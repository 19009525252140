import { useEffect } from "react";
import Footer from "pages/components/footer";
import Sidebar from "pages/components/sidebar";
import Header from "pages/components/header";
import { useMemo, useState } from "react";
import {MaterialReactTable} from "material-react-table";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LoanAutoCharge from "pages/settings/modals_updated/add_new_loan_auto_charge";
const LoanProductSingle = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [data, setData] = useState([]);
  //fetch loan products data
  const controller = new AbortController();
  const getLoanProductAutoCharges = async () => {
    try {
      const response = await axiosPrivate.get(
        `settings/loans/autocharges/${params.id}/product`,
        {
          signal: controller.signal,
        }
      );
      setData(response.data.data.auto_charges);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoanProductAutoCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const charges = data?.map((data) => ({
    title: data.title,
    value: data.value,
    type: data.type,
    nature: data.nature,
    action: (
      <div className="btn-group">
        <button className="btn btn-sm btn-danger p-1 rounded-0">delete</button>
      </div>
    ),
  }));

  const columns = useMemo(
    () => [
      { header: "Charge Title", accessorKey: "title" },
      {
        header: "Charge Value",
        id: "value",
        accessorFn: (row) => row.value.toLocaleString(),
      },
      { header: "Charge Type", accessorKey: "type" },
      { header: "Charge Nature", accessorKey: "nature" },
      { header: "Action", accessorKey: "action" },
    ],
    []
  );

  const [showModal, setShowModal] = useState (false);
  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);
 
  return (
    <>
      {/* .............header...................... */}
      <Header />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <Sidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Loan product Auto Charges{" "}
                      </h2>
                      <button
                        onClick={handleOpenModal}
                        className="btn btn-outline-dark float-right"
                      >
                        Add Auto Charge
                      </button>
                      <LoanAutoCharge
                      showModal = {showModal}
                      handleCloseModal ={handleCloseModal}
                       getAutoCharges = {getLoanProductAutoCharges}
                       />
                    </div>
                    <div className="card-body mt-3 mb-3">
                      {/* ....loan products view..... */}
                      <MaterialReactTable
                        columns={columns}
                        data={charges}
                        className="table align-items-center mb-0"
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableRowSelection
                        enableGrouping
                        enableStickyHeader
                        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiSearchTextFieldProps={{
                          placeholder: "Search",
                          sx: { fontFamily: "Nunito", fontSize: "12px" },
                        }}
                      />

                      {/* ....loan products view end..... */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <Footer />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};
export default LoanProductSingle;
