import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import fileDownload from "js-file-download";
import ProgressBar from "@badrap/bar-of-progress";
import Pagination from "pages/components/pagination";

const WithdrawsGeneralReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const controller = new AbortController();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post(
        `/reports/new/savings/general-withdraws`,
        data,
        {
          signal: controller.signal,
        }
      );

      setDates(response.data.data.dates);
      setData(response.data.data.data);

      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const total = data.map((data) => data.amount).reduce((a, b) => a + b, 0);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // function to export data as pdf
  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.post(
        `/export/reports/general_withdraws/pdf`,
        {
          data: {
            dates,
            data,
          }
        },

        { responseType: "blob" }
      );
      const filename = "Withdraw_Savings_General_Report.pdf";
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading withdraw savings  report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.post(
        `/export/reports/general_withdraws/csv`,
        {
          data: {
            dates,
            data,
          }
        },
        { responseType: "blob" }
      );

      const filename = `General_Withdraws_Report.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading General Withdraws report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };
  return (
    <>
      <Toaster />

      <section className="mt-1">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-0 mb-lg-0">
              <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  General Withdraws Report{" "}
                </h2>
              </div>
              <div className="card-body mt-3 mb-3">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <form
                      className="row needs-validation"
                      id="generalLegerForm"
                      onSubmit={handleSubmit(onQuery)}
                    >
                      <div className="form-group col-md-3">
                        <label className="form-label">Select Start Date</label>
                        <input
                          type="date"
                          placeholder="Select Start Date"
                          className="form-control form-control-md input-text shadow border-0"
                          {...register("start_date", { required: true })}
                        />
                        {errors?.start_date?.type === "required" && (
                          <p className="text-small m-0 text-danger help-block">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="form-group col-md-3">
                        <label className="form-label">Select End Date</label>
                        <input
                          type="date"
                          placeholder="Select End Date"
                          className="form-control form-control-md input-text shadow border-0"
                          {...register("end_date", { required: true })}
                        />
                        {errors?.end_date?.type === "required" && (
                          <p className="text-small m-0 text-danger help-block">
                            This field is required
                          </p>
                        )}
                      </div>

                      <div className="form-group col-md-3 pt-3 mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary text-center rounded-0"
                          disabled={disabled}
                        >
                          {!disabled && "Generate"}{" "}
                          {disabled && "Please Wait ....."}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-3">
                  <table className="table table-hover table-bordered text-medium-small">
                    <thead>
                      <tr>
                        <th className="text-left" colSpan="8">
                          <div className="row">
                            <p className="text-left col-4">
                              Start Date : {dates?.start_date}
                            </p>
                            <p className="text-left col-4">
                              End Date: {dates?.end_date}
                            </p>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th>Date</th>
                        <th>Full Name</th>
                        <th>Account Number</th>
                        <th>Account Title</th>
                        <th>Amount</th>
                        <th>Charges</th>
                        <th>TXN Code</th>
                        <th>Notary</th>
                        <th>Notes</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentItems?.map((row, index) => (
                        <tr key={index}>
                          <td>
                            {new Date(row?.timestamp).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </td>
                          <td>
                            {row?.client_name}
                          </td>
                          <td>{row?.client_account_number}</td>
                          <td>{row?.account_title}</td>
                          <td>{row?.amount?.toLocaleString()}</td>
                          <td>{row?.charge?.toLocaleString()}</td>
                          <td>{row?.code}</td>
                          <td>{row?.notary}</td>
                          <td>{row?.notes}</td>
                        </tr>
                      ))}
                      <tr>
                        <th>Total</th>
                        <th colSpan={3}></th>
                        <th>{total.toLocaleString()}</th>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                  <div className="d-flex align-items-center mt-0">
                    <div className="ml-auto mb-0 mt-0">
                      <button
                        className="btn btn-primary text-right"
                        onClick={downloadUssdReportAsPDF}
                        sumcredit
                        // disabled={isGenerating || isPDFDownloading}
                      >
                        {isPDFDownloading
                          ? "Downloading please wait..."
                          : "Download PDF"}
                      </button>
                      <button
                        className="btn btn-secondary text-right"
                        onClick={downloadUssdReportAsCSV}
                        // disabled={isGenerating || isCSVDownloading}
                      >
                        {isCSVDownloading
                          ? "Downloading please wait..."
                          : "Download CSV"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WithdrawsGeneralReport;
