import { useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useState } from "react";

const SaccoDefaults = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [sms, setSMS] = useState(true);
  const [email, setEmail] = useState(true);
  const [monthlyAutoCharges, setMonthlyAutoCharges] = useState(true);
  const [monthlyAutoLoanCharges, setMonthlyAutoLoanCharges] = useState(true);
  const [loanAlerts, setLoanAlerts] = useState(true);
  const [loanPenalty, setLoanPenalty] = useState(true);
  const [system, setSystem] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const controller = new AbortController();
  const get = async () => {
    try {
      const response = await axiosPrivate.get("/settings/accounts/account", {
        signal: controller.signal,
      });
      setAccounts(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [defaults, setDefaults] = useState([]);
  const getDefaults = async () => {
    try {
      const response = await axiosPrivate.get("settings/sacco/defaults", {
        signal: controller.signal,
      });
      setDefaults(response.data.data.sacco_defaults);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getSettings = async () => {
    try {
      const response = await axiosPrivate.get("settings/sacco/settings", {
        signal: controller.signal,
      });
      setSMS(response.data.data.settings.sms === "yes" ? true : false);
      setEmail(response.data.data.settings.email === "yes" ? true : false);
      setMonthlyAutoCharges(
        response.data.data.settings.monthly_auto_charges === "yes"
          ? true
          : false
      );
      setMonthlyAutoLoanCharges(
        response.data.data.settings.loan_auto_charge === "yes" ? true : false
      );
      setLoanAlerts(
        response.data.data.settings.loan_alerts === "yes" ? true : false
      );
      setLoanPenalty(
        response.data.data.settings.penalty_auto === "yes" ? true : false
      );
      setSystem(
        response.data.data.settings.system_status === "locked" ? true : false
      );
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getDefaults();
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = accounts?.map((account) => ({
    value: account.id,
    label: account.title,
  }));

  const onSave = async (data) => {
    // console.log(data);
    const newData = {
      currency: data.currency,
      guarantee: data.guarantee,
      period: data.period,
      share: data.share.value,
      interest: data.interest.value,
      penalty: data.penalty.value,
      withdraw: data.withdraw.value,
      disbursment: data.disbursment.value,
      extra_charges: data.extra_charges.value,
      share_price: data.share_price,
      monthly_charge: data.monthly_charge,
      monthly_charge_account: data.monthly_charge_account.value,
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        "settings/sacco/defaults",
        newData,
        {
          signal: controller.signal,
        }
      );
      console.log(response);
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);

        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const handleChangeSms = async (e) => {
    progress.start();
    const data =
      sms === true
        ? JSON.stringify({ sms: "no" })
        : JSON.stringify({ sms: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/settings/sacco/settings`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getSettings();
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const handleChangeEmail = async (e) => {
    progress.start();
    const data =
      email === true
        ? JSON.stringify({ email: "no" })
        : JSON.stringify({ email: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/settings/sacco/settings`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getSettings();
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const handleChangeMonthlyAutoCharges = async (e) => {
    progress.start();
    const data =
      monthlyAutoCharges === true
        ? JSON.stringify({ monthly_auto_charges: "no" })
        : JSON.stringify({ monthly_auto_charges: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/settings/sacco/settings`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getSettings();
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const handleChangeMonthlyAutoLoanCharges = async (e) => {
    progress.start();
    const data =
      monthlyAutoLoanCharges === true
        ? JSON.stringify({ loan_auto_charge: "no" })
        : JSON.stringify({ loan_auto_charge: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/settings/sacco/settings`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getSettings();
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const handleChangeLoanAlerts = async (e) => {
    progress.start();
    const data =
      loanAlerts === true
        ? JSON.stringify({ loan_alerts: "no" })
        : JSON.stringify({ loan_alerts: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/settings/sacco/settings`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getSettings();
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const handleChangePenalty = async (e) => {
    progress.start();
    const data =
      loanPenalty === true
        ? JSON.stringify({ penalty_auto: "no" })
        : JSON.stringify({ penalty_auto: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/settings/sacco/settings`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getSettings();
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  // Please don't touch this kindly unless you remember this course unit of Compiler Design and P versus NP Problem
  const handleChangeLock = async (e) => {
    progress.start();
    const data =
      system === true
        ? JSON.stringify({ system_status: "open" })
        : JSON.stringify({ system_status: "locked" });
    try {
      const response = await axiosPrivate.patch(
        `/settings/sacco/settings`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getSettings();
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
   
          <section className="mt-2">
            <div className="card">
              <div className="card-header">
                <h2 className="h6  text-uppercase text-center">
                  system defaults Settings{" "}
                </h2>
              </div>
              <div className="card-body p-2">
                <div className="row">
                  <form
                    onSubmit={handleSubmit(onSave)}
                    className="row needs-validation col-8"
                  >
                    <div className="form-group col-4">
                      <label id="currency" className="form-label">
                        Choose Prefered Currency
                      </label>
                      <select
                        {...register("currency")}
                        name="currency"
                        className="border-0 shadow form-control-md input-text"
                      >
                        <option value="UGX" defaultValue>
                          Ugandan Shillings
                        </option>
                        <option value="USD">United States Dollars</option>
                        <option value="EUR">Euro</option>
                        <option value="GBP">United Kingdom Pounds</option>
                        <option value="DZD">Algeria Dinars</option>
                        <option value="ARP">Argentina Pesos</option>
                        <option value="AUD">Australia Dollars</option>
                        <option value="ATS">Austria Schillings</option>
                        <option value="BSD">Bahamas Dollars</option>
                        <option value="BBD">Barbados Dollars</option>
                        <option value="BEF">Belgium Francs</option>
                        <option value="BMD">Bermuda Dollars</option>
                        <option value="BRR">Brazil Real</option>
                        <option value="BGL">Bulgaria Lev</option>
                        <option value="CAD">Canada Dollars</option>
                        <option value="CLP">Chile Pesos</option>
                        <option value="CNY">China Yuan Renmimbi</option>
                        <option value="CYP">Cyprus Pounds</option>
                        <option value="CSK">Czech Republic Koruna</option>
                        <option value="DKK">Denmark Kroner</option>
                        <option value="NLG">Dutch Guilders</option>
                        <option value="XCD">Eastern Caribbean Dollars</option>
                        <option value="EGP">Egypt Pounds</option>
                        <option value="FJD">Fiji Dollars</option>
                        <option value="FIM">Finland Markka</option>
                        <option value="FRF">France Francs</option>
                        <option value="DEM">Germany Deutsche Marks</option>
                        <option value="XAU">Gold Ounces</option>
                        <option value="GRD">Greece Drachmas</option>
                        <option value="HKD">Hong Kong Dollars</option>
                        <option value="HUF">Hungary Forint</option>
                        <option value="ISK">Iceland Krona</option>
                        <option value="INR">India Rupees</option>
                        <option value="IDR">Indonesia Rupiah</option>
                        <option value="IEP">Ireland Punt</option>
                        <option value="ILS">Israel New Shekels</option>
                        <option value="ITL">Italy Lira</option>
                        <option value="JMD">Jamaica Dollars</option>
                        <option value="JPY">Japan Yen</option>
                        <option value="JOD">Jordan Dinar</option>
                        <option value="KRW">Korea (South) Won</option>
                        <option value="LBP">Lebanon Pounds</option>
                        <option value="LUF">Luxembourg Francs</option>
                        <option value="MYR">Malaysia Ringgit</option>
                        <option value="MXP">Mexico Pesos</option>
                        <option value="NLG">Netherlands Guilders</option>
                        <option value="NZD">New Zealand Dollars</option>
                        <option value="NOK">Norway Kroner</option>
                        <option value="PKR">Pakistan Rupees</option>
                        <option value="XPD">Palladium Ounces</option>
                        <option value="PHP">Philippines Pesos</option>
                        <option value="XPT">Platinum Ounces</option>
                        <option value="PLZ">Poland Zloty</option>
                        <option value="PTE">Portugal Escudo</option>
                        <option value="ROL">Romania Leu</option>
                        <option value="RUR">Russia Rubles</option>
                        <option value="SAR">Saudi Arabia Riyal</option>
                        <option value="XAG">Silver Ounces</option>
                        <option value="SGD">Singapore Dollars</option>
                        <option value="SKK">Slovakia Koruna</option>
                        <option value="ZAR">South Africa Rand</option>
                        <option value="KRW">South Korea Won</option>
                        <option value="ESP">Spain Pesetas</option>
                        <option value="XDR">Special Drawing Right (IMF)</option>
                        <option value="SDD">Sudan Dinar</option>
                        <option value="SEK">Sweden Krona</option>
                        <option value="CHF">Switzerland Francs</option>
                        <option value="TWD">Taiwan Dollars</option>
                        <option value="THB">Thailand Baht</option>
                        <option value="TTD">Trinidad and Tobago Dollars</option>
                        <option value="TRL">Turkey Lira</option>
                        <option value="VEB">Venezuela Bolivar</option>
                        <option value="ZMK">Zambia Kwacha</option>
                        <option value="EUR">Euro</option>
                        <option value="XCD">Eastern Caribbean Dollars</option>
                        <option value="XDR">Special Drawing Right (IMF)</option>
                        <option value="XAG">Silver Ounces</option>
                        <option value="XAU">Gold Ounces</option>
                        <option value="XPD">Palladium Ounces</option>
                        <option value="XPT">Platinum Ounces</option>
                      </select>
                    </div>

                    <div className="form-group col-4">
                      <label className="form-label">
                        Choose Prefered Shares Account
                      </label>
                      <Controller
                        control={control}
                        name="share"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            {...register("share", { required: true })}
                            options={options}
                            onChange={onChange}
                            isMulti={false}
                            onBlur={onBlur}
                            placeholder={defaults.share_account}
                            className="border-0 shadow"
                            name={name}
                            styles={customStyles}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.share?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please select preferd shares account
                        </p>
                      )}
                    </div>

                    <div className="form-group col-4">
                      <label className="form-label">
                        Choose Prefered Loan Interest Account
                      </label>
                      <Controller
                        control={control}
                        name="interest"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            {...register("interest", { required: true })}
                            options={options}
                            onChange={onChange}
                            isMulti={false}
                            onBlur={onBlur}
                            name={name}
                            className="border-0 shadow"
                            styles={customStyles}
                            placeholder={defaults.interest_account}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.share?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please select preferd interest account
                        </p>
                      )}
                    </div>

                    <div className="form-group col-4">
                      <label className="form-label">
                        No Of Loans One can guarantee
                      </label>
                      <input
                        type="number"
                        {...register("guarantee", { required: true })}
                        placeholder={defaults.loan_guarantee}
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                      {errors?.guarantee?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please specify number of loans one can guarantee
                        </p>
                      )}
                    </div>

                    <div className="form-group col-4">
                      <label className="form-label">
                        Default Account For Funds Withdraw Fees
                      </label>
                      <Controller
                        control={control}
                        name="withdraw"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            {...register("withdraw", { required: true })}
                            options={options}
                            onChange={onChange}
                            isMulti={false}
                            onBlur={onBlur}
                            className="border-0 shadow"
                            styles={customStyles}
                            name={name}
                            placeholder={defaults.withdraw_account}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.withdraw?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please select preferd withdraw account
                        </p>
                      )}
                    </div>

                    <div className="form-group col-4">
                      <label className="form-label">Loans account</label>
                      <Controller
                        control={control}
                        name="disbursment"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            {...register("disbursment", {
                              required: true,
                            })}
                            options={options}
                            onChange={onChange}
                            isMulti={false}
                            onBlur={onBlur}
                            className="border-0 shadow"
                            styles={customStyles}
                            name={name}
                            placeholder={defaults.disbursment_account}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.disbursment?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please select prefered loans account
                        </p>
                      )}
                    </div>
                    <div className="form-group col-4">
                      <label className="form-label">Penalty Account</label>
                      <Controller
                        control={control}
                        name="penalty"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            {...register("penalty", { required: true })}
                            options={options}
                            onChange={onChange}
                            isMulti={false}
                            onBlur={onBlur}
                            className="border-0 shadow"
                            styles={customStyles}
                            name={name}
                            placeholder={defaults.penalty_account}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.penalty?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please select preferd penalty account
                        </p>
                      )}
                    </div>

                    <div className="form-group col-4">
                      <label className="form-label">
                        Extra charges account
                      </label>
                      <Controller
                        control={control}
                        name="extra_charges"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            {...register("extra_charges", {
                              required: true,
                            })}
                            options={options}
                            onChange={onChange}
                            isMulti={false}
                            onBlur={onBlur}
                            className="border-0 shadow"
                            styles={customStyles}
                            name={name}
                            placeholder={defaults?.extra_charges_account}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.extra_charges?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please select preferd extra fees account
                        </p>
                      )}
                    </div>

                    <div className="form-group col-4">
                      <label className="form-label">
                        Monthly Charges Account
                      </label>
                      <Controller
                        control={control}
                        name="monthly_charge_account"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            {...register("monthly_charge_account", {
                              required: true,
                            })}
                            options={options}
                            onChange={onChange}
                            isMulti={false}
                            onBlur={onBlur}
                            className="border-0 shadow"
                            styles={customStyles}
                            name={name}
                            placeholder={defaults?.monthly_charge_account}
                            ref={ref}
                          />
                        )}
                      />
                      {errors?.monthly_charge_account?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please select monthly account charges
                        </p>
                      )}
                    </div>
                    <div className="form-group col-4">
                      <label className="form-label">Set Monthly Charge</label>
                      <input
                        type="number"
                        defaultValue={defaults.monthly_charge}
                        className="form-control border-0 shadow form-control-md input-text"
                        {...register("monthly_charge", { required: true })}
                      />

                      {errors?.monthly_charge?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please set monthly charge
                        </p>
                      )}
                    </div>
                    <div className="form-group col-4">
                      <label className="form-label">Set Share Price</label>
                      <input
                        type="number"
                        defaultValue={defaults.share_price}
                        className="form-control border-0 shadow form-control-md input-text"
                        {...register("share_price", { required: true })}
                      />

                      {errors?.share_price?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please set share price
                        </p>
                      )}
                    </div>
                    <div className="form-group col-4">
                      <label className="form-label">
                        Accounting Period Starts
                      </label>
                      <input
                        type="date"
                        defaultValue={defaults.according_period}
                        className="form-control border-0 shadow form-control-md input-text"
                        {...register("period", { required: true })}
                      />

                      {errors?.period?.type === "required" && (
                        <p className="text-small m-0 text-start text-danger help-block">
                          Please choose accounting period
                        </p>
                      )}
                    </div>
                    <div className="form-group col-md-12 text-right">
                      <button
                        disabled={disabled}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {!disabled && "Update"} {disabled && "Please Wait"}
                      </button>
                    </div>
                  </form>
                  <div className="col-4">
                    <div className="card">
                      <div className="card-header">Sacco Settings</div>
                      <div className="card-body p-2">
                        <ul className="list-group">
                          <li className="list-group-item border-0">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="email"
                                onChange={handleChangeSms}
                                checked={sms}
                              />
                              <label className="form-check-label pt-1">
                                Send General SMS
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item border-0">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="email"
                                onChange={handleChangeEmail}
                                checked={email}
                              />
                              <label className="form-check-label pt-1">
                                Send General Emails
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item border-0">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="email"
                                onChange={handleChangeMonthlyAutoCharges}
                                checked={monthlyAutoCharges}
                              />
                              <label className="form-check-label pt-1">
                                Monthly Auto Account Charges
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item border-0">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="email"
                                onChange={handleChangeMonthlyAutoLoanCharges}
                                checked={monthlyAutoLoanCharges}
                              />
                              <label className="form-check-label pt-1">
                                Monthly Auto Loan Charges
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item border-0">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="email"
                                onChange={handleChangeLoanAlerts}
                                checked={loanAlerts}
                              />
                              <label className="form-check-label pt-1">
                                Loan Alerts
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item border-0">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="email"
                                onChange={handleChangePenalty}
                                checked={loanPenalty}
                              />
                              <label className="form-check-label pt-1">
                                Loan Auto Penalties
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="card-footer text-center">
                        <span className="badge badge-lg badge-dark p-2">
                          {system === true ? "System Locked" : "System Open"}
                        </span>
                        <div className="btn-group mt-2">
                          <button
                            className="btn btn-success btn-sm"
                            disabled={!system}
                            onClick={handleChangeLock}
                          >
                            System On
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            disabled={system}
                            onClick={handleChangeLock}
                          >
                            System Off
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    </>
  );
};

export default SaccoDefaults;
