import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const NewFixedDeposits = ({ showModal, handleCloseModal, getFixed }) => {
  const [disabled, setDisabled] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    var setting = data.setting.value;
    delete data.setting;
    data.setting = setting;
    var client_account_id = data.client_account_id.value;
    delete data.client_account_id;
    data.client_account_id = client_account_id;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/accounting/fixed/deposits",
        data,
        {
          signal: controller.signal,
        }
      );

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      getFixed();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [settings, setSettings] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [clients, setClients] = useState([]);
  const handleOnChange = async (event) => {
    // console.logs(event.value)
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        `/accounts/attached/accounts/${event.value}`,
        {
          signal: controller.signal,
        }
      );
      setAccounts(response?.data?.data?.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const options3 = accounts?.map((account) => ({
      value: `${account.id}`,
      label: `${account.title}`,
    }));
  const controller = new AbortController();
  const getSettings = async () => {
    try {
      const response = await axiosPrivate.get("/settings/fixed/settings", {
        signal: controller.signal,
      });
      setSettings(response.data.data.fixed_deposit_settings);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const options = settings?.map((setting) => ({
    value: `${setting.id}`,
    label: `${setting.title}`,
  }));
  const getClients = async () => {
    try {
      const response = await axiosPrivate.get("/clients/general", {
        signal: controller.signal,
      });
      setClients(response?.data?.data?.clients);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getClients();
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options2 = clients?.map((client) => ({
    value: `${client.id}`,
    label: `${client.name} - ${client.account} - ${client.contact}`,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center">
              {" "}
              Add New Fixed Deposit{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-6">
              <label htmlFor="client" className="form-label">
                Select Client *
              </label>

              <Select
                className="border-1 form-control-md"
                placeholder="Select Client to Attach * "
                styles={customStyles}
                options={options2}
                onChange={handleOnChange}
                isMulti={false}
              />

              {errors?.client?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Client
                </p>
              )}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="client_account_id" className="form-label">
                Select Fixed Account *
              </label>
              <Controller
                control={control}
                name="client_account_id"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    {...register("client_account_id", { required: true })}
                    className="border-1 form-control-md"
                    placeholder="Select Account To Attach * "
                    styles={customStyles}
                    options={options3}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    isMulti={false}
                    ref={ref}
                  />
                )}
              />
              {errors?.client_account_id?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Setting
                </p>
              )}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="setting" className="form-label">
                Select Fixed Deposit Setting *
              </label>
              <Controller
                control={control}
                name="setting"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    {...register("setting", { required: true })}
                    className="border-1 form-control-md"
                    placeholder="Select Settings To Attach * "
                    styles={customStyles}
                    options={options}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    isMulti={false}
                    ref={ref}
                  />
                )}
              />
              {errors?.setting?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Setting
                </p>
              )}
            </div>

            {/* Phone Number */}
            <div className="form-group col-md-6  input-container">
              <label htmlFor="amount" className="form-label">
                Amount
              </label>
              <input
                type="text"
                name="amount"
                placeholder="Amount to fix *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("amount", {
                  required: true,
                })}
              />
              {errors?.amount?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter amount to fix
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="start_date" className="form-label">
                Select Date of Starting
              </label>
              <input
                type="date"
                name="start_date"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("start_date", { required: true })}
              />
              {errors?.start_date?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter Date Of Starting
                </p>
              )}
            </div>
            {/* Date of joining */}
            <div className="form-group col-md-6 input-container">
              <label htmlFor="end_date" className="form-label">
                Select Date of Ending
              </label>
              <input
                type="date"
                name="end_date"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("end_date", { required: true })}
              />
              {errors?.end_date?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter Date Of Ending
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Save"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default NewFixedDeposits;
