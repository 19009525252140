import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";

const BalanceSheet = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const [data, setData] = useState([]);
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);

  const controller = new AbortController();
  const getData = async () => {
    progress.start();
    try {
      const response = await axiosPrivate.get("/reports/new/balance-sheet", {
        signal: controller.signal,
      });
      console.log(response.data.data.data);
      setData(response.data.data.data);

      progress.finish();
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sumIncome = data?.assets
    ?.map((data) => parseFloat(data?.account_balance))
    ?.reduce((a, b) => a + b, 0);
  const sumExpenses = data?.liabilities
    ?.map((data) => parseFloat(data?.account_balance))
    ?.reduce((a, b) => a + b, 0);
  const sumEquity = data?.equity
    ?.map((data) => parseFloat(data?.account_balance))
    ?.reduce((a, b) => a + b, 0);
  // const sumIncome = [];
  // const sumExpenses = [];

  // / function to export data as pdf
  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/balance_sheet/pdf`,

        { responseType: "blob" }
      );
      const filename = `Balance_sheet.pdf`;
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading balance sheet:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/balance_sheet/csv`,

        { responseType: "blob" }
      );

      const filename = `Balance_sheet.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading Balance sheet:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  return (
    <>
      <div className="card mt-2">
        <div className="card-header bg-dark">
          <p className="h5 text-bold text-uppercase text-center text-white">
            Balance Sheet{" "}
          </p>
        </div>
        <div className="card-body mt-3 mb-3">
          <div className="mt-3">
            <table className="table table-hover table-bordered">
              <tbody>
                <tr>
                  <th
                    className="bg-dark text-white text-medium-small"
                    colSpan={2}
                  >
                    Assets
                  </th>
                </tr>
                {data?.assets?.map((vote, index) => (
                  <tr key={index} className="text-medium-small">
                    <td>{vote?.account_title}</td>
                    <td>
                      {parseFloat(vote?.account_balance)?.toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-light text-medium-small">Total Assets</th>
                  <th className="bg-light text-medium-small">
                    {sumIncome?.toLocaleString()}
                  </th>
                </tr>
                <tr>
                  <th
                    className="bg-dark text-white text-medium-small"
                    colSpan={2}
                  >
                    Liabilites
                  </th>
                </tr>
                {data?.liabilities?.map((vote, index) => (
                  <tr key={index} className="text-medium-small">
                    <td>{vote?.account_title}</td>
                    <td>
                      {parseFloat(vote?.account_balance)?.toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-light text-medium-small">
                    Total Liabilites
                  </th>
                  <th className="bg-light text-medium-small">
                    {sumExpenses?.toLocaleString()}
                  </th>
                </tr>

                <tr>
                  <th
                    className="bg-dark text-white text-medium-small"
                    colSpan={2}
                  >
                    Equity / Capital
                  </th>
                </tr>
                {data?.equity?.map((vote, index) => (
                  <tr key={index} className="text-medium-small">
                    <td>{vote?.account_title}</td>
                    <td>
                      {parseFloat(vote?.account_balance)?.toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-light text-medium-small">Total Equity</th>
                  <th className="bg-light text-medium-small">
                    {sumEquity?.toLocaleString()}
                  </th>
                </tr>
              </tbody>
            </table>
            <div className="d-flex align-items-center">
              <div className="ml-auto">
                <button
                  className="btn btn-primary text-right"
                  onClick={downloadUssdReportAsPDF}

                  // disabled={isGenerating || isPDFDownloading}
                >
                  {isPDFDownloading
                    ? "Downloading please wait..."
                    : "Download PDF"}
                </button>
                <button
                  className="btn btn-secondary text-right"
                  onClick={downloadUssdReportAsCSV}
                  // disabled={isGenerating || isCSVDownloading}
                >
                  {isCSVDownloading
                    ? "Downloading please wait..."
                    : "Download CSV"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceSheet;
