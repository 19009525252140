import { useState, useEffect, useCallback, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import LoanCalculator from "./components/loanCalculator";
import NewLoanApplication from "./components/newLoanApplication";
import Table from "pages/components/common_table";
const LoanApplications = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [loans, setLoans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showOpenModal, setShowOpenModal] = useState(false);
      const [isLoading, setIsLoading] = useState(true);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleOpenShowModal = () => setShowOpenModal(true);
  const handleOpenCloseModal = () => setShowOpenModal(false);
  const controller = new AbortController();
  const getLoans = async () => {
    try {
      const response = await axiosPrivate.get("/loans/applications", {
        signal: controller.signal,
      });
      setLoans(response.data.data.loans);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = () => {
    setIsLoading(true);
    getLoans();
  }
  const handleDetele = useCallback(
    (id) => {
      confirmAlert({
        // : 'Confirm to submit',
        message: " Are you sure to do this..",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                const controller = new AbortController();
                const response = await axiosPrivate.delete(
                  "/loans/applications/" + id,
                  {
                    signal: controller.signal,
                  }
                );
                toast.success(response?.data?.messages);
              } catch (error) {
                if (!error?.response) {
                  toast("No server response");
                } else {
                  console.log(error?.response);
                  if (error?.response?.status === 401) {
                    navigate("/", {
                      state: { from: location },
                      replace: true,
                    });
                  }
                  toast.error(error?.response?.data?.messages, {
                    style: {
                      background: "#e91e63",
                      fontSize: "13px",
                      color: "#fff",
                    },
                  });
                }
              }
            },
          },
          {
            label: "No",
            // onClick: () => alert('Click No')
          },
        ],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const columns = useMemo(
    () => [
      {
        id: "account_name", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Name",
        accessorFn: (row) => row?.account_name,
        size: 50,
      },
      {
        id: "account_number", //access nested data with dot notation
        enableClickToCopy: true,
        header: "A/C No.",
        accessorFn: (row) => row?.account_number,
        size: 50,
      },
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Amount",
        accessorFn: (row) => row?.amount?.toLocaleString(),
        size: 50,
      },
      {
        id: "loan_product", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Loan Product",
        accessorFn: (row) => row?.loan_product,
        size: 50,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Status",
        accessorFn: (row) =>
          row?.status === "pending" ? (
            <span className="badge badge-warning p-1 text-white">Pending</span>
          ) : row?.status === "approved" ? (
            <span className="badge badge-info p-1 text-white">Approved</span>
          ) : row?.status === "disbursed" ? (
            <span className="badge badge-success p-1 text-white">
              Disbursed
            </span>
          ) : row?.status === "process" ? (
            <span className="badge badge-secondary p-1 text-white">
              Processing
            </span>
          ) : row?.status === "rejected" ? (
            <span className="badge badge-danger p-1 text-white">Rejected</span>
          ) : row?.status === "settled" ? (
            <span className="badge badge-success p-1 text-white">Settled</span>
          ) : (
            ""
          ),
        size: 50,
      },
      {
        id: "date", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Date Of Application",
        accessorFn: (row) => row?.date,
        size: 50,
      },
      {
        id: "code", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Loan Number",
        accessorFn: (row) => row?.code,
        size: 50,
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 30,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <Link
              to={`/loans/loans/${row?.id}`}
              className="text-secondary font-weight-bold text-xs mr-1"
            >
              view
            </Link>
            <button
              className="btn btn-danger text-white rounded-0 btn-sm p-1"
              onClick={() => handleDetele(row?.id)}
              aria-expanded="false"
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    [handleDetele]
  );
  return (
    <>
      <Toaster />
      <section className="mt-3">
        <div className="card mb-5">
          <NewLoanApplication
            getLoans={getLoans}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
          <LoanCalculator
            showModal={showOpenModal}
            handleCloseModal={handleOpenCloseModal}
          />
          <div className="card-body p-0">
            <ul className="nav nav-tabs bg-dark" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active text-white  text-sm p-3"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Individual Loan Applications
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-white text-sm p-3"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Group Loan Applications
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <Table
                  columns={columns}
                  data={loans
                    ?.map((loan) => loan)
                    .filter((loan) => loan.type === "individual")}
                    refetch={refetch}
                    isLoading={isLoading}
                  headers={
                    <>
                      <button
                        className="btn btn-outline-dark btn-sm mr-2 rounded-0"
                        onClick={handleShowModal}
                      >
                        <i className="fas fa-plus mr-1"></i> Add New Loan
                        Application
                      </button>
                      <button
                        className="btn btn-outline-danger btn-sm rounded-0"
                        onClick={handleOpenShowModal}
                      >
                        <i className="fas fa-calculator mr-1"></i> Calculator
                      </button>
                    </>
                  }
                />
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <Table
                  columns={columns}
                  data={loans
                    ?.map((loan) => loan)
                    .filter((loan) => loan.type === "group")}
                    refetch={refetch}
                    isLoading={isLoading}
                  headers={
                    <>
                      <button
                        className="btn btn-outline-dark btn-sm mr-2 rounded-0"
                        onClick={handleShowModal}
                      >
                        <i className="fas fa-plus mr-1"></i> Add New Loan
                        Application
                      </button>
                      <button
                        className="btn btn-outline-danger btn-sm rounded-0"
                        onClick={handleOpenShowModal}
                      >
                        <i className="fas fa-calculator mr-1"></i> Calculator
                      </button>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoanApplications;
