import { useMemo, useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ExtraChargesModal from "./extra-charges-modal";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import Table from "pages/components/common_table";
const ExtraCharges = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const params = useParams();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const controller = new AbortController();
  const getCharges = async () => {
    try {
      const response = await axiosPrivate.get(
        `loans/charges/${params.id}/applications`,
        {
          signal: controller.signal,
        }
      );
      setData(response.data.data.charges);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = () => {
    setIsLoading(true);
    getCharges();
  }
  const handleDelete = useCallback(
    async (id) => {
      if (window.confirm("Are you sure you want delete this charge?")) {
        try {
          progress.start();
          setDisabled(true);
          const controller = new AbortController();
          const response = await axiosPrivate.delete(`/loans/charges/${id}`, {
            signal: controller.signal,
          });
          getCharges();
          toast.success(response?.data?.messages);
          progress.finish();
          setDisabled(false);
        } catch (error) {
          if (!error?.response) {
            toast.error("No server response");
            progress.finish();
            setDisabled(false);
          } else {
            console.log(error?.response);
            progress.finish();
            setDisabled(false);
            if (error?.response?.status === 401) {
              navigate("/", { state: { from: location }, replace: true });
            }
            toast.error(error?.response?.data?.messages, {
              style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
    const handlePay = useCallback(
      async (id) => {
        if (window.confirm("Are you sure you want pay this charge?")) {
          try {
            progress.start();
            setDisabled(true);
            const controller = new AbortController();
            const response = await axiosPrivate.patch(`/loans/charges/${id}`, {
              signal: controller.signal,
            });
            getCharges();
            toast.success(response?.data?.messages);
            progress.finish();
            setDisabled(false);
          } catch (error) {
            if (!error?.response) {
              toast.error("No server response");
              progress.finish();
              setDisabled(false);
            } else {
              console.log(error?.response);
              progress.finish();
              setDisabled(false);
              if (error?.response?.status === 401) {
                navigate("/", { state: { from: location }, replace: true });
              }
              toast.error(error?.response?.data?.messages, {
                style: {
                  background: "#e91e63",
                  fontSize: "13px",
                  color: "#fff",
                },
              });
            }
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
  const loansColumns = useMemo(
    () => [
      {
        id: "title",
        header: "Description",
        size: 50,
        accessorFn: (row) => row.title,
      },
      {
        accessorKey: "timestamp",
        size: 50,
        header: "Date",
      },
      {
        id: "amount",
        header: "Amount",
        size: 50,
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        id: "status",
        header: "Status",
        size: 50,
        accessorFn: (row) =>
          row.status === "active" ? (
            <div className="badge badge-danger">Not Paid</div>
          ) : (
            <div className="badge badge-success">Paid</div>
          ),
      },
      {
        id: "id",
        header: "Action",
        size: 50,
        accessorFn: (row) => (
            row.status === "active" ? (
          <>
            <button
              onClick={() => handlePay(row.id)}
              className="btn btn-sm p-1 btn-link text-dark rounded-0"
            >
              Pay
            </button>
            <button
              onClick={() => handleDelete(row.id)}
              className="btn btn-sm p-1 btn-link text-dark rounded-0"
              disabled={disabled}
            >
              <i className="fas fa-trash-alt text-danger"></i>
            </button>
          </>
            ) : "No action"
        ),
      },
    ],
    [handleDelete, disabled, handlePay]
  );
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  return (
    <>
      <Toaster />
      <div className="row">
        <div className="col-12 p-2">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h2 className="h6 mb-0 text-uppercase text-center float-right">
                    Loan Extra Charges
                  </h2>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm float-right mr-2 rounded-0"
                    onClick={handleOpenModal}
                  >
                    Add Extra Charge
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              {/* <MaterialReactTable
                columns={loansColumns}
                data={data}
                className="table align-items-center p-0"
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                enableGrouping
                enableStickyHeader
                initialState={{ density: "compact" }}
                muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
              /> */}
              <Table columns={loansColumns} data={data} isLoading={isLoading} refetch={refetch} />
            </div>
          </div>
        </div>
      </div>
      <ExtraChargesModal
        getCharges={getCharges}
        handleCloseModal={handleCloseModal}
        showModal={openModal}
      />
    </>
  );
};

export default ExtraCharges;
