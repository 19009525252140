import { useEffect } from "react";
import LoapProductModal from "pages/settings/modals/loanproductmodal";
import { useMemo, useState } from "react";
import {MaterialReactTable} from "material-react-table";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ProductView from "pages/settings/modals/product_viewmodal";
const LoanProducts = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    setData(id);
  };

  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  //fetch loan products data
  const controller = new AbortController();
  const getLoanProducts = async () => {
    try {
      const response = await axiosPrivate.get("settings/loans/products", {
        signal: controller.signal,
      });
      setProducts(response.data.data.loan_products);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoanProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loan_products = products?.map((product) => ({
    title: product.title,
    fees: product.processing_fee,
    type: product.type,
    interest_rate: product.interest_rate,
    action: (
      <div className="btn-group">
        <button
          onClick={() => handleShow1(product)}
          className="btn btn-sm btn-info p-1 rounded-0"
        >
          view
        </button>
        <Link to={`/settings/loan/products/${product.id}`} className="btn btn-sm btn-secondary p-1 rounded-0">Auto Charges</Link>
      </div>
    ),
  }));

  const products_body = useMemo(
    () => [
      { header: "Product name", accessorKey: "title" },
      {
        header: "Processings fee",
        id: "fees",
        accessorFn: (row) => row.fees.toLocaleString(),
      },
      { header: "Interest rate", accessorKey: "interest_rate" },
      { header: "Type", accessorKey: "type" },
      { header: "Action", accessorKey: "action" },
    ],
    []
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Loan product settings{" "}
                      </h2>
                      <button
                        onClick={handleShow}
                        className="btn btn-outline-dark float-right"
                      >
                        Add loan product
                      </button>
                      <LoapProductModal
                        getLoanProducts={getLoanProducts}
                        show={show}
                        handleClose={handleClose}
                      />
                    </div>
                    <div className="card-body p-2 mt-3 mb-3">
                      {/* ....loan products view..... */}
                      <MaterialReactTable
                        columns={products_body}
                        data={loan_products}
                        className="table align-items-center mb-0"
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableRowSelection
                        enableGrouping
                        enableStickyHeader
                        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiSearchTextFieldProps={{
                          placeholder: "Search",
                          sx: { fontFamily: "Nunito", fontSize: "12px" },
                        }}
                      />
                      <ProductView
                        show1={show1}
                        handleClose1={handleClose1}
                        data={data}
                      />

                      {/* ....loan products view end..... */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
         
    </>
  );
};
export default LoanProducts;
