import Table from "pages/components/common_table";
import { useMemo, useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
const PenaltyBreakDowns = ({isLoading, refetch}) => {
   const axiosPrivate = useAxiosPrivate();
   const navigate = useNavigate();
   const location = useLocation();
   const params = useParams();
  const [data, setData] = useState([]);
  
  const loansColumns = useMemo(
    () => [
      {
        accessorKey: "timestamp",
        size: 50,
        header: "Date",
      },
      {
        id: "amount",
        header: "Amount",
        size: 50,
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        id: "code",
        header: "Transaction Code",
        size: 50,
        accessorFn: (row) => row.code,
      },
      {
        id: "status",
        header: "Status",
        size: 50,
        accessorFn: (row) =>
          row.status === "active" ? (
            <span className="badge badge-sm badge-success">Active</span>
          ) : (
            <span className="badge badge-sm badge-dark">Waived Off</span>
          ),
      },
      {
        id: "action",
        header: "Action",
        accessorFn: (row) => (
          <button className="btn btn-sm btn-danger rounded-0 p-1 text-small">Waiver</button>
        ),
      },
    ],
    []
  );
   const controller = new AbortController();
   const getPenalty = async () => {
     try {
       const response = await axiosPrivate.get(
         `/loans/loan-penalties/${params.id}`,
         {
           signal: controller.signal,
         }
       );
       setData(response?.data?.data?.penalty);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
     }
   };
   useEffect(() => {
     getPenalty();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
  return (
    <>
      <div className="row">
        <div className="col-12 p-2">
          <div className="card">
            <div className="card-header">Penalty Break downs</div>
            <div className="card-body p-0">
              <Table 
                columns = {loansColumns}
                data={data}
                isLoading={isLoading}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PenaltyBreakDowns;
