import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const BranchesModal = ({ getBranches }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("settings/branches", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getBranches();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Toaster />
      <button
        onClick={handleShow}
        className="btn btn-outline-primary float-right"
      >
        {" "}
        New Branch
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Add new branch</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSave)} noValidate>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 form-group mb-4">
                <label className="text-primary" htmlFor="">
                  Name
                </label>

                <input
                  {...register("name", { required: true })}
                  type="text"
                  placeholder="Branch name *"
                  className="form-control border-0 shadow form-control-md input-text"
                />
                {errors?.name?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
              </div>
              <div className="col-md-6 form-group mb-4">
                <label className="text-primary" htmlFor="">
                  Code
                </label>

                <input
                  {...register("code", { required: true })}
                  type="text"
                  placeholder="Branch Code e.g 111*"
                  maxlength="4"
                  className="form-control border-0 shadow form-control-md input-text"
                />
                {errors?.code?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
              </div>
              <div className="col-md-12 form-group mb-4">
                <label className="text-primary" htmlFor="">
                  Adress
                </label>
                <input
                  {...register("address", { required: true })}
                  type="text"
                  placeholder="Branch address*"
                  className="form-control border-0 shadow form-control-md input-text"
                />
                {errors?.address?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
              </div>
              <div className="col-md-12 form-group mb-4">
                <label className="text-primary" htmlFor="">
                  Description
                </label>

                <textarea
                  {...register("description", { required: true })}
                  placeholder="Description *"
                  className="form-control border-0 shadow form-control-md input-text"
                ></textarea>
                {errors?.description?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-warning" onClick={handleClose}>
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-outline-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default BranchesModal;
