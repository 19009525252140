import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const SystemUsers = ({ getSystemUsers, users }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "45px", // adjust the height as needed
  }),
};
  const [roles, setRoles] = useState([]);

  const controller = new AbortController();
  const getRoles = async () => {
    try {
      const response = await axiosPrivate.get("settings/rights/roles", {
        signal: controller.signal,
      });
      setRoles(response.data.data.role);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = roles?.map((role) => ({
    value: role.id,
    label: role.name,
  }));
   const options2 = users?.map((user) => ({
     value: user.id,
     label: `${user.firstname} ${user.lastname}`,
   }));
  const onSave = async (data) => {
    const newData = {
      firstname: data.firstname,
      lastname: data.lastname,
      gender: data.gender,
      contact: data.contact,
      email: data.email,
      role: data.role.value,
      manager: data.manager.value,
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("sacco/users", newData, {
        signal: controller.signal,
      });
      console.log(response);
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getSystemUsers();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Toaster />
      <button onClick={handleShow} className="btn btn-outline-primary">
        Add User
      </button>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body className="modal-body-event">
            <div className="row">
              <div className="col-6">
                {errors?.firstname?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  First Name{" "}
                </label>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("firstname", { required: true })}
                    placeholder="Enter firstname"
                  />
                </div>
              </div>

              <div className="col-6">
                {errors?.lastname?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Last Name{" "}
                </label>
                <div className=" form-group">
                  <input
                    type="text"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("lastname", { required: true })}
                    placeholder="Enter lastname"
                  />
                </div>
              </div>

              <div className="col-6">
                {errors?.gender?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Gender{" "}
                </label>
                <div className=" form-group">
                  <select
                    type="text"
                    className="form-control border-0 shadow form-control-lg input-text"
                    {...register("gender", { required: true })}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>

              <div className="col-6">
                {errors?.contact?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Contact{" "}
                </label>
                <div className=" form-group">
                  <input
                    type="number"
                    className=" form-control border-0 shadow form-control-md input-text"
                    {...register("contact", { required: true })}
                    placeholder="Enter Contact"
                  />
                </div>
              </div>

              <div className="col-6">
                {errors?.email?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Email{" "}
                </label>
                <div className=" form-group">
                  <input
                    type="email"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("email", { required: true })}
                    placeholder="Enter email"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <label className="text-start text-primary help-block">
                  Role{" "}
                </label>
                <div className="form-group">
                  <Controller
                    control={control}
                    name="role"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("role", { required: true })}
                        options={options}
                        onChange={onChange}
                        isMulti={false}
                        onBlur={onBlur}
                        styles={customStyles}
                        value={value}
                        name={name}
                        placeholder="Select * Role"
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.role?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select preferd roles account
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <label className="text-start text-primary help-block">
                  Managed By{" "}
                </label>
                <div className="form-group">
                  <Controller
                    control={control}
                    name="manager"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("manager", { required: true })}
                        options={options2}
                        onChange={onChange}
                        isMulti={false}
                        onBlur={onBlur}
                        styles={customStyles}
                        value={value}
                        name={name}
                        placeholder="Select Manager"
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.role?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select preferd roles account
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-warning"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SystemUsers;
