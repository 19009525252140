import React, { useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "services/loaders/loader";

const ManagerDashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [savings, setSavings] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const controller = new AbortController();
  const [isLoadingDeposits, setIsLoadingDeposits] = useState(true);
  const [isLoadingWithdraws, setIsLoadingWithdraws] = useState(true);
  const [moreData, setMoreData] = useState([]);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(true);
  const getSavings = async () => {
    try {
      const response = await axiosPrivate.get(
        "/accounting/savings/general/deposits",
        {
          signal: controller.signal,
        }
      );
      setSavings(response.data.data.transactions.slice(0,100));
      setIsLoadingDeposits(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getWithdraws = async () => {
    try {
      const response = await axiosPrivate.get(
        "/accounting/withdraw/general/withdraws",
        {
          signal: controller.signal,
        }
      );
      setWithdraws(response.data.data.transactions.slice(0,100));
      setIsLoadingWithdraws(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
    const getDashboardData = async () => {
      try {
        const response = await axiosPrivate.get("/dashboards/teller", {
          signal: controller.signal,
        });
        setMoreData(response.data.data);
        setIsLoadingMoreData(false);
      } catch (error) {
        // console.log(error)
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    };

  useEffect(() => {
    getSavings();
    getWithdraws();
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const today = new Date();
  const options = { day: "numeric", month: "numeric", year: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new shadow h-100 py-2 border-left-success border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white small text-uppercase mb-1">
                    Today's General Savings
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoadingDeposits ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {savings
                          ?.filter(
                            (transaction) =>
                              new Date(
                                transaction?.timestamp
                              ).toLocaleDateString("en-US", options) ===
                                formattedDate &&
                              transaction.reverse_status === "ongoing" &&
                              transaction.method !== "ussd"
                          )
                          ?.map((transaction) => transaction?.amount)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new shadow h-100 py-2 border-left-success border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold small text-white text-uppercase mb-1">
                    Today's General Withdraws
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoadingWithdraws ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {withdraws
                          ?.filter(
                            (transaction) =>
                              new Date(
                                transaction?.timestamp
                              ).toLocaleDateString("en-US", options) ===
                                formattedDate &&
                              transaction.reverse_status === "ongoing"
                          )
                          ?.map((transaction) => transaction?.amount)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-info  bg-hover-info bg-gradient-info card-new border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white small text-uppercase mb-1">
                    General USSD Savings
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-white">
                    {isLoadingMoreData ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {moreData?.totalGeneralUSSDSavings?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                    Not Paid SMS Cost
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-white">
                    {isLoadingMoreData ? (
                      <Loader />
                    ) : (
                      <>
                        <span>
                          UGX.{" "}
                          {(
                            moreData?.totalSMSCountBalance)?.toLocaleString()}
                        </span>{" "}
                        <br />
                        <span className="text-very-small">
                          SMS Count: {moreData?.totalSMS?.toLocaleString()}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="card mb-5 mb-lg-0">
            <div className="card-header">
              <h2 className="h6 mb-0 text-uppercase">Recent General Savings</h2>
            </div>
            <div className="card-body">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Name</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                {isLoadingDeposits ? (
                  <tbody>
                    <tr>
                      <td>
                        <Loader />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {savings?.slice(0, 5)?.map((transaction, index) => (
                      <tr key={index}>
                        <td>{transaction?.account_number}</td>
                        <td>{transaction?.account_name}</td>
                        <td>UGX. {transaction?.amount?.toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card mb-5 mb-lg-0">
            <div className="card-header">
              <h2 className="h6 mb-0 text-uppercase">
                Recent General Withdraws
              </h2>
            </div>
            <div className="card-body">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Name</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                {isLoadingWithdraws ? (
                  <tbody>
                    <tr>
                      <td>
                        <Loader />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {withdraws?.slice(0, 5)?.map((transaction, index) => (
                      <tr key={index}>
                        <td>{transaction?.account_number}</td>
                        <td>{transaction?.account_name}</td>
                        <td>UGX. {transaction?.amount?.toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerDashboard;
