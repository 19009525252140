import React, { useState, useEffect, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Table from "pages/components/common_table";

const UserByManager = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState([]);

  //fetch roles data
  const controller = new AbortController();
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get("/accounting/manage/teams", {
        signal: controller.signal,
      });
      setUsers(response.data.data.users);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const users_body = useMemo(
    () => [
      {
        header: "Name",
        id: "name",
        accessorFn: (row) => row.firstname + " " + row.lastname,
        size: 50,
      },
      { header: "Email", accessorKey: "email", size: 50 },
      { header: "Phone", accessorKey: "contact", size: 50 },
      { header: "Float Balance", id: "balance", accessorFn: (row) => row.balance.toLocaleString(), size: 50 },
      {
        header: "Status",
        id: "status",
        size: 50,
        accessorFn: (row) =>
          row.status === "active" ? (
            <div className="badge badge-success p-1">Active</div>
          ) : (
            <div className="badge badge-danger p-1">Inactive</div>
          ),
      },
      {
        header: "Action",
        id: "id",
        size: 50,
        accessorFn: (row) => (
          <Link to = {`/manage/team/${row.id}`} className="btn btn-sm p-1 btn-link text-dark rounded-0">
            <i className="fa fa-eye"></i>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <>
      <section className="py-2 mt-1">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body p-0">
                <div className="row mt-2">
                  <div className="col">
                    <Table
                      data={users ?? []}
                      columns={users_body}
                      headers={
                        <h2 className="h6 mb-0 text-uppercase text-center">
                          Manage Team
                        </h2>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default UserByManager;
