import { useState, useMemo } from "react";
import Table from "pages/components/common_table";

const MultiDesposits = () => {
  const columns = useMemo(
    () => [
      {
        id: "client_account_number", //access nested data with dot notation
        enableClickToCopy: true,
        header: "A/C No.",
        size: 50,
        accessorFn: (row) => row.client_account_number,
      },
      {
        id: "account_type", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Type",
        size: 50,
        accessorFn: (row) => row.account_type,
      },
      {
        id: "account_name", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Name",
        size: 50,
        accessorFn: (row) => row?.account_name,
      },
      {
        id: "deposit_amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => row?.deposit_amount?.toLocaleString(),
      },
      {
        id: "deposit_transaction_method", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Method",
        accessorFn: (row) => row.deposit_transaction_method,
      },

      {
        id: "deposit_transaction_code", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Transaction Code",
        accessorFn: (row) => row.deposit_transaction_code,
      },
      {
        id: "deposit_transaction_timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
        accessorFn: (row) => row.deposit_transaction_timestamp,
      },
      {
        id: "user", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "System User",
        accessorFn: (row) => row.user,
      },
      {
        id: "deposit_transaction_status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) =>
          row.deposit_transaction_status === "success" ? (
            <div className="badge badge-success p-1 text-white">Success</div>
          ) : row.deposit_transaction_status === "pending" ? (
            <div className="badge badge-info p-1 text-white">Pending</div>
          ) : row.deposit_transaction_status === "failed" ? (
            <div className="badge badge-danger p-1 text-white">Failed</div>
          ) : (
            ""
          ),
      },
      {
        id: "deposit_transaction_reverse_status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Reverse Status",
        accessorFn: (row) =>
          row.deposit_transaction_reverse_status === "ongoing" ? (
            <div className="badge badge-success p-1 text-white">
              Not Reversed
            </div>
          ) : row.deposit_transaction_reverse_status === "reversed" ? (
            <div className="badge badge-warning p-1 text-white">Reversed</div>
          ) : (
            ""
          ),
      },

      {
        id: "deposit_transaction_id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => <></>,
      },
    ],
    []
  );
  // eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState([]);
  // eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line
  const [sorting, setSorting] = useState([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  return (
    <>
      <section className="py-2 mb-4">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  isLoading={false}
                  refetch={false}
                  data={[]}
                  headers={
                    <h2 className="h6 mb-0 text-uppercase text-center">
                      Multi Deposits
                    </h2>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MultiDesposits;
