import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const LoanRepaymentGeneralModal = ({
  handleCloseModal,
  showModal,
  getLoan,
  getloanRepayment,
}) => {
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {
    data.loanid = params.id;
    console.log(data);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/general/loans/payments",
        data,
        {
          signal: controller.signal,
        }
      );

      alert(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      getLoan();
      getloanRepayment();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response?.data);
        alert(error?.response?.data?.messages);
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> General Loan Repayment </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-12 input-container">
              <label htmlFor="amount" className="form-label">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                placeholder="Enter Amount To Pay *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("amount", {
                  required: true,
                })}
              />
              {errors?.amount?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter Amount
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-danger"
              disabled={disabled}
            >
              {!disabled && "Pay"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default LoanRepaymentGeneralModal;
