import { useMemo } from "react";
import Header from "pages/components/header";
import Sidebar from "pages/components/sidebar";
import Footer from "pages/components/footer";
import {MaterialReactTable} from "material-react-table";
const UserActivity = () => {
  const activity = [];

  const tbody = useMemo(
    () => [
      { header: "Date", accessorKey: "date" },
      { header: "Time", accessorKey: "time" },
      { header: "Ip Adress", accessorKey: "adress" },
      { header: "Activity", accessorKey: "activity" },
    ],
    []
  );
  return (
    <>
      {/* .............header...................... */}
      <Header />
      {/* .............header end..................... */}

      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <Sidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        user activity
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      {/* <form className="row needs-validation" action="">
                        <div className="form-group col-md-4">
                          <input
                            type="date"
                            placeholder="Start Date"
                            className="form-control"
                          ></input>
                        </div>
                        <div className="form-group col-md-4">
                          <input
                            type="date"
                            placeholder="Start Date"
                            className="form-control"
                          ></input>
                        </div>
                        <div className="form-group col-md-4">
                          <select className="form-control-md" typeof="text">
                            <option value={{}}>Choose User</option>
                          </select>
                        </div>

                        <div className="d-flex justify-content-end mb-3 col">
                          <button type="submit" className="btn btn-primary">
                            Generate
                          </button>
                        </div>

                      </form> */}
                         <div className="col-md-12">
                          <MaterialReactTable
                            columns={tbody}
                            data={activity}
                            className="table align-items-center mb-0"
                            enableColumnFilterModes
                            enableColumnOrdering
                            enableRowSelection
                            enableGrouping
                            enableStickyHeader
                            muiTableContainerProps={{
                              sx: { maxHeight: "300px" },
                            }}
                            muiTableHeadCellProps={{
                              //simple styling with the `sx` prop, works just like a style prop in this example
                              sx: {
                                fontWeight: "normal",
                                fontSize: "13px",
                                fontFamily: "Nunito",
                              },
                            }}
                            muiTableBodyCellProps={{
                              sx: {
                                fontWeight: "normal",
                                fontSize: "13px",
                                fontFamily: "Nunito",
                              },
                            }}
                            muiSearchTextFieldProps={{
                              placeholder: "Search",
                              sx: { fontFamily: "Nunito", fontSize: "12px" },
                            }}
                          />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* footer............................ */}
          <Footer />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default UserActivity;
