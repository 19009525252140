import Table from "pages/components/common_table";

import { useMemo } from "react";
const RepaymentDetails = ({data, isLoading, refetch}) => {
   
  const loansColumns = useMemo(
    () => [
      {
        accessorKey: "code",
        header: "Receipt N0.",
        
      },
      {
       accessorKey: "reason",
       header: "Narrative"
     },
      {
        accessorKey: "timestamp",
        
        header: "Date",
      },
      {
        id: "amount",
        header: "Amount Paid",

        accessorFn: (row) => row.amount.toLocaleString(),
      },
      // {
      //   id: "action",
      //   header: "Action",
      //   accessorFn: (row) => (
      //   <button className="btn btn-sm rounded-0 p-0" > 
      //           <i className="fa fa-eye text-dark"></i>
      //   </button>)
      // }
    
    ],
    []
  );
  return (
    <>
      <>
        <div className="row">
          <div className="col-12 p-2">
            <div className="card">
              <div className="card-header">Loan Repayment Details</div>
              <div className="card-body p-0">
                {/* <MaterialReactTable
                  columns={loansColumns}
                  data={data?.filter((loan) => loan?.type === "payment")}
                  className="table align-items-center p-0"
                  enableColumnFilterModes
                  enableColumnOrdering
                  enableRowSelection
                  enableGrouping
                  enableStickyHeader
                  initialState={{ density: "compact" }}
                  muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                  muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      fontWeight: "normal",
                      fontSize: "13px",
                      fontFamily: "Nunito",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontWeight: "normal",
                      fontSize: "13px",
                      fontFamily: "Nunito",
                    },
                  }}
                  muiSearchTextFieldProps={{
                    placeholder: "Search",
                    sx: { fontFamily: "Nunito", fontSize: "12px" },
                  }}
                /> */}
                <Table
                  columns={loansColumns}
                  data={data?.filter((loan) => loan?.type === "payment")}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default RepaymentDetails;
