import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const NewPosting = ({ showModal, handleCloseModal, getLedgers }) => {
  const [disabled, setDisabled] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    let sending_account = data.sending_account.value;
    delete data.sending_account;
    data.sending_account = sending_account;
    let receiving_account = data.receiving_account.value;
    delete data.receiving_account;
    data.receiving_account = receiving_account;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/accounting/ledgers/posting",
        data,
        {
          signal: controller.signal,
        }
      );

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      getLedgers();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [accounts, setAccounts] = useState([]);
    const controller = new AbortController();
    const getAccounts = async () => {
      try {
        const response = await axiosPrivate.get("/settings/accounts/account", {
          signal: controller.signal,
        });
        setAccounts(response?.data?.data?.accounts);
      } catch (error) {
        // console.log(error)
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    };
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = accounts?.map((account) => ({
    value: `${account.id}`,
    label: `${account.title} - ${account.code}`,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> New Transfer </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-6">
              <label htmlFor="sending_account" className="form-label">
                Select Sending Account *
              </label>
              <Controller
                control={control}
                name="sending_account"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    {...register("sending_account", { required: true })}
                    className="border-1 form-control-md"
                    placeholder="Select Sending Account * "
                    styles={customStyles}
                    options={options}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    isMulti={false}
                    ref={ref}
                  />
                )}
              />
              {errors?.sending_account?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Sending Account
                </p>
              )}
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="receiving_account" className="form-label">
                Select Receiving Account *
              </label>
              <Controller
                control={control}
                name="receiving_account"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    {...register("receiving_account", { required: true })}
                    className="border-1 form-control-md"
                    placeholder="Select Receiving Account * "
                    styles={customStyles}
                    options={options}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    isMulti={false}
                    ref={ref}
                  />
                )}
              />
              {errors?.receiving_account?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Receiving Account
                </p>
              )}
            </div>

            <div className="form-group col-md-12 input-container">
              <label htmlFor="amount" className="form-label">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                placeholder="Amount to Transfer *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("amount", {
                  required: true,
                })}
              />
              {errors?.amount?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter amount to transfer
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Save"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default NewPosting;
