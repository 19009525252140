import {MaterialReactTable} from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
const LoansSummaries = () => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [loans, setLoans] = useState([]);
  const controller = new AbortController();

  const getApplications = async () => {
    try {
      const response = await axiosPrivate.get(
        `/loans/${params.id}/applications`,
        {
          signal: controller.signal,
        }
      );

      setLoans(response?.data?.data?.loans);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loansColumns = useMemo(
    () => [
      {
        accessorKey: "loan_product",
        header: "Loan Product",
        size: 50,
      },
      {
        id: "amount",
        header: "Amount",
        size: 50,
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        id: "period",
        header: "Tenure Period",
        size: 50,
        accessorFn: (row) => row.period.toLocaleString(),
      },
      {
        id: "status",
        size: 50,
        header: "Status",
        accessorFn: (row) =>
          row?.status === "pending" ? (
            <div className="badge badge-warning p-1 text-white">Pending</div>
          ) : row?.status === "approved" ? (
            <div className="badge badge-info p-1 text-white">Approved</div>
          ) : row?.status === "disbursed" ? (
            <div className="badge badge-success p-1 text-white">Disbursed</div>
          ) : row?.status === "process" ? (
            <div className="badge badge-secondary p-1 text-white">
              Processing
            </div>
          ) : row?.status === "rejected" ? (
            <div className="badge badge-danger p-1 text-white">Rejected</div>
          ) : row?.status === "settled" ? (
            <div className="badge badge-success p-1 text-white">Settled</div>
          ) : (
            ""
          ),
      },
      {
        accessorKey: "timestamp",
        size: 50,
        header: "Date",
      },
      {
        id: "id",
        size: 50, //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        accessorFn: (row) => (
          <div className="float-left text-left text-sm">
            <Link
              className="text-dark rounded-2 btn-sm p-1"
              to={`/loans/loans/${row.id}`}
            >
              View
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
          <div className="card">
            <div className="card-header">Loans</div>
            <div className="card-body p-0">
              <MaterialReactTable
                columns={loansColumns}
                data={loans}
                className="table align-items-center p-0"
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                enableGrouping
                enableStickyHeader
                initialState={{ density: "compact" }}
                muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
              />
            </div>
          </div>
    </>
  );
};

export default LoansSummaries;
