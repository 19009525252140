import React, { useMemo } from "react";
import {MaterialReactTable} from "material-react-table";
const PaymentModes = () => {
  const modes = [
    {
      mode: "Cash",
    },
    {
      mode: "Bank Transfer",
    },
    {
      mode: "Wire Transfer",
    },
    {
      mode: "Bank Deposit",
    },
    {
      mode: "Cheque",
    },
    {
      mode: "Inbound Transfer",
    },
  ];
  const tbody = useMemo(
    () => [
      {
        header: "Payment Mode",
        accessorKey: "mode",
      },
    ],
    []
  );

  return (
    <>
    
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Payment Modes
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="col-md-8">
                        <p>Availbale Payment Modes</p>
                        <MaterialReactTable
                          columns={tbody}
                          data={modes}
                          className="table align-items-center mb-0"
                          enableColumnFilterModes
                          enableColumnOrdering
                          enableRowSelection
                          enableGrouping
                          enableStickyHeader
                          muiTableContainerProps={{
                            sx: { maxHeight: "300px" },
                          }}
                          muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiSearchTextFieldProps={{
                            placeholder: "Search",
                            sx: { fontFamily: "Nunito", fontSize: "12px" },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
    </>
  );
};

export default PaymentModes;
