import { useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
const AddAccounts = ({ showModal, handleClose, getAccounts }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
   const get = async (controller) => {
     try {
       const response = await axiosPrivate.get("/settings/accounts/account", {
         signal: controller.signal,
       });
       setAccounts(response.data.data.accounts);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
     }
   };
  useEffect(() => {
    const controller = new AbortController();
    get(controller);
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = accounts?.filter((account) => account.type === "savings" || account.type === "fixed")?.map((account) => ({
    value: `${account.id}`,
    label: `${account.title}`,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const onSave = async (data) => {
    //   console.log(data.account);
    for (var i = 0; i < data.account.length; i++) {
      try {
        const newData = {
          client: params.id,
          account: data.account[i].value,
        };
        progress.start();
        setDisabled(true);
        const controller = new AbortController();
        const response = await axiosPrivate.post(
          "/accounts/attached/accounts",
          newData,
          {
            signal: controller.signal,
          }
        );
        toast.success(response?.data?.messages);
        progress.finish();
        setDisabled(false);
        reset();
        getAccounts();
        handleClose();
      } catch (error) {
        if (!error?.response) {
          toast.error("No server response");
          progress.finish();
          setDisabled(false);
        } else {
          console.log(error?.response);
          progress.finish();
          setDisabled(false);
          if (error?.response?.status === 401) {
            navigate("/", { state: { from: location }, replace: true });
          }
          toast.error(error?.response?.data?.messages, {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add Accounts
          </Modal.Title>
          <button onClick={handleClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="container row">
              <div className="form-group col-md-12">
                <label htmlFor="account" className="form-label">
                  Select Account To Attach *
                </label>
                <Controller
                  control={control}
                  name="account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("account", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Accounts To Attach * "
                      styles={customStyles}
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={true}
                      ref={ref}
                    />
                  )}
                />
                {errors?.account?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    Please Select Accounts To Attach
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddAccounts;
