import {MaterialReactTable} from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
const Communication = ({ showSMSModal, handleSMSClose, showEmailModal, handleEmailClose}) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
   const {
     register: register1,
     handleSubmit: handleSubmit1,
     reset: reset1,
     formState: { errors: errors1 },
   } = useForm();
  const onSMSSave = async (data) => {
     try {
       progress.start();
       setDisabled(true);
       const controller = new AbortController();
       const response = await axiosPrivate.post("/communication/sms", data, {
         signal: controller.signal,
       });
       toast.success(response?.data?.messages);
       progress.finish();
       setDisabled(false);
       handleSMSClose();
       getSms();
       reset();
     } catch (error) {
       if (!error?.response) {
         toast.error("No server response");
         progress.finish();
         setDisabled(false);
       } else {
         console.log(error?.response);
         progress.finish();
         setDisabled(false);
         if (error?.response?.status === 401) {
           navigate("/", { state: { from: location }, replace: true });
         }
         toast.error(error?.response?.data?.messages, {
           style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
         });
       }
     }
  };
   const onEmailSave = async (data) => {
     try {
       progress.start();
       setDisabled(true);
       const controller = new AbortController();
       const response = await axiosPrivate.post("/communication/emails", data, {
         signal: controller.signal,
       });
       toast.success(response?.data?.messages);
       progress.finish();
       setDisabled(false);
       handleEmailClose();
       getEmail();
       reset1();
     } catch (error) {
       if (!error?.response) {
         toast.error("No server response");
         progress.finish();
         setDisabled(false);
       } else {
         console.log(error?.response);
         progress.finish();
         setDisabled(false);
         if (error?.response?.status === 401) {
           navigate("/", { state: { from: location }, replace: true });
         }
         toast.error(error?.response?.data?.messages, {
           style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
         });
       }
     }
   };
  const [sms, setSms] = useState([]);
  const [emails, setEmails] = useState([]);
  const controller = new AbortController();
  const getSms = async () => {
    try {
      const response = await axiosPrivate.get(
        `/communication/${params.id}/sms`,
        {
          signal: controller.signal,
        }
      );

      setSms(response?.data?.data?.sms);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getEmail = async () => {
    try {
      const response = await axiosPrivate.get(
        `/communication/${params.id}/emails`,
        {
          signal: controller.signal,
        }
      );

      setEmails(response?.data?.data?.emails);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {    
    getEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const smscolumns = useMemo(
    () => [
      {
        accessorKey: "message", //access nested data with dot notation
        header: "Message",
        size: 50,
      },
      {
        accessorKey: "length", //access nested data with dot notation
        header: "Message Length",
        size: 50,
      },
      {
        accessorKey: "timestamp",
        size: 50, //access nested data with dot notation
        header: "Date",
      },
    ],
    []
  );
  const emailcolumns = useMemo(
    () => [
      {
        accessorKey: "subject", //access nested data with dot notation
        header: "Subject",
        size: 50,
      },
      {
        accessorKey: "message", //access nested data with dot notation
        header: "Message",
        size: 50,
      },
      {
        accessorKey: "timestamp", //access nested data with dot notation
        header: "Date",
        size: 50,
      },
    ],
    []
  );
  return (
    <>
      <Toaster />
      <div className="row">
        <div className="col-12 p-2">
          <div className="card">
            <div className="card-header">SMS Report</div>
            <div className="card-body p-0">
              <MaterialReactTable
                columns={smscolumns}
                data={sms}
                className="table align-items-center p-0"
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                enableGrouping
                enableStickyHeader
                initialState={{ density: "compact" }}
                muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12 p-2">
          <div className="card">
            <div className="card-header">Email Report</div>
            <div className="card-body p-0">
              <MaterialReactTable
                columns={emailcolumns}
                data={emails}
                initialState={{ density: "compact" }}
                className="table align-items-center p-0"
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                enableGrouping
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSMSModal}
        backdrop="static"
        keyboard={false}
        onHide={handleSMSClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Send SMS
          </Modal.Title>
          <button onClick={handleSMSClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSMSSave)}>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12 input-container">
                <label htmlFor="title" className="form-label">
                  Enter SMS
                </label>
                <textarea
                  type="text"
                  name="title"
                  placeholder="Enter SMS *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  {...register("message", { required: true })}
                ></textarea>
                <input
                  type="hidden"
                  value={params.id}
                  {...register("clientid", { required: true })}
                />
                {errors?.message?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    please enter sms message
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleSMSClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Send"} {disabled && "Please Wait ... "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showEmailModal}
        backdrop="static"
        keyboard={false}
        onHide={handleEmailClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Send Email
          </Modal.Title>
          <button onClick={handleEmailClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit1(onEmailSave)}>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12 input-container">
                <label htmlFor="message" className="form-label">
                  Enter Subject
                </label>
                <input
                  type="text"
                  name="subject"
                  placeholder="Enter Subject *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  {...register1("subject", { required: true })}
                />
                <input
                  type="hidden"
                  value={params.id}
                  {...register1("clientid", { required: true })}
                />
                {errors1?.subject?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter email subject
                  </p>
                )}
              </div>
              <div className="form-group col-md-12 input-container">
                <label htmlFor="message" className="form-label">
                  Enter Email
                </label>
                <textarea
                  type="text"
                  name="message"
                  placeholder="Enter Email Body *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  {...register1("message", { required: true })}
                ></textarea>
                <input
                  type="hidden"
                  value={params.id}
                  {...register1("clientid", { required: true })}
                />
                {errors1?.message?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    please enter email body
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleEmailClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Send"} {disabled && "Please Wait ... "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Communication;
