import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const RollOverModal = ({
  showModal,
  handleCloseModal,
  getloan,
}) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
   data.loanid = params.id;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(`/loans/actions/rollover`, data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getloan();
      handleCloseModal();
    } catch (error) {
      if (!error?.response) {
        console.log(error);
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <>
              <Modal.Header>
                <Modal.Title className="text-center">
                  {" "}
                  Rollover Loan
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="row mx-auto">
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="start_date" className="form-label">
                   Start Date
                  </label>
                  <input
                    type="date"
                    name="start_date"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("start_date", { required: true })}
                  />
                  {errors?.start_date?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please Select Date
                    </p>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit(onSave)}
                  disabled={disabled}
                >
                  {!disabled && "Take Action"} {disabled && "Please Wait ....."}
                </Button>
              </Modal.Footer>
            </>
          </form>
        </>
      </Modal>
    </>
  );
};

export default RollOverModal;
