import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const CreditScore = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [clients, setClients] = useState([]);
  const controller = new AbortController();
  const getClients = async () => {
    progress.start();
    try {
      const response = await axiosPrivate.get("/clients/general", {
        signal: controller.signal,
      });
      // console.log(response.data.data.clients);
      setClients(response.data.data.clients);

      progress.finish();
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientsData = clients?.map((client) => ({
    value: client.id,
    label: `${client.name} - ${client.account} - ${client.contact}`,
  }));
  const [disabled, setDisabled] = useState(false);
  const [score, setScore] = useState([]);
  const [credit, setCredit]= useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post(
        `/reports/new/credit-score`,
        { client_id: data.client_id.value },
        {
          signal: controller.signal,
        }
      );
      setScore(response.data.data.data);
      setCredit(response.data.data.credit_score);
      console.log(score);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const ranges = {
    poor: { min: 0, max: 300, color: "#FF5733" },
    fair: { min: 301, max: 579, color: "#FFA833" },
    good: { min: 580, max: 669, color: "#F0D632" },
    veryGood: { min: 670, max: 739, color: "#32F032" },
    excellent: { min: 740, max: 850, color: "#328CF0" },
  };

  // Determine the credit score range
  let range = "Unknown";
  for (const [key, value] of Object.entries(ranges)) {
    if (credit >= value.min && credit <= value.max) {
      range = key;
      break;
    }
  }

  // Chart.js data and options
  const data = {
    datasets: [
      {
        data: [100],
        backgroundColor: [ranges[range]?.color],
      },
    ],
  };

  const options = {
    cutoutPercentage: 70,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    elements: {
      center: {
        text: range.toUpperCase(),
        fontStyle: "Nunito",
        sidePadding: 15,
      },
    },
  };
  return (
    <>
      <Toaster />
      <div className="card mt-2">
        <div className="card-header bg-dark">
          <p className="h5 text-bold text-uppercase text-center text-white">
            Credit Score{" "}
          </p>
        </div>
        <div className="card-body mt-3 mb-3">
          <div className="row mt-3">
            <div className="col-md-12 mb-3">
              <form
                className="row needs-validation"
                onSubmit={handleSubmit(onQuery)}
              >
                <div className="form-group col-md-4">
                  <label htmlFor="client_id" className="form-label">
                    Select a Member
                  </label>
                  <Controller
                    control={control}
                    name="client_id"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("client_id", { required: true })}
                        options={clientsData}
                        onChange={onChange}
                        isMulti={false}
                        onBlur={onBlur}
                        name={name}
                        className="shadow border-0"
                        value={value}
                        styles={customStyles}
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.client_id?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please Select Member
                    </p>
                  )}
                </div>

                <div className="form-group col-md-3 pt-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-center rounded-0"
                    disabled={disabled}
                  >
                    {disabled ? "Please Wait ....." : "Calculate"}
                  </button>
                </div>
              </form>
            </div>

            <div className="col-md-6">
              <table className="table table-hover table-bordered text-medium-small">
                <thead>
                  <tr>
                    <th colSpan="2">Personal Information</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{score?.client?.name}</td>
                  </tr>

                  <tr>
                    <th>Account Number</th>
                    <td>{score?.client?.account_number}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <table className="table table-hover table-bordered text-medium-small">
                <thead>
                  <tr>
                    <th colSpan="2">Savings Summary</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>Savings Made</th>
                    <td> {score?.saving_count}</td>
                  </tr>
                  <tr>
                    <th>Savings Portfolio</th>
                    <td>UGX {score?.saving_total?.toLocaleString()}</td>
                  </tr>

                  <tr>
                    <th>Withdraws Made</th>
                    <td> {score?.withdraw_count}</td>
                  </tr>

                  <tr>
                    <th>Withdraws Made</th>
                    <td>UGX {score?.withdraw_total?.toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <table className="table table-hover table-bordered text-medium-small">
                <thead>
                  <tr>
                    <th colSpan="2">Loan History</th>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>Active</th>
                    <td>{score?.disbursedLoansCount}</td>
                  </tr>

                  <tr>
                    <th>Completed</th>
                    <td>{score?.settledLoansCount}</td>
                  </tr>

                  <tr>
                    <th>Defaulted</th>
                    <td>{score?.overDueLoansCount}</td>
                  </tr>

                  <tr>
                    <th>Rejected</th>
                    <td>{score?.rejectedLoansCount}</td>
                  </tr>

                  <tr>
                    <th>Waived</th>
                    <td>{score?.waivedoffLoansCount}</td>
                  </tr>

                  <tr>
                    <th>Written Off</th>
                    <td>{score?.writternoffLoansCount}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <Doughnut data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditScore;
