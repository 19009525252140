import React, { useEffect, useMemo, useState } from "react";

import {MaterialReactTable} from "material-react-table";
import { useNavigate, useLocation } from "react-router-dom";

import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import WithdrawSettingsModal from "pages/settings/modals/withdraw_settings";
const SaccoWthdrawSettings = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [settings, setSettings] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // table data

  const controller = new AbortController();
  const getSettings = async () => {
    try {
      const response = await axiosPrivate.get("settings/withdraws/teller", {
        signal: controller.signal,
      });
      setSettings(response.data.data.withdraw_settings);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const withdarw_settings = settings?.map((setting) => ({
    account: setting.account,
    charge: setting.amount,
    from: setting.min_value,
    to: setting.max_value,
    mode: setting.type,
  }));

  const settings_body = useMemo(
    () => [
      { header: "Account", accessorKey: "account" },
      { header: "Charge", accessorKey: "charge" },
      { header: "Amount from", accessorKey: "from" },
      { header: "Amount to", accessorKey: "to" },
      { header: "Mode of deduction", accessorKey: "mode" },
    ],
    []
  );

  return (
    <>
      
            <section className="mt-1">
              <div className="card">
                <div className="card-header">
                  <h2 className="h6 mb-0 text-uppercase text-center">
                    Sacco withdarw settings
                  </h2>
                  <button
                    onClick={handleShow}
                    className="btn btn-outline-primary float-right"
                  >
                    Add setting
                  </button>
                  <WithdrawSettingsModal
                    show={show}
                    handleClose={handleClose}
                    getSettings={getSettings}
                  />
                </div>

                <div className="card-body p-0">
                  <MaterialReactTable
                    columns={settings_body}
                    data={withdarw_settings}
                    className="table align-items-center mb-0"
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableRowSelection
                    enableGrouping
                    enableStickyHeader
                    muiTableContainerProps={{
                      sx: { maxHeight: "300px" },
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: "normal",
                        fontSize: "13px",
                        fontFamily: "Nunito",
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        fontWeight: "normal",
                        fontSize: "13px",
                        fontFamily: "Nunito",
                      },
                    }}
                    muiSearchTextFieldProps={{
                      placeholder: "Search",
                      sx: { fontFamily: "Nunito", fontSize: "12px" },
                    }}
                  />
                </div>
              </div>
            </section>
    </>
  );
};

export default SaccoWthdrawSettings;
