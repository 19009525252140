import { useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";

const CompusloryTransfers = ({showModal, handleCloseModal, accounts, getAccounts}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const controller = new AbortController();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
 const customStyles = {
   control: (provided) => ({
     ...provided,
     height: "45px", // adjust the height as needed
   }),
 };
   const options = accounts?.map((account) => ({
     value: `${account.id}`,
     label: `${account.title}`,
   }));
    const onSave = async (data) => {
      data.account = data.account.value;
      try {
        progress.start();
        setDisabled(true);
        const response = await axiosPrivate.post(
          "/accounting/savings/compuslory/transfers",
          data,
          {
            signal: controller.signal,
          }
        );
        toast.success(response?.data?.messages);
        progress.finish();
        setDisabled(false);
        handleCloseModal();
        reset();
        getAccounts();
      } catch (error) {
        if (!error?.response) {
          toast.error("No server response");
          progress.finish();
          setDisabled(false);
        } else {
          console.log(error?.response);
          progress.finish();
          setDisabled(false);
          if (error?.response?.status === 401) {
            navigate("/", { state: { from: location }, replace: true });
          }
          toast.error(error?.response?.data?.messages, {
            style: {
              background: "#e91e63",
              fontSize: "13px",
              color: "#fff",
            },
          });
        }
      }
    };

    return (
      <>
        <Toaster />
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={handleCloseModal}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Compulsory Transfer
            </Modal.Title>
            <button onClick={handleCloseModal} className="btn-close btn-danger">
              <i className="material-icons opacity-10">close</i>
            </button>
          </Modal.Header>
          <form noValidate onSubmit={handleSubmit(onSave)} autoComplete="off">
            <Modal.Body>
              <div className="row">
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="account" className="form-label">
                    Select Account
                  </label>
                  <Controller
                    control={control}
                    name="account"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("account", { required: true })}
                        className="border-1 form-control-md"
                        placeholder="Select Account * "
                        styles={customStyles}
                        options={options}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        isMulti={false}
                        isClearable={true}
                        ref={ref}
                      />
                    )}
                  />

                  {errors?.account?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please Select Account
                    </p>
                  )}
                </div>
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="amount" className="form-label">
                    Amount To Transfer
                  </label>
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter Amount *"
                    className="form-control border-0 shadow form-control-md input-text"
                    rows={5}
                    {...register("amount", { required: true })}
                  />

                  {errors?.amount?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please Enter Amount To Transfer
                    </p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                disabled={disabled}
                type="submit"
                className="btn btn-primary"
              >
                {!disabled && "Save"} {disabled && "Please Wait ... "}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
}
 
export default CompusloryTransfers;