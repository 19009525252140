import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
const GeneralReportsModals = ({ handleAccountReport, openAccountReport }) => {
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  //eslint-disable-next-line
  const [datas, setDatas] = useState([]);
  //eslint-disable-next-line
  const [information, setInformation] = useState([]);
  //eslint-disable-next-line
  const [deposits, setDeposits] = useState([]);
  //eslint-disable-next-line
  const [withdraws, setWithdraws] = useState([]);
  const onSave = async (data) => {
    data.client_id = params.id;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `/reports/general/account`,
        data,
        {
          signal: controller.signal,
        }
      );

      setDatas(response.data.data.datas);
      console.log(response.data.data.datas);
      progress.finish();
      setDisabled(false);
      handleAccountReport();
      // setOpenModal(true);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  // const length = datas?.length;
  // console.log(length)
  const date_options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const data = [...deposits, ...withdraws];
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
  return (
    <>
      <Toaster />
      <Modal
        show={openAccountReport}
        backdrop="static"
        keyboard={false}
        onHide={handleAccountReport}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Generate General Statement
          </Modal.Title>
          <button
            onClick={handleAccountReport}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="container row">
              <div className="form-group col-md-6 input-container">
                <label htmlFor="start_date" className="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  name="start_date"
                  placeholder="Start Date *"
                  className="form-control border-0 shadow form-control-md input-text"
                  {...register("start_date", { required: true })}
                />
                {errors?.start_date?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Start Date
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="end_date" className="form-label">
                  End Date
                </label>
                <input
                  type="date"
                  name="end_date"
                  placeholder="End Date *"
                  className="form-control border-0 shadow form-control-md input-text"
                  {...register("end_date", { required: true })}
                />
                {errors?.end_date?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter End Date
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleAccountReport}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Generate"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={openModal}
        backdrop="static"
        keyboard={false}
        onHide={() => setOpenModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Account Statement ({information?.account})
          </Modal.Title>
          <button
            onClick={() => setOpenModal(false)}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body style={{ height: "500px", overflowY: "auto" }}>
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th className="text-center" colSpan="7">
                    <h6 style={{ textAlign: "right" }}>
                      <b>From:</b> {information?.start_date} <br /> <b>To:</b>{" "}
                      {information?.end_date}
                    </h6>
                  </th>
                </tr>
                <tr>
                  <th colSpan={8}>
                    <span className="float-left">
                      Account Name: {information?.account_name} <br />
                      Account Number: {information?.account_number}
                    </span>
                    <span className="float-right">
                      Account Balance: {information?.account_balance}
                    </span>
                  </th>
                </tr>

                <tr>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Comment</th>
                  <th>Money Out</th>
                  <th>Money In</th>
                </tr>
              </thead>

              <tbody>
                {sortedData?.map((row, index) =>
                  row.type === "credit" ? (
                    <tr>
                      <td>
                        {new Date(row?.date).toLocaleDateString(
                          "en-US",
                          date_options
                        )}
                      </td>
                      <td>{row?.reference}</td>
                      <td>{row?.comment}</td>
                      <td></td>
                      <td>{row?.amount?.toLocaleString()}</td>
                    </tr>
                  ) : row.type === "debit" ? (
                    <>
                      <tr>
                        <td>
                          {" "}
                          {new Date(row?.date).toLocaleDateString(
                            "en-US",
                            date_options
                          )}
                        </td>
                        <td>{row?.reference}</td>
                        <td>{row?.comment}</td>
                        <td>{row?.amount?.toLocaleString()}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          {new Date(row?.date).toLocaleDateString(
                            "en-US",
                            date_options
                          )}
                        </td>
                        <td></td>
                        <td>Withdraw Charge</td>
                        <td>{row?.charges?.toLocaleString()}</td>
                        <td></td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => setOpenModal(false)}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Download"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default GeneralReportsModals;
