import React, { useEffect, useState, useMemo } from "react";
import SubAccount from "pages/settings/modals/sub-account-group";
import AccountsModal from "pages/settings/modals/accounts";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import {MaterialReactTable} from "material-react-table";
const SaccoAccounts = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);

  const [account_groups, setAccount_groups] = useState([]);

  const [subaccounts, setSubaccounts] = useState([]);

  const [accounts, setAccounts] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const saveGroup = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "settings/accounts/groups",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  // get accout groups data
  const controller = new AbortController();
  const getGroupAccounts = async () => {
    try {
      const response = await axiosPrivate.get("/settings/accounts/groups", {
        signal: controller.signal,
      });
      setAccount_groups(response.data.data.account_groups);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getGroupAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groups = account_groups?.map((account_group) => ({
    title: account_group.title,
    code: account_group.code,
  }));

  const tbody = useMemo(
    () => [
      { header: "Title", accessorKey: "title" },
      { header: "Code", accessorKey: "code" },
    ],
    []
  );

  //   load subaccount table data
  const getSubaccounts = async () => {
    try {
      const response = await axiosPrivate.get("settings/accounts/sub/groups", {
        signal: controller.signal,
      });
      setSubaccounts(response.data.data.account_sub_groups);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSubaccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const subgroups = subaccounts?.map((subaccount) => ({
    group_title: subaccount.title,
    group_code: subaccount.code,
    group: subaccount.group,
  }));
  const tbody2 = useMemo(
    () => [
      { header: "Sub Account", accessorKey: "group_title" },
      { header: "Code", accessorKey: "group_code" },
      { header: "Account Group", accessorKey: "group" },
    ],
    []
  );

  //   load account table data
  const getAccounts = async () => {
    try {
      const response = await axiosPrivate.get("/settings/accounts/account", {
        signal: controller.signal,
      });
      setAccounts(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const account_ = accounts?.map((account) => ({
    account_title: account.title,
    account_code: account.code,
    sub_group: account.sub_group,
    group: account.group,
    opening_balance: account.opening_balance,
    status: account.status,
  }));
  const account_body = useMemo(
    () => [
      { header: "Account", accessorKey: "account_title" },
      { header: "Code", accessorKey: "account_code" },
      { header: "Sub account group", accessorKey: "sub_group" },
      { header: "Account group", accessorKey: "group" },
      { header: "Balance", id: "opening_balance", accessorFn: (row) => row.opening_balance.toLocaleString() },
      {
        header: "Status",
        id: "status",
        accessorFn: (row) =>
          row.status === "active" ? (
            <div className="badge badge-success p-1">Active</div>
          ) : (
            <div className="badge badge-danger p-1">Inactive</div>
          ),
      },
    ],
    []
  );

  const [chart_of_accounts, setChart_of_accounts] = useState([]);
  //   load chart of account table
  const getChart_of_accounts = async () => {
    try {
      const response = await axiosPrivate.get(
        "settings/accounts/chart-of-accounts",
        {
          signal: controller.signal,
        }
      );
      setChart_of_accounts(response.data.data.chart_of_accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getChart_of_accounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toaster />
     
            <section className="py-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        account defaults{" "}
                      </h2>
                    </div>
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="nav nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <button
                              className="border nav-link active mr-2 px-3 py-2 h6 text-center"
                              id="v-pills-home-tab"
                              data-toggle="pill"
                              href="#v-pills-account-groups"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              Account Groups
                            </button>
                            <button
                              className="border nav-link mr-2 px-3 py-2 h6 text-center"
                              id="v-pills-accounts-tab"
                              data-toggle="pill"
                              href="#v-pills-sub-account-groups"
                              role="tab"
                              aria-controls="v-pills-account-groups"
                              aria-selected="false"
                            >
                              Sub Account Groups
                            </button>
                            <button
                              className="border nav-link mr-2 px-3 py-2 h6 text-center"
                              id="v-pills-accounts-tab"
                              data-toggle="pill"
                              href="#v-pills-accounts"
                              role="tab"
                              aria-controls="v-pills-accounts"
                              aria-selected="false"
                            >
                              Accounts
                            </button>
                            <button
                              className="border nav-link mr-2 px-3 py-2 h6 text-center"
                              id="v-pills-messages-tab"
                              data-toggle="pill"
                              href="#v-pills-charts-of-accounts"
                              role="tab"
                              aria-controls="v-pills-messages"
                              aria-selected="false"
                            >
                              Charts Of Accounts
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-account-groups"
                          role="tabpanel"
                          aria-labelledby="v-pills-account-groups-tab"
                        >
                          <div className="row container d-flex justify-content-center mt-5">
                            <div className="row col-11">
                              <div className="col-md-6">
                                <form
                                  onSubmit={handleSubmit(saveGroup)}
                                  className="needs-validation"
                                  noValidate
                                >
                                  <div className="form-group">
                                    <label className="form-label">
                                      Account Group Name
                                    </label>
                                    <input
                                      {...register("title", { required: true })}
                                      type="text"
                                      placeholder="Enter Account Group Name"
                                      className="form-control  border-0 shadow form-control-md input-text"
                                    />
                                    {errors?.title?.type === "required" && (
                                      <span className="text-danger">
                                        Group name required
                                      </span>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label className="form-label">
                                      Account Group Code
                                    </label>
                                    <input
                                      {...register("code", { required: true })}
                                      type="text"
                                      placeholder="Enter Account Group Code"
                                      className="form-control border-0 shadow  form-control-md input-text"
                                    />
                                    {errors?.code?.type === "required" && (
                                      <span className="text-danger">
                                        Group code required
                                      </span>
                                    )}
                                  </div>
                                  <div className="form-group  text-right">
                                    <button
                                      disabled={disabled}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      {!disabled && "Add account group"}{" "}
                                      {disabled && "Please Wait"}
                                    </button>
                                  </div>
                                </form>
                              </div>
                              <div className="col-md-6">
                                <h6 align="right">Account Groups</h6>
                                <MaterialReactTable
                                  columns={tbody}
                                  data={groups}
                                  className="table align-items-center mb-0"
                                  enableColumnFilterModes
                                  enableColumnOrdering
                                  enableRowSelection
                                  enableGrouping
                                  enableStickyHeader
                                  muiTableContainerProps={{
                                    sx: { maxHeight: "300px" },
                                  }}
                                  muiTableHeadCellProps={{
                                    //simple styling with the `sx` prop, works just like a style prop in this example
                                    sx: {
                                      fontWeight: "normal",
                                      fontSize: "13px",
                                      fontFamily: "Nunito",
                                    },
                                  }}
                                  muiTableBodyCellProps={{
                                    sx: {
                                      fontWeight: "normal",
                                      fontSize: "13px",
                                      fontFamily: "Nunito",
                                    },
                                  }}
                                  muiSearchTextFieldProps={{
                                    placeholder: "Search",
                                    sx: {
                                      fontFamily: "Nunito",
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade show"
                          id="v-pills-sub-account-groups"
                          role="tabpanel"
                          aria-labelledby="v-pills-account-groups-tab"
                        >
                          <div className="row  mt-5">
                            <div className="mb-4 col d-flex justify-content-end">
                              <SubAccount getSubaccounts={getSubaccounts} />
                            </div>
                          </div>
                          <div>
                            <MaterialReactTable
                              columns={tbody2}
                              data={subgroups}
                              className="table align-items-center mb-0"
                              enableColumnFilterModes
                              enableColumnOrdering
                              enableRowSelection
                              enableGrouping
                              enableStickyHeader
                              muiTableContainerProps={{
                                sx: { maxHeight: "300px" },
                              }}
                              muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiTableBodyCellProps={{
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiSearchTextFieldProps={{
                                placeholder: "Search",
                                sx: {
                                  fontFamily: "Nunito",
                                  fontSize: "12px",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="tab-pane fade show"
                          id="v-pills-accounts"
                          role="tabpanel"
                          aria-labelledby="v-pills-account-groups-tab"
                        >
                          <div className="row  mt-5">
                            <div className="mb-4 col d-flex justify-content-end">
                              <AccountsModal getAccounts={getAccounts} />
                            </div>
                          </div>
                          <div>
                            <MaterialReactTable
                              columns={account_body}
                              data={account_}
                              className="table align-items-center mb-0"
                              enableColumnFilterModes
                              enableColumnOrdering
                              enableRowSelection
                              enableGrouping
                              enableStickyHeader
                              muiTableContainerProps={{
                                sx: { maxHeight: "300px" },
                              }}
                              muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiTableBodyCellProps={{
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiSearchTextFieldProps={{
                                placeholder: "Search",
                                sx: {
                                  fontFamily: "Nunito",
                                  fontSize: "12px",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="tab-pane fade show"
                          id="v-pills-charts-of-accounts"
                          role="tabpanel"
                          aria-labelledby="v-pills-account-groups-tab"
                        >
                          <div className="card">
                            <div className="card-body">
                              <h2 className="h6 mb-0 text-uppercase text-center mb-4">
                                View Charts of Accounts{" "}
                              </h2>
                              <table className="table table-bordered">
                                <thead
                                  style={{
                                    background: "#F2F2F2",
                                  }}
                                >
                                  <tr>
                                    <td>Account Groups</td>
                                    <td>Account Group Code</td>
                                    <td>Sub Accounts</td>
                                    <td>Sub Accounts Code</td>
                                    <td>Accounts</td>
                                    <td>Accounts Code</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {chart_of_accounts?.map((account) => {
                                    const subAccounts =
                                      account?.sub_accounts || [];
                                    const subAccountRows = subAccounts.map(
                                      (subAccount) => {
                                        const accounts =
                                          subAccount?.accounts || [];
                                        const accountRows = accounts.map(
                                          (account) => {
                                            return (
                                              <tr key={account.id}>
                                                <td colSpan={4}></td>
                                                <td className="col-md-2">
                                                  {account.title}
                                                </td>
                                                <td className="col-md-2">
                                                  {account.code}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        );

                                        return (
                                          <React.Fragment key={subAccount.id}>
                                            <tr
                                              style={{
                                                background: "#D9D9D9",
                                              }}
                                            >
                                              <td colSpan={2}></td>
                                              <td className="col-md-2">
                                                {subAccount.title}
                                              </td>
                                              <td className="col-md-2">
                                                {subAccount.code}
                                              </td>
                                              <td colSpan={2}></td>
                                            </tr>
                                            {accountRows}
                                          </React.Fragment>
                                        );
                                      }
                                    );

                                    return (
                                      <React.Fragment key={account.id}>
                                        <tr>
                                          <td className="col-md-2">
                                            {account.account_name}
                                          </td>
                                          <td className="col-md-2">
                                            {account.account_code}
                                          </td>
                                          <td colSpan={4}></td>
                                        </tr>
                                        {subAccountRows}
                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              </table>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
         
    </>
  );
};
export default SaccoAccounts;
