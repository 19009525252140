import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
const Footer = () => {
      const [year, setYear] = useState("");

      useEffect(() => {
        const currentYear = new Date().getFullYear();
        setYear(currentYear);
      }, [year]);
    return (
      <footer
        className="footer bg-white shadow align-self-end px-xl-5 w-100 fixed-bottom"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 text-start text-md-left text-secondary">
              <p className="mb-2 mb-md-0">
                &copy; <span>{year}</span> iRembo Finance
              </p>
            </div>
            <div className="col-md-6 text-end text-md-right text-gray-400">
              <p className="mb-0">
                <span className="text-dark">Powered by</span>{" "}
                <Link to="/" className="external text-warning">
                  Mob
                  <i
                    className="fa fa-info-circle text-success"
                    aria-hidden="true"
                  ></i>
                  tungo
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
}
 
export default Footer;