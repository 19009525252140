import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import toast, { Toaster } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";
import Select from "react-select";
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="pagination">
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="btn btn-sm btn-link text-dark rounded-0">
        {currentPage}
      </span>
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};
const TillSheet = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tellers, setTellers] = useState([]);
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTeller, setSelectedTeller] = useState(null);
  const {
    register,
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const controller = new AbortController();

  // Fetch tellers data
  const getTellers = async () => {
    try {
      const response = await axiosPrivate.get("settings/users/tellers", {
        signal: controller.signal,
      });
      setTellers(response?.data?.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getTellers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tellersData = tellers.map((teller) => ({
    value: teller.id,
    label: teller.name,
  }));

  const onQuery = async (formData) => {
    const teller = formData.teller.value;
    const date = new Date(formData.date).toISOString().split("T")[0];
    const queryPayload = { teller, date };

    try {
      progress.start();
      setDisabled(true);

      const response = await axiosPrivate.post(
        `/reports/new/till-sheet`,
        queryPayload,
        { signal: controller.signal }
      );

      setData(response?.data);
      progress.finish();
      setDisabled(false);
      resetForm(); // Reset form fields using react-hook-form's reset function
      setSelectedDate(null); // Reset selectedDate state
      setSelectedTeller(null); // Reset selectedTeller state
    } catch (error) {
      console.error("Error fetching till sheet data:", error);
      if (!error?.response) {
        toast.error("No server response");
      } else {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
      progress.finish();
      setDisabled(false);
    }
  };

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.data?.transactions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.data?.transactions.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const downloadReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      progress.start();

      const response = await axiosPrivate.post(
        `/export/reports/till_sheet`,
        data,
        { responseType: "blob" }
      );

      const tellerName =
        data?.data?.transactions?.[0]?.tellerName?.replace(/\s+/g, "_") ||
        "Teller";
      const reportingDate =
        data?.data?.reportingDate?.replace(/\s+/g, "_") || "Date";
      const filename = `${tellerName}_${reportingDate}_till_sheet_report_.pdf`;
      fileDownload(response.data, filename);
      progress.finish();
    } catch (error) {
      console.error("Error downloading till sheet report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      progress.finish();
    }
  };

  return (
    <>
      <Toaster />
      <div className="card mt-2">
        <div className="container-fluid">
          <div className="card">
            <div className="row">
              <div className="col-lg-12">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-header bg-dark">
                    <h2 className="h5 p-1 text-bold text-uppercase text-center text-white">
                      Till Sheet
                    </h2>
                  </div>
                  <div className="card-body mt-3 mb-3">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <form
                          className="row needs-validation"
                          id="generalLegerForm"
                          onSubmit={handleSubmit(onQuery)}
                        >
                          <div className="form-group col-md-3">
                            <label className="form-label">
                              Select Today's Date
                            </label>
                            <input
                              type="date"
                              placeholder="Select today's Date"
                              className="form-control shadow border-1"
                              {...register("date", {
                                required: true,
                              })}
                              style={{ height: "45px" }}
                              value={selectedDate || ""}
                              onChange={(e) => setSelectedDate(e.target.value)}
                            />
                            {errors?.date?.type === "required" && (
                              <p className="text-small m-0 text-danger help-block">
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-3">
                            <label className="form-label">Select Teller</label>
                            <Controller
                              control={control}
                              name="teller"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                              }) => (
                                <Select
                                  {...register("teller", {
                                    required: true,
                                  })}
                                  options={tellersData}
                                  onChange={(selectedOption) => {
                                    setSelectedTeller(selectedOption);
                                    onChange(selectedOption);
                                  }}
                                  isMulti={false}
                                  onBlur={onBlur}
                                  name={name}
                                  className="shadow border-0"
                                  value={selectedTeller}
                                  styles={customStyles}
                                  ref={ref}
                                />
                              )}
                            />
                            {errors?.teller?.type === "required" && (
                              <p className="text-small m-0 text-start text-danger help-block">
                                Please select teller
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-3 pt-3 mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary text-center rounded-0"
                              disabled={disabled}
                            >
                              {!disabled ? "Generate" : "Please Wait ..."}
                            </button>
                          </div>
                        </form>
                        {data && (
                          <div className="text-medium-small">
                            <p>
                              <b>Branch:</b> {data?.data.branch}
                            </p>
                            <p>
                              <b>Reporting Date:</b> {data?.data?.reportingDate}
                            </p>
                            <p>
                              <b>Teller:</b>{" "}
                              {data?.data?.transactions?.[0]?.tellerName}
                            </p>
                            <p>
                              <b>Cash from Reserve:</b> UGX{" "}
                              {data?.data?.cashFromReserve.toLocaleString()}
                            </p>
                            <p>
                              <b>Opening Balance:</b> UGX{" "}
                              {data?.data?.openingBalance.toLocaleString()}
                            </p>
                            <table className="table text-medium-small table-hover table-bordered">
                              <thead>
                                <tr className="bg-dark text-white">
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Client Name</th>
                                  <th>Account No</th>
                                  <th>Transaction No</th>
                                  <th>Description</th>
                                  <th>Debit (UGX)</th>
                                  <th>Credit (UGX)</th>
                                  <th>Recurring Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems.map(
                                  (transaction, index) => (
                                    <tr key={index}>
                                      <td>{transaction.date}</td>
                                      <td>{transaction.time}</td>
                                      <td>{transaction.clientName}</td>
                                      <td>{transaction.accountNumber}</td>
                                      <td>{transaction.transactionNumber}</td>
                                      <td>{transaction.description}</td>
                                      <td>
                                        {transaction.debit.toLocaleString()}
                                      </td>
                                      <td>
                                        {transaction.credit.toLocaleString()}
                                      </td>
                                      <td>
                                        {transaction.balance.toLocaleString()}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={onPageChange}
                            />
                            <div className="row">
                              <div className="col-md-6 mt-2">
                                <p>
                                  <b>Total transactions:</b>{" "}
                                  {data?.data.totalTransactions}
                                </p>
                                <p>
                                  <b>Total Cash In:</b> UGX{" "}
                                  {data?.data?.totalCashIn.toLocaleString()}
                                </p>
                                <p>
                                  <b>Cash from Reserve:</b> UGX{" "}
                                  {data?.data?.cashFromReserve.toLocaleString()}
                                </p>
                                <p>
                                  <b>Total:</b> UGX{" "}
                                  {(
                                    data?.data?.cashFromReserve +
                                    data?.data?.totalCashIn
                                  ).toLocaleString()}
                                </p>
                              </div>
                              <div className="col-md-6 mt-3">
                                <p></p>
                                <p>
                                  <b>Total Cash Out:</b> UGX{" "}
                                  {data?.data?.totalCashOut.toLocaleString()}
                                </p>
                                <p>
                                  <b>Cash into Reserve:</b> UGX{" "}
                                  {data?.data?.cashIntoReserve.toLocaleString()}
                                </p>
                                <p>
                                  <b>Total:</b> UGX{" "}
                                  {(
                                    data?.data?.cashIntoReserve +
                                    data?.data?.totalCashOut
                                  ).toLocaleString()}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center mt-0">
                              <div className="ml-auto mb-0 mt-0">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={downloadReportAsPDF}
                                >
                                  {isPDFDownloading
                                    ? "Downloading please wait..."
                                    : "Download PDF"}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TillSheet;
