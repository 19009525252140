import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";

const EditRoles = ({
  openEditModal,
  handleEditClose,
  permission_data,
  name,
}) => {
  // console.log(data?.map(perm => perm.code))
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar();
  const [disabled, setDisabled] = useState(false);
  const { handleSubmit, register, reset } = useForm();
  const [permissions, setPermissions] = useState([]);
  const controller = new AbortController();
  const get = async () => {
    try {
      const response = await axiosPrivate.get(`/settings/rights/permissions`, {
        signal: controller.signal,
      });
      setPermissions(response?.data?.data?.permissions);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (data) => {
    // console.log(data)
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.put(
        `/settings/rights/roles/${name}`,
        data,
        {
          signal: controller.signal,
        }
      );
      console.log(response?.data);
      toast.success(response?.data?.messages);
      progress.finish();
      handleEditClose();
      setDisabled(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
        reset();
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        reset();
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={openEditModal}
        backdrop="static"
        keyboard={false}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Edit Role{" "}
          </Modal.Title>
          <button onClick={handleEditClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 row px-3">
                {permissions?.map((data) => (
                  <React.Fragment key={data?.id}>
                    <div className="bg-dark col-12 p-2 m-2">
                      <h1 className="text-white">{data?.name}</h1>
                    </div>
                    {data?.permissions?.map((permission) => (
                      <div className="col-3" key={permission?.id}>
                        <input
                          type="checkbox"
                          value={permission?.id}
                          name="permissions"
                          {...register("permissions")}
                          defaultChecked={
                            permission_data
                              ?.map((perm) => perm.code)
                              .includes(permission?.code)
                              ? true
                              : false
                          }
                        />
                        <span className="px-1 text-dark text-sm">
                          {permission?.name}
                        </span>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleEditClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Update"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditRoles;
