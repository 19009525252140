import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const AccountsModal = ({ getAccounts }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [account_sub_groups, setAccount_sub_groups] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const controller = new AbortController();
  const getSubaccounts = async () => {
    try {
      const response = await axiosPrivate.get("/settings/accounts/sub/groups", {
        signal: controller.signal,
      });

      setAccount_sub_groups(response.data.data.account_sub_groups);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSubaccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = account_sub_groups?.map((account_sub_group) => ({
    value: account_sub_group.id,
    label: account_sub_group.title,
  }));

  // submit accounts data to API
  const addAccount = async (data) => {
    const newData = {
      title: data.title,
      code: data.code,
      minimal_balance: data.minimal_balance,
      opening_balance: data.opening_balance,
      subgroupid: data.subgroupid.value,
      type: data.type,
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "settings/accounts/account",
        newData,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getAccounts();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Toaster />
      <button onClick={handleShow} className="btn btn-outline-primary">
        Register new account
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Register new account</Modal.Title>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(addAccount)}
          className="needs-validation"
          noValidate
        >
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-6">
                <label
                  id="sub-account-name"
                  className="text-primary form-label"
                >
                  Enter Account Name
                </label>
                <input
                  {...register("title", { required: true })}
                  type="text"
                  placeholder="Enter Account Name"
                  className="form-control form-control-md input-text"
                  required
                />
                {errors?.title?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-primary form-label">
                  Enter Account Code
                </label>
                <input
                  {...register("code", { required: true })}
                  type="text"
                  placeholder="Enter Account Code"
                  className="form-control form-control-md input-text"
                />
                {errors?.code?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="" className="text-primary form-label">
                  Enter minumal balance
                </label>
                <input
                  {...register("minimal_balance", { required: true })}
                  type="number"
                  placeholder="Enter Account opening-balance"
                  className="form-control form-control-md input-text"
                />
                {errors?.minimal_balance?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="" className="text-primary form-label">
                  Enter Account opening Balance
                </label>
                <input
                  {...register("opening_balance", { required: true })}
                  type="number"
                  placeholder="Enter Account opening-balance"
                  className="form-control form-control-md input-text"
                />
                {errors?.opening_balance?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
              <div className="form-group col-md-6">
                <label
                  id="subaccount-group"
                  className="text-primary form-label"
                >
                  Select sub Account Group
                </label>
                <Controller
                  control={control}
                  name="subgroupid"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("subgroupid", { required: true })}
                      options={options}
                      onChange={onChange}
                      isMulti={false}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      placeholder="Select * subaccount group"
                      ref={ref}
                    />
                  )}
                />
                {errors?.groupid?.type === "required" && (
                  <span className="text-danger">
                    Please this field is mandatory
                  </span>
                )}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="type" className="text-primary form-label">
                  Account Category
                </label>
                <select
                  {...register("type", { required: true })}
                  defaultValue=""
                  className="form-control form-control-lg input-text"
                >
                  <option value="" disabled>
                    Account Type
                  </option>
                  <option value="fixed">Fixed</option>
                  <option value="incomes">Income And Expense</option>
                  <option value="savings">Savings</option>
                  <option value="others">Others</option>
                </select>
                {errors?.minimal_balance?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-warning" onClick={handleClose}>
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-outline-primary"
            >
              {!disabled && "Save"}
              {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AccountsModal;
