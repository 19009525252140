import { Modal } from "react-bootstrap";
import { ToWords } from "to-words";

const DepositReceipt = ({ showModal, handleClose, data }) => {
  const toWords = new ToWords({ localeCode: "en-GB" });

  const sacco_information = JSON.parse(localStorage?.user_data);
  console.log(data);

  const handlePrint = () => {
    // eslint-disable-next-line
    const printContents =
      document.getElementById("printable-content").innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Receipt</title>
          <style>
            @media print {
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                line-height: 1.0;
                font-size: 11px !important;
              
              }
              .address {
                font-size: 0.9em;
                margin-bottom: 10px;
              }
                          
              .hidden-print {
                display: none;
              }
        
              .centered {
                  text-align: center;
                  align-content: center;
              }

              .address {
                font-size: 11px;
                margin-bottom: 10px;
                  
              }

            }
          </style>
        </head>
        <body>
          <div id="printable-content">
            <p className="address" style = "text-align: center; align-content: center;">
              ${sacco_information.sacco} <br />
              ${sacco_information.branch} <br />
              ${sacco_information.sacco_address} <br />
              ${sacco_information.sacco_email}, <br /> 
              ${sacco_information.sacco_contact}
              <br />
              Deposit Receipt
            </p>
            <hr />
            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div >Account Name:</div>
              <div> ${data?.account_name}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Account Number:</div>
              <div className="text-bold">${data?.account_number}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Receipt Number:</div>
              <div>${data?.code}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Date & Time:</div>
              <div>${data?.timestamp}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Teller:</div>
              <div>${data?.user}</div>
            </div>
            <hr />
            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Amount:</div>
              <div>UGX ${data?.amount?.toLocaleString()}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Amount in words:</div>
              <div>${toWords.convert(
                data?.amount ?? 0
              )} Shillings Only</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Deposited By:</div>
              <div>${data?.notary}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0;">
              <div>Notes:</div>
              <div>${data?.notes ?? ""}</div>
            </div>

            <hr />

            <p style= "align-items: center"; text-align: center;">Powered by &copy; iRembo Finance</p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="mt-1">
            <div id="printable-content">
              <p className="text-center text-bold fw-500 p-0">
                {sacco_information.sacco} <br />
                {sacco_information.branch} <br />
                {sacco_information.sacco_address} <br />
                {sacco_information.sacco_email},
                {sacco_information.sacco_contact}
                <br />
                Deposit Receipt
              </p>
              <hr />
              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Account Name:</div>
                <div className="text-bold text-dark"> {data?.account_name}</div>
              </div>

              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Account Number:</div>
                <div className="text-bold">{data?.account_number}</div>
              </div>

              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Receipt Number:</div>
                <div>{data?.code}</div>
              </div>

              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Date & Time:</div>
                <div>{data?.timestamp}</div>
              </div>

              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Teller:</div>
                <div>{data?.user}</div>
              </div>
              <hr />
              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Amount:</div>
                <div>UGX {data?.amount?.toLocaleString()}</div>
              </div>

              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Amount in words:</div>
                <div>
                  {toWords.convert(data?.amount ?? 0)} Shillings Only
                </div>
              </div>

              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Deposited By:</div>
                <div>{data?.notary}</div>
              </div>

              <div className="p-0 row-container d-flex align-items-center justify-content-between">
                <div>Notes:</div>
                <div>{data?.notes}</div>
              </div>

              <hr />

              <p className="text-center p-0">
                Powered by &copy; iRembo Finance
              </p>
            </div>
          </div>

          <div className="row-container hidden-print d-flex align-items-center justify-content-between">
            <div className="col-4">
              <button className="btn btn-link" onClick={handlePrint}>
                <i className="fa fa-print text-dark"></i>
              </button>
            </div>
            <div className="col-4">
              <button className="btn btn-link">
                <i className="fa fa-download text-dark"></i>
              </button>
            </div>
            <div className="col-4">
              <button className="btn btn-link">
                <i className="fa fa-share text-dark"></i>
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="btn btn-block btn-primary">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DepositReceipt;
