import { Link, useNavigate } from "react-router-dom";
import axios from "../../config/axios";
import toast, { Toaster } from "react-hot-toast";
import { errorColor } from "services/colors/constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";

const VerifyEmail = () => {
const progress = new ProgressBar({ color: "#73B41A", size: 4 });
const navigate = useNavigate();
const [disabled, setDisabled] = useState(false);
 const {
   register,
   handleSubmit,
   formState: { errors },
 } = useForm();

 const onReset = async (data) => {
   try {
     progress.start();
     setDisabled(true);
     const response = await axios.put("/recovery/email", data); 
     toast.success(response?.data?.messages);
     progress.finish();
     navigate(`/recover/password/${response?.data?.data?.id}`);
   } catch (error) {
     if (!error?.response) {
       setDisabled(false);
       toast.error("No server response", {
         style: { background: errorColor, color: "#000" },
       });
       progress.finish();
     } else {
       setDisabled(false);
       progress.finish();
       toast.error(error?.response?.data?.messages, {
         style: { background: errorColor, color: "#000" },
       });
     }
   }
 };
  return (
    <>
      <Toaster />
      <div className="page-holder-2 d-flex align-items-center">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 px-4">
              <div className="card mt-4 mb-5">
                <div className="card-header">
                  <p className="text-muted text-center">
                    Enter OTP Sent To Your Email Address Or Contact For Recovery
                  </p>
                </div>
                <div className="card-body">
                  <form
                    className="form-horizontal"
                    autoComplete="off"
                    onSubmit={handleSubmit(onReset)}
                  >
                    <div className="form-group mb-4">
                      <input
                        type="number"
                        placeholder="Enter OTP code"
                        className="form-control border-2 form-control-lg"
                        autoComplete="off"
                        {...register("otp_code", { required: true })}
                      />
                      {errors?.otp_code?.type === "required" && (
                        <p className="text-small  m-0 text-danger help-block">
                          Enter your otp code.
                        </p>
                      )}
                    </div>

                    <button
                      className="btn btn-outline-warning btn-block px-5 rounded-0"
                      disabled={disabled}
                    >
                      {!disabled && "Verify"} {disabled && "Please Wait ....."}
                    </button>
                  </form>
                  {/* <p className="text-center mt-3 text-capitalize">
                    <Link to="/">Back To Login</Link>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="float-center">
            <p className="mt-5 mb-0 text-center">
              <span className="text-dark">Powered by</span>
              <Link to="/" className="external text-warning">
                {" "}
                Mob
                <i
                  className="fa fa-info-circle text-success"
                  aria-hidden="true"
                ></i>
                tungo
              </Link>
              <br /> <span className="text-dark">&copy; 2024 Ahu</span>riire (U)
              LTD
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
