import React, { useState, useEffect, useMemo, useCallback } from "react";
import UserRolesmodal from "pages/settings/modals/user-roles";
import SystemUsers from "pages/settings/modals/system_users";
import {MaterialReactTable} from "material-react-table";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import EditRoles from "pages/settings/modals/edit-role";
import EditSystemUsers from "pages/settings/modals/edit-system_users";

const UserManager = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [user_roles, setUser_roles] = useState([]);
  const [system_users, setSystem_users] = useState([]);
  const [data, setData] = useState([]);

  //fetch roles data
  const controller = new AbortController();
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get("settings/rights/roles", {
        signal: controller.signal,
      });
      setUser_roles(response.data.data.role);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getPermissions = async () => {
    try {
      const response = await axiosPrivate.get("settings/rights/permissions", {
        signal: controller.signal,
      });
      setData(response.data.data.permissions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getUsers();
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalData, setModalData] = useState([]);

  const [name, setName] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleEditClose = () => {
    setOpenEditModal(false);
  };
  const handleEditOpen = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(`/settings/rights/roles/${id}`, {
        signal: controller.signal,
      });

      setModalData(response?.data?.data?.permissions);
      setName(id);
      setOpenEditModal(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const roles_body = [
    { header: "Title", accessorKey: "name", size: 50 },
    {
      header: "Action",
      id: "id",
      size: 50,
      accessorFn: (row) => (
        <button
          className="btn btn-secondary btn-sm p-1 rounded-0"
          onClick={() => handleEditOpen(row.id)}
        >
          Update
        </button>
      ),
    },
  ];

  //fetch system users
  const getSystemUsers = async () => {
    try {
      const response = await axiosPrivate.get("sacco/users", {
        signal: controller.signal,
      });
      setSystem_users(response.data.data.users);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSystemUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const users = system_users?.map((system_user) => ({
    name: `${system_user.firstname} ${system_user.lastname}`,
    email: system_user.email,
    phone: system_user.contact,
    role: system_user.role,
    status: system_user.status,
    action: system_user.id,
  }));
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [modalUserData, setModalUserData] = useState([]);
  const handleEditUserOpen =  useCallback(async (id) => {
    try {
      const response = await axiosPrivate.get(`sacco/users/${id}`, {
        signal: controller.signal,
      });
      setModalUserData(response.data.data.user);
      setOpenEditUserModal(true);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEditUserClose = () => {
    setModalUserData([]);
    setOpenEditUserModal(false);
  };
const titles = useMemo(() => [
  { header: "Title (Sections)", accessorKey: "name", size: 50, },
],[]);
  const users_body = useMemo(
    () => [
      { header: "Name", accessorKey: "name", size: 50 },
      { header: "Email", accessorKey: "email", size: 50 },
      { header: "Phone", accessorKey: "phone", size: 50 },
      { header: "Role", accessorKey: "role", size: 50 },
      {
        header: "Status",
        id: "status",
        size: 50,
        accessorFn: (row) =>
          row.status === "active" ? (
            <div className="badge badge-success p-1">Active</div>
          ) : (
            <div className="badge badge-danger p-1">Inactive</div>
          ),
      },
      {
        header: "Action",
        id: "action",
        size: 50,
        accessorFn: (row) => (
          <>
            {/* <button className="btn btn-sm p-1 btn-link text-dark rounded-0">
              <i className="fa fa-refresh"></i>
            </button> */}
            <button
              onClick={() => handleEditUserOpen(row.action)}
              className="btn btn-sm p-1 btn-link text-dark rounded-0"
            >
              <i className="fa fa-edit"></i> Update
            </button>
          </>
        ),
      },
    ],
    [handleEditUserOpen]
  );


  return (
    <>
    
            <section className="py-1 mt-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        User Management{" "}
                      </h2>
                    </div>
                    <div className="card-body p-2 mt-0 mb-3">
                      <div className="row mt-5">
                        <div className="col-md-12">
                          <div
                            className="nav nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <a
                              className="border nav-link active mr-2 px-3 py-2 h6 text-center"
                              id="v-pills-home-tab"
                              data-toggle="pill"
                              href="#pill-group-title"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              User Roles
                            </a>
                            <a
                              className="border nav-link mr-2 px-3 py-2 h6 text-center"
                              id="pill-permissions-tab"
                              data-toggle="pill"
                              href="#pill-system-users"
                              role="tab"
                              aria-controls="pill-group-title"
                              aria-selected="false"
                            >
                              System Users
                            </a>
                            {/* .............................. */}
                          </div>
                        </div>
                      </div>
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pill-group-title"
                          role="tabpanel"
                          aria-labelledby="pill-group-title-tab"
                        >
                          <div className="row">
                            <div className="text-primary col mt-0">
                              {/* <p>Available User Roles</p> */}
                            </div>
                            <div className="col mt-0 text-right">
                              <UserRolesmodal getUsers={getUsers} />
                            </div>
                          </div>
                          <div className="row mt-0">
                            <div className="col-md-6">
                              <MaterialReactTable
                                columns={roles_body}
                                data={user_roles}
                                className="table align-items-center mb-0"
                                enableColumnFilterModes
                                enableColumnOrdering
                                enableRowSelection
                                enableGrouping
                                initialState={{ density: "compact" }}
                                enableStickyHeader
                                muiTableContainerProps={{
                                  sx: { maxHeight: "300px" },
                                }}
                                muiTableHeadCellProps={{
                                  //simple styling with the `sx` prop, works just like a style prop in this example
                                  sx: {
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                    fontFamily: "Nunito",
                                  },
                                }}
                                muiTableBodyCellProps={{
                                  sx: {
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                    fontFamily: "Nunito",
                                  },
                                }}
                                muiSearchTextFieldProps={{
                                  placeholder: "Search",
                                  sx: {
                                    fontFamily: "Nunito",
                                    fontSize: "12px",
                                  },
                                }}
                              />
                            </div>
                            <div className="col-6 mt-3">
                              <MaterialReactTable
                                columns={titles}
                                data={data}
                                className="table align-items-center mb-0"
                                enableColumnFilterModes
                                enableColumnOrdering
                                enableRowSelection
                                enableGrouping
                                initialState={{ density: "compact" }}
                                enableStickyHeader
                                muiTableContainerProps={{
                                  sx: { maxHeight: "200px" },
                                }}
                                muiTableHeadCellProps={{
                                  //simple styling with the `sx` prop, works just like a style prop in this example
                                  sx: {
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                    fontFamily: "Nunito",
                                  },
                                }}
                                muiTableBodyCellProps={{
                                  sx: {
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                    fontFamily: "Nunito",
                                  },
                                }}
                                muiSearchTextFieldProps={{
                                  placeholder: "Search",
                                  sx: {
                                    fontFamily: "Nunito",
                                    fontSize: "12px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade show"
                          id="pill-system-users"
                          role="tabpanel"
                          aria-labelledby="pill-group-title-tab"
                        >
                          <div className="row  mt-0">
                            <div className="col">
                              <div className="mb-3 d-flex justify-content-between">
                                <h6 className="text-primary">System Users</h6>
                                <SystemUsers
                                  getSystemUsers={getSystemUsers}
                                  users={system_users}
                                />
                                <EditSystemUsers
                                  getSystemUsers={getSystemUsers}
                                  users={system_users}
                                  show={openEditUserModal}
                                  handleClose={handleEditUserClose}
                                  data={modalUserData}
                                />
                              </div>
                              <MaterialReactTable
                                columns={users_body}
                                data={users}
                                className="table align-items-center mb-0"
                                enableColumnFilterModes
                                enableColumnOrdering
                                enableRowSelection
                                enableGrouping
                                enableStickyHeader
                                initialState={{ density: "compact" }}
                                muiTableContainerProps={{
                                  sx: { maxHeight: "300px" },
                                }}
                                muiTableHeadCellProps={{
                                  //simple styling with the `sx` prop, works just like a style prop in this example
                                  sx: {
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                    fontFamily: "Nunito",
                                  },
                                }}
                                muiTableBodyCellProps={{
                                  sx: {
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                    fontFamily: "Nunito",
                                  },
                                }}
                                muiSearchTextFieldProps={{
                                  placeholder: "Search",
                                  sx: {
                                    fontFamily: "Nunito",
                                    fontSize: "12px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
      {modalData && (
        <EditRoles
          handleEditClose={handleEditClose}
          openEditModal={openEditModal}
          permission_data={modalData}
          name={name}
        />
      )}
    </>
  );
};
export default UserManager;
