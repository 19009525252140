// import { MaterialReactTable } from "material-react-table";
import { useMemo, useState, useEffect } from "react";
import SmsModal from "pages/settings/modals/sms-modal";
import EmailModal from "pages/settings/modals/email-modal";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "pages/components/common_table";
import SmsSelectedModal from "pages/settings/modals/sms-selected-modal";

const CommunicationGeneral = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [sms, setSms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

  const controller = new AbortController();
  const getSentSms = async () => {
    try {
      const response = await axiosPrivate.get("communication/general/sms", {
        signal: controller.signal,
      });

      setSms(response.data.data.sms);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSentSms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = () => { 
    setIsLoading(true);
    getSentSms();
  }
  const [email, setEmail] = useState([]);
  const getSentemail = async () => {
    try {
      const response = await axiosPrivate.get("communication/general/emails", {
        signal: controller.signal,
      });

      setEmail(response.data.data.emails);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSentemail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const smsColumns = useMemo(
    () => [
      { header: "Message", accessorKey: "message" },
      { header: "Message Count", accessorKey: "length" },
      { header: "Sent by", accessorKey: "user" },
    ],
    []
  );
  const columns = useMemo(
    () => [
      { header: "Subject", accessorKey: "subject" },
      { header: "Message", accessorKey: "message" },
      { header: "Sent by", accessorKey: "user" },
    ],
    []
  );
  return (
    <>
      <section className="mt-1">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h2 className="h6 mb-0 text-uppercase text-center">
                Client Communications{" "}
              </h2>
            </div>
            <div className="card-body pt-2">
              <ul
                className="nav nav-tabs mb-3 row"
                id="tabs-tab"
                role="tablist"
              >
                <li className="nav-item col-4" role="presentation">
                  <a
                    className="nav-link active text-dark"
                    id="tabs-home-tab"
                    data-toggle="pill"
                    href="#tabs-home"
                    role="tab"
                    aria-controls="tabs-home"
                    aria-selected="true"
                  >
                    Sms
                  </a>
                </li>
                <li className="nav-item col-4" role="presentation">
                  <a
                    className="nav-link text-dark"
                    id="tabs-profile-tab"
                    data-toggle="pill"
                    href="#tabs-profile"
                    role="tab"
                    aria-controls="tabs-profile"
                    aria-selected="false"
                  >
                    Emails
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="tabs-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="tabs-home"
                  role="tabpanel"
                  aria-labelledby="tabs-home-tab"
                >
                  <div className="mb-4 d-flex justify-content-center">
                    <SmsModal getSentSms={getSentSms} />
                    <SmsSelectedModal getSentSms={getSentSms}  />
                  </div>
                  <Table columns={smsColumns} data={sms} refetch={refetch} isLoading={isLoading} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tabs-profile"
                  role="tabpanel"
                  aria-labelledby="tabs-profile-tab"
                >
                  <div className="mb-4 d-flex justify-content-center">
                    <EmailModal getSentemail={getSentemail} />
                  </div>
                  <Table columns={columns} data={email} refetch={refetch} isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommunicationGeneral;
