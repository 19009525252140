import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";
import Pagination from "pages/components/pagination";
const IncomeGeneralReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const controller = new AbortController();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post(`/reports/new/income`, data, {
        signal: controller.signal,
      });
      setDates(response.data.data.dates);
      setData(response.data.data.data);
      console.log(response.data.data.data.incomes)

      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const sumIncome = data
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);

  // const sortedData = incomes.sort(
  //   (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  // );
  //   console.log(data)

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // function to export data as pdf
  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.post(
        `/export/reports/incomes_general/pdf`,
        {
          data: {
            dates,
            data,
          }
        },
      
      { responseType: "blob" }
      );
      const filename = "Income_report.pdf";
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading incomes report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.post(
        `/export/reports/incomes_general/csv`,
         {
          data: {
            dates,
            data,
          }
        },

        { responseType: "blob" }
      );

      const filename = `Incomes_report.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading share balances report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };
  return (
    <>
      <Toaster />

      <div className="card mt-2">
        <div className="card-header bg-dark">
          <h2 className="h5 text-bold text-uppercase text-center text-white">
            General Income Report{" "}
          </h2>
        </div>
        <div className="card-body mt-3 mb-3 text-medium-small">
          <div className="row">
            <div className="col-md-12 mb-3">
              <form
                className="row needs-validation"
                id="generalLegerForm"
                onSubmit={handleSubmit(onQuery)}
              >
                <div className="form-group col-md-3">
                  <label className="form-label">Select Start Date</label>
                  <input
                    type="date"
                    placeholder="Select Start Date"
                    className="form-control shadow border-1"
                    {...register("start_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.start_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label">Select End Date</label>
                  <input
                    type="date"
                    placeholder="Select End Date"
                    className="form-control shadow border-1"
                    {...register("end_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.end_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3 pt-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-center rounded-0"
                    disabled={disabled}
                  >
                    {!disabled && "Generate"} {disabled && "Please Wait ....."}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-3 text-medium-small">
            <table className="table table-hover table-bordered text-medium-small">
              <thead>
                <tr>
                  <th className="text-left" colSpan="8">
                    <div className="row">
                      <p className="text-left col-4">
                        Start Date : {dates?.start_date}
                      </p>
                      <p className="text-left col-4">
                        End Date: {dates?.end_date}
                      </p>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Transaction Code</th>
                  <th>Vote / Account</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                {currentItems?.map((row) => (
                  <tr key={row.id}>
                    <td>
                      {new Date(row?.timestamp).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    <td>{row?.notes}</td>
                    <td>{row?.code}</td>
                    <td>{row?.account}</td>
                    <td style={{ textAlign: "right" }}>{row?.amount?.toLocaleString()}</td>
                  </tr>
                ))}

                <tr className="bg-dark text-white">
                  <th>Total</th>
                  <th colSpan={3}></th>
                  <th style={{ textAlign: "right" }}>{sumIncome.toLocaleString()}</th>
                </tr>
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
            <div className="d-flex align-items-center mt-0">
              <div className="ml-auto mb-0 mt-0">
                <button
                  className="btn btn-primary text-right"
                  onClick={downloadUssdReportAsPDF}

                  // disabled={isGenerating || isPDFDownloading}
                >
                  {isPDFDownloading
                    ? "Downloading please wait..."
                    : "Download PDF"}
                </button>
                <button
                  className="btn btn-secondary text-right"
                  onClick={downloadUssdReportAsCSV}
                  // disabled={isGenerating || isCSVDownloading}
                >
                  {isCSVDownloading
                    ? "Downloading please wait..."
                    : "Download CSV"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeGeneralReport;
