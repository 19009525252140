import React, { useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import Loader from "services/loaders/loader";
const TellerDashBoard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const controller = new AbortController();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const getTimeComponents = (timestamp) => {
    const timeString = new Date(timestamp).toLocaleTimeString("en-US", {
      hour12: false,
    });
    return timeString;
  };
  const getDashboardData = async () => {
    try {
      const response = await axiosPrivate.get("/dashboards/teller", {
        signal: controller.signal,
      });
      // console.log(response.data.data);
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({ datasets: [] });
  useEffect(() => {
    const depositData = data?.dailyDeposits ?? [];
    const depositTimestamps = depositData.map((deposit) =>
      getTimeComponents(deposit.timestamp)
    );
    const depositAmounts = depositData.map((deposit) => deposit.amount);

    // Extract timestamps and amounts for withdraws
    const withdrawData = data?.dailyWithdraws ?? [];
    const withdrawTimestamps = withdrawData.map((withdraw) =>
      getTimeComponents(withdraw.timestamp)
    );
    const withdrawAmounts = withdrawData.map((withdraw) => withdraw.amount);

    // Find the union of timestamps
    const commonTimestampsSet = new Set([
      ...depositTimestamps,
      ...withdrawTimestamps,
    ]);
    const commonTimestamps = Array.from(commonTimestampsSet).sort();

    // Create datasets for deposits and withdraws with common timestamps
    const depositDataset = {
      label: "Deposits",
      data: commonTimestamps.map((timestamp) => ({
        x: timestamp,
        y: depositAmounts[depositTimestamps.indexOf(timestamp)] || 0,
      })),
      borderColor: "rgba(24,97,40,1)",
      backgroundColor: "rgba(24,97,40,0.4)",
      borderWidth: 1,
      pointRadius: 5,
    };

    const withdrawDataset = {
      label: "Withdraws",
      data: commonTimestamps.map((timestamp) => ({
        x: timestamp,
        y: withdrawAmounts[withdrawTimestamps.indexOf(timestamp)] || 0,
      })),
      borderColor: "rgba(152, 27, 40, 1)",
      backgroundColor: "rgba(152, 27, 40, 0.4)",
      borderWidth: 1,
      pointRadius: 5,
    };

    const datas = {
      datasets: [depositDataset, withdrawDataset],
    };

    setChartData(datas);
    const options = {
      scales: {
        x: {
          // type: "time",
          time: {
            unit: "hour",
            displayFormats: {
              hour: "HH:mm",
            },
          },
          title: {
            display: true,
            text: "Time",
            font: {
              size: 12,
              weight: "bold",
            },
          },
        },
        y: {
          beginAtZero: true, // Start the y-axis from zero
          stacked: true, // Make the datasets overlap
          title: {
            display: false,
            text: "Amount",
            font: {
              size: 12,
              weight: "bold",
            },
          },
          ticks: {
            font: {
              size: 12,
            },
          },
          grid: {
            display: true, // Show y-axis grid lines
          },
        },
      },
      plugins: {
        legend: {
          display: true, // Show legend
        },
        tooltip: {
          enabled: true,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
        },
      },
    };
    setChartOptions(options);
  }, [data]);

  const data_gender = {
    labels: ["Male", "Female", "Groups"],
    datasets: [
      {
        data: [data?.gender?.male, data?.gender?.female, data?.gender?.group],
        backgroundColor: [
          "rgba(152, 27, 40, 0.9)",
          "rgba(225, 184, 34, 1)",
          "rgba(4, 84, 34, 1)",
        ],
        borderColor: [
          "rgba(152, 27, 40, 0.9)",
          "rgba(225, 184, 34, 1)",
          "rgba(22, 70, 34, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <div className="row">
        
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new shadow h-100 py-2 border-left-success border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs text-small font-weight-bold text-white text-uppercase mb-1">
                    Today's Savings
                  </div>
                  <div className="h5 mb-0 text-small font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX. {data?.totalDailySavings?.toLocaleString() ?? 0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new shadow h-100 py-2 border-left-success border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs text-small font-weight-bold text-white text-uppercase mb-1">
                    Today's Withdraws
                  </div>
                  <div className="h5 mb-0 text-small font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX. {data?.totalDailyWithdraws?.toLocaleString() ?? 0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-info  bg-hover-info bg-gradient-info card-new border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-small text-uppercase mb-1">
                    Today's Withdraw Charges
                  </div>
                  <div className="h5 mb-0 text-small font-weight-bold text-white">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {data?.totalDailyWithdrawCharges?.toLocaleString() ?? 0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-small text-uppercase mb-1">
                    Today's USSD Savings
                  </div>
                  <div className="h5 mb-0 text-small font-weight-bold text-white">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {data?.totalDailyUSSDSavings?.toLocaleString() ?? 0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-0 mb-lg-0">
          <div className="card">
            <div className="card-header">
              <h2 className="h6 text-uppercase mb-0 text-small">
                Daily Savings vs Daily withdraws
              </h2>
            </div>
            <div className="card-body">
              {/* <p className="text-gray">
                Daily trend between Savings and Withdraws
              </p> */}
              <div className="chart-holder">
                {isLoading ? <Loader /> : ""}
                <Line options={chartOptions} data={chartData} className="p-0" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 mb-lg-0 pl-lg-0">
          <div className="card mb-3">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-0 small">
                    Daily Reversed Savings
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span className="text-small">
                        UGX.{" "}
                        {data?.totalDailyReversedSavings?.toLocaleString() ?? 0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-danger text-uppercase mb-0 small">
                    Daily Reversed Withdraws
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span className="text-small">
                        {" "}
                        UGX.{" "}
                        {data?.totalDailyReversedWithdraws?.toLocaleString() ??
                          0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-3 bg-hover-gradient-info  bg-hover-info bg-gradient-info card-new border-left-info">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-uppercase mb-0 small">
                    Daily SMS Usage
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span className="text-small text-white">
                        {" "}
                        {data?.dailySMS?.toLocaleString() ?? 0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 mb-lg-0 pl-lg-0">
          <div className="card mb-1">
            <div className="card-header">
              <h2 className="h6 text-uppercase mb-0">Membership</h2>
            </div>
            <div className="card-body">
              {isLoading ? <Loader /> : ""}
              <Doughnut data={data_gender} className="px-0" />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 mb-5 pb-2">
        <div className="col-lg-6">
          <div className="card mb-5 mb-lg-0">
            <div className="card-header">
              <h2 className="h6 mb-0 text-uppercase">Recent Savings</h2>
            </div>
            <div className="card-body p-0">
                {isLoading ? (
                  <Loader />
                ) : (
              <table className="table table-striped table-bordered text-small">
                <thead className="text-bold bg-dark text-white">
                  <tr>
                    <th>Account</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>TXN ID</th>
                    <th>Method</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                  <tbody>
                    {data?.deposits?.map((deposit) => (
                      <tr key={deposit?.id}>
                        <td>{deposit?.account}</td>
                        <td>{deposit?.name}</td>
                        <td>{deposit?.amount?.toLocaleString()}</td>
                        <td>{deposit?.code}</td>
                        <td>{deposit?.method}</td>
                        <td>{deposit?.timestamp}</td>
                      </tr>
                    ))}
                  </tbody>
              </table>
                )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card mb-5 mb-lg-0">
            <div className="card-header">
              <h2 className="h6 mb-0 text-uppercase">Recent Withdraws</h2>
            </div>
            <div className="card-body p-0">
                {isLoading ? (
                  <Loader />
                ) : (
              <table className="table table-striped table-bordered text-small">
                <thead className="text-bold bg-dark text-white">
                  <tr>
                    <th>Account</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>TXN ID</th>
                    <th>Method</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                  <tbody>
                    {data?.withdraws?.map((withdraw) => (
                      <tr key={withdraw?.id}>
                        <td>{withdraw?.account}</td>
                        <td>{withdraw?.name}</td>
                        <td>{withdraw?.amount?.toLocaleString()}</td>
                        <td>{withdraw?.code}</td>
                        <td>{withdraw?.method}</td>
                        <td>{withdraw?.timestamp}</td>
                      </tr>
                    ))}
                  </tbody>
              </table>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TellerDashBoard;
