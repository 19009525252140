import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import NewClient from "./components/add-client";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import toast, { Toaster } from "react-hot-toast";
// import Table from "pages/components/common_table";
import ConfirmModal from "pages/components/confirmModal";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
const IndividualClient = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  // const [clients, setClients] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState([]);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  const controller = new AbortController();
  const handleDetele = async () => {
    try {
      const response = await axiosPrivate.delete("/clients/individual/" + id, {
        signal: controller.signal,
      });
      refetch();
      toast.success(response?.data?.messages);
    } catch (error) {
      if (!error?.response) {
        toast("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  const handleCancel = async () => {
    setId([]);
  };
  const columns = useMemo(
    () => [
      {
        id: "client_account_number", //access nested data with dot notation
        enableClickToCopy: true,
        header: "A/C No.",
        accessorFn: (row) => row.client_account_number,
        size: 50,
      },
      {
        id: "client_firstname", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Firstname",
        accessorFn: (row) => row.client_firstname,
        size: 50,
      },
      {
        id: "client_lastname", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Lastname",
        accessorFn: (row) => row.client_lastname,
        size: 50,
      },
      {
        id: "client_gender", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Gender",
        accessorFn: (row) =>
          row.client_gender === "male"
            ? "Male"
            : row.client_gender === "female"
            ? "Female"
            : "",
        size: 50,
      },

      {
        id: "client_contact", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Contact",
        accessorFn: (row) => row.client_contact,
        size: 50,
      },

      {
        id: "client_id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="text-small">
            <Link
              to={`/clients/individual/${row.client_id}`}
              className="text-dark  mr-3"
            >
              view
            </Link>
            {roles?.includes(1000003) && (
              <button
                className="btn btn-link text-white rounded-0 btn-sm p-0"
                onClick={() => {
                  setId(row.client_id);
                  setShow(true);
                }}
                aria-expanded="false"
              >
                <i className="text-danger fa fa fa-trash"></i>
              </button>
            )}
          </div>
        ),
      },
    ],
    [roles]
  );
  // eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState([]);
  // eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line
  const [sorting, setSorting] = useState([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const {
    data: { data = [], meta } = {}, // your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      pagination.pageIndex, // refetch when pagination.pageIndex changes
      pagination.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `serverside/clients/individual`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? "",
            sorting: JSON.stringify(sorting ?? []),
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
 

  return (
    <>
      <Toaster />
      <section className="mt-3 mb-5">
        <div className="card ">
          <NewClient
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            getClients={refetch}
          />
          <div className="card-body p-0">
            {/* <Table
              columns={columns}
              data={clients}
              isLoading={isLoading}
              refetch={refetch}
            /> */}
            <MaterialReactTable
              data={data}
              columns={columns}
              enableColumnFilterModes
              enableColumnOrdering
              enableRowSelection
              manualFiltering={true}
              manualPagination={true}
              manualSorting={true}
              enableGrouping
              enableStickyHeader
              className="table align-items-justify table-flush p-0 m-0"
              initialState={{
                density: "compact",
                showColumnFilters: false,
              }}
              paginationDisplayMode={"pages"}
              muiPaginationProps={{
                shape: "rounded",
                sx: { fontFamily: "Nunito", fontSize: "10px" },
              }}
              // state={{ isLoading: isLoading }}
              muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
              muiTableHeadCellProps={{
                sx: {
                  fontWeight: "normal",
                  fontSize: "12px",
                  fontFamily: "Nunito",
                },
              }}
              muiCircularProgressProps={{
                color: "secondary",
                thickness: 5,
                size: 25,
              }}
              muiSkeletonProps={{
                animation: "pulse",
                height: 28,
              }}
              muiTableBodyCellProps={{
                sx: {
                  fontWeight: "normal",
                  fontSize: "12px",
                  fontFamily: "Nunito",
                },
              }}
              muiSearchTextFieldProps={{
                placeholder: "Search",
                sx: { fontFamily: "Nunito", fontSize: "12px" },
              }}
              renderTopToolbarCustomActions={({ table }) => (
                <>
                  <div className="btn-group">
                    {/* <button className="btn btn-sm btn-danger rounded-0">
                      <i className="fa fa-file-pdf"></i>
                    </button>

                    <button
                      className="btn btn-sm btn-success rounded-0"
                      disabled={table.getRowModel().rows.length === 0}
                      //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                      onClick={() => handleExportRows(table.getRowModel().rows)}
                    >
                      <i className="fa fa-file-excel"></i>
                    </button>
                    <button className="btn btn-sm btn-info rounded-0">
                      <i className="fa fa-print"></i>
                    </button> */}
                    <button
                      onClick={refetch}
                      className="btn btn-sm btn-dark rounded-0"
                    >
                      <i className="fa fa-rotate-right"></i>
                    </button>
                  </div>
                  <div className="row">
                    {roles?.includes(1000002) && (
                      <div className="col-md-12">
                        <button
                          className="btn btn-success rounded-0 float-end"
                          onClick={handleShowModal}
                        >
                          + Add New Member
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
              rowCount={meta?.totalRowCount ?? 0}
              state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
                sorting,
              }}
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
            />
          </div>
        </div>
      </section>
      <ConfirmModal
        message="Are you sure you want to delete this client?"
        onConfirm={handleDetele}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default IndividualClient;
