import { Modal } from "react-bootstrap";

const ProductView = ({ show1, handleClose1, data }) => {
  return (
    <>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Loan product info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Name of Loan Product
            </div>
            <div className="mb-2">{data.title}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Type of Loan
            </div>
            <div className="mb-2">{data.type}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Processing fees
            </div>
            <div className="mb-2">{data.processing_fee}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Interest Rate(p.a)
            </div>
            <div className="mb-2">{data.interest_rate}%</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Penalty Interval
            </div>
            <div className="mb-2">{data.penalty_interval}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Product Interval
            </div>
            <div className="mb-2">{data.product_interval}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Minimum Amount that can be applied for
            </div>
            <div className="mb-2">{data.min_value}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Maximum ammount that can be applied for &nbsp;
            </div>
            <div className="mb-2">{data.max_value}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              No. of guarantors allowed
            </div>
            <div className="mb-2">{data.guarantors}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Penalty Amount
            </div>
            <div className="mb-2">{data.penalty_value}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Penalty mode
            </div>
            <div className="mb-2">{data.penalty_mode}</div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Rollover fees
            </div>
            {/* <div className="mb-2">{data.roll_over_settings.fees}</div> */}
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Rollover mode
            </div>
            {/* <div className="mb-2">{data.roll_over_settings.mode}</div> */}
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Rollover principal
            </div>
            <div className="mb-2">
              {/* {data.roll_over_settings.principal_attached} */}
            </div>
          </div>
          <div className=" d-flex align-items-center justify-content-between ">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Rollover interest
            </div>
            <div className="mb-2">
              {/* {data.roll_over_settings.interest_attached} */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-block btn-primary" onClick={handleClose1}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductView;
