import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportToJsonExcel = (jsonData, fields) => {
  const dataToExport = jsonData.map((item) => {
    let row = {};
    fields.forEach((field) => {
      row[field] = item[field];
    });
    return row;
  });

  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "selected_data.xlsx");
};


export const exportToJsonPdf = (jsonData, fields) => {
  const doc = new jsPDF();
  const dataToExport = jsonData.map((item) =>
    fields.map((field) => item[field])
  );

  doc.autoTable({
    head: [fields],
    body: dataToExport,
  });

  doc.save("selected_data.pdf");
};

