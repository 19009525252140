import  {MaterialReactTable}  from "material-react-table";
import { useState, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
const LoanDocuments = ({ showModal, handleClose, data, getloan }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [documentFile, setDocumentFile] = useState([]);
  const handleOpenFileModal = (data) => {
    setOpenFileModal(true);
    setDocumentFile(data);
  };
  const handleCloseFileModal = (data) => {
    setOpenFileModal(false);
    setDocumentFile([]);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "title", //access nested data with dot notation
        header: "Description",
        size: 50,
      },
      {
        id: "url",
        header: "Document",
        size: 50,
        accessorFn: (row) => (
          <div className="align-middle text-start text-sm">
            <button
              className="btn btn-link text-info"
              target="_blank"
              rel="noreferrer"
              onClick={() => handleOpenFileModal([{ uri: row.url }])}
            >
              <i className="fa fa-file"></i>
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append(
      "attributes",
      JSON.stringify({ title: data.title, loanid: params.id })
    );
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/loans/documents",
        formData,
        {
          headers: {
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        },
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getloan();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <>
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Add Documents
            </Modal.Title>
            <button onClick={handleClose} className="btn-close btn-danger">
              <i className="material-icons opacity-10">close</i>
            </button>
          </Modal.Header>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <Modal.Body>
              <div className="container row">
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter Description *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("title", { required: true })}
                  />
                  {errors?.title?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter title
                    </p>
                  )}
                </div>
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="file" className="form-label">
                    Document
                  </label>
                  <input
                    type="file"
                    name="file"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("file", { required: true })}
                  />
                  {errors?.file?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please select file
                    </p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={disabled}
                type="submit"
                className="btn btn-primary"
              >
                {!disabled && "Save"} {disabled && "Please Wait"}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
      <MaterialReactTable
        columns={columns}
        data={data}
        className="table align-items-center p-0"
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        enableGrouping
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
        initialState={{ density: "compact" }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
      />
      <Modal
        show={openFileModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseFileModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            View File
          </Modal.Title>
          <button
            onClick={handleCloseFileModal}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={documentFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark"
            onClick={handleCloseFileModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
 
export default LoanDocuments;