import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const FixedModal = ({ getFixedSettings }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
    const controller = new AbortController();
    const getAccounts = async () => {
      try {
        const response = await axiosPrivate.get("settings/accounts/account", {
          signal: controller.signal,
        });
        setAccounts(response.data.data.accounts);
      } catch (error) {
        // console.log(error)
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    };
  //fetch accounts data
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = accounts?.map((account) => ({
    value: account.id,
    label: account.title,
  }));
  const onSave = async (data) => {
    const newData = {
      title: data.title,
      interval: data.interval,
      interest: data.interest,
      calculation_mode: data.calculation_mode,
      interest_expense_account: data.interest_expense_account.value,
      account_payable: data.account_payable.value,
      fixed_holding_account: data.fixed_holding_account.value,
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "settings/fixed/settings",
        newData,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getFixedSettings();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Toaster />
      <button onClick={handleShow} className="btn btn-outline-primary">
        {" "}
        Add settings
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Fixed deposit settings</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSave)} noValidate className="p-1">
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Settings title</label>

                <input
                  {...register("title", { required: true })}
                  type="text"
                  placeholder="Title  etc..."
                  className="form-control form-control-md shadow border-0 input-text"
                />
                {errors?.title?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
              <div className="form-group col-6">
                <label className="form-label">
                  Select a fixed deposit account
                </label>
                <Controller
                  control={control}
                  name="fixed_holding_account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("fixed_holding_account", { required: true })}
                      options={options}
                      onChange={onChange}
                      isMulti={false}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      className="shadow border-0"
                      styles={customStyles}
                      placeholder="Select * Fixed Holding Account"
                      ref={ref}
                    />
                  )}
                />
                {errors?.fixed_holding_account?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="form-group col-6">
                <label className="form-label">Interest Rate Per Anum</label>
                <input
                  {...register("interest", { required: true })}
                  type="number"
                  placeholder="Enter rate."
                  className="form-control form-control-md shadow border-0 input-text"
                />
                {errors?.fixed_holding_account?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="form-group col-6">
                <label className="form-label">Interest Calculation Mode</label>
                <select
                  {...register("calculation_mode", { required: true })}
                  className="form-control-md shadow border-0"
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Interest Type
                  </option>
                  <option value="simple">Simple</option>
                  <option value="compound">Compound</option>
                </select>
                {errors?.calculation_mode?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="form-group col-6">
                <label className="form-label">Interest Expense Account</label>
                <Controller
                  control={control}
                  name="interest_expense_account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("interest_expense_account", {
                        required: true,
                      })}
                      options={options}
                      onChange={onChange}
                      isMulti={false}
                      onBlur={onBlur}
                      value={value}
                      styles={customStyles}
                      className="shadow border-0"
                      name={name}
                      placeholder="Select * interest expense account"
                      ref={ref}
                    />
                  )}
                />
                {errors?.interest_expense_account?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="form-group col-6">
                <label className="form-label">Interest Payable Account</label>
                <Controller
                  control={control}
                  name="account_payable"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("account_payable", { required: true })}
                      options={options}
                      onChange={onChange}
                      isMulti={false}
                      onBlur={onBlur}
                      styles={customStyles}
                      className="shadow border-0"
                      value={value}
                      name={name}
                      placeholder="Select * Account Payabale"
                      ref={ref}
                    />
                  )}
                />
                {errors?.account_payable?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="form-group col-6">
                <label className="form-label">How Interest is Earned</label>
                <select
                  {...register("interval", { required: true })}
                  className="form-control-md shadow border-0"
                >
                  <option value="">Specify</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="daily">Daily</option>
                </select>
                {errors?.account_payable?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-outline-primary"
            >
              {!disabled && "Save"}
              {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default FixedModal;
