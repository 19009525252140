import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const EditClient = ({ showModal, handleCloseModal, clientData, getClient }) => {
  const [step, setStep] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({ defaultValues: clientData });

  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/clients/individual/${params.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getClient();
      handleCloseModal();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            {step === 1 && (
              <>
                <Modal.Header>
                  <Modal.Title className="text-center">
                    {" "}
                    Edit Member{" "}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="row col-md-12 mx-auto">
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="first name" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Enter Firstname *"
                      defaultValue={clientData?.firstname}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("firstname", { required: true })}
                    />
                    {errors?.firstname?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter firstname
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="middlename" className="form-label">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      name="midlename"
                      defaultValue={clientData?.middlename}
                      placeholder="Enter Middlename"
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("middlename", { required: false })}
                    />
                  </div>
                  {/* Last Name */}
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="lastname" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Enter Lastname *"
                      defaultValue={clientData?.lastname}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("lastname", { required: true })}
                    />
                    {errors?.lastname?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter lastname
                      </p>
                    )}
                  </div>
                  {/* Date of birth */}
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="dateOfBirth" className="form-label">
                      Select Date of Birth
                    </label>
                    <input
                      type="date"
                      name="dateOfBirth"
                      placeholder="Date of birth *"
                      defaultValue={clientData?.date_of_birth}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("dateOfBirth", { required: true })}
                    />
                    {errors?.dateOfBirth?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter Date Of Birth
                      </p>
                    )}
                  </div>

                  {/* Phone Number */}
                  <div className="form-group col-md-6  input-container">
                    <label htmlFor="phonenumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="contact"
                      placeholder="Contact phone *"
                      maxLength="10"
                      defaultValue={clientData?.contact}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("contact", {
                        required: true,
                        maxLength: 10,
                        minLength: 10,
                      })}
                    />
                    {errors?.contact?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter your phone number
                      </p>
                    )}
                    {(errors?.contact?.type === "maxLength" ||
                      errors?.contact?.type === "minLength") && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Invalid phone number, please cross check
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <select
                      name="gender"
                      className="form-control border-0 shadow form-control-lg input-text"
                      defaultValue={clientData?.gender}
                      {...register("gender", { required: true })}
                    >
                      <option value="" disabled={true}>
                        Select your Gender
                      </option>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </select>
                    {errors?.gender?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please select gender
                      </p>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="Button"
                    className="btn btn-secondary"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Modal.Footer>
              </>
            )}

            {/* Second Form for group */}
            {step === 2 && (
              <>
                <Modal.Header>
                  <Modal.Title className="text-center">
                    {" "}
                    Edit Member
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="row mx-auto">
                  {/* Relationship */}
                  <div className="form-group col-md-4 input-container">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter Email Address "
                      defaultValue={clientData?.email}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("email", {
                        pattern: { value: /^\S+@\S+$/i },
                      })}
                    />
                    {errors?.email?.type === "pattern" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter a valid email address
                      </p>
                    )}
                  </div>

                  {/* <!-- Address --> */}
                  <div className="form-group col-md-4 input-container">
                    <label htmlFor="dateOfJoin" className="form-label">
                      Date Of Registration
                    </label>
                    <input
                      type="date"
                      name="dateOfJoin"
                      defaultValue={clientData?.date_of_joining}
                      placeholder="Enter Date Of Registration *"
                      className="form-control border-1 form-control-md input-text"
                      {...register("dateOfJoin", {
                        required: true,
                      })}
                    />
                    {errors?.dateOfJoin?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter a date of registration
                      </p>
                    )}
                  </div>
                  {/* <!-- Date Of Registration --> */}
                  <div className="form-group col-md-4 input-container">
                    <label htmlFor="employmentStatus" className="form-label">
                      Employment Status
                    </label>
                    <select
                      name="employmentStatus"
                      placeholder="Date of Registration *"
                      className="form-control border-0 shadow form-control-lg input-text"
                      defaultValue={clientData?.employment_status}
                      {...register("employmentStatus", {
                        required: true,
                      })}
                    >
                      <option value="" disabled={true}>
                        Select Client Employment Status
                      </option>
                      <option value="employed">Employed</option>
                      <option value="unemployed">Un Employed</option>
                      <option value="selfemployed">Self Employed</option>
                    </select>
                    {errors?.employmentStatus?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please select client employment status
                      </p>
                    )}
                  </div>
                  {/* <!-- Email Address --> */}
                  <div className="form-group col-md-4 input-container">
                    <label htmlFor="gross" className="form-label">
                      Gross Income
                    </label>
                    <input
                      type="number"
                      name="gross"
                      placeholder="Gross Income *"
                      defaultValue={clientData?.gross_income}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("gross", {
                        required: true,
                      })}
                    />
                    {errors?.gross?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter a gross income
                      </p>
                    )}
                  </div>
                  {/*  Identification Number */}
                  <div className="form-group col-md-4 input-container">
                    <label htmlFor="identification" className="form-label">
                      National Identification Number
                    </label>
                    <input
                      type="text"
                      name="identification"
                      placeholder="Enter NIN *"
                      defaultValue={clientData?.identification}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("identification", {
                        required: true,
                      })}
                    />
                    {errors?.identification?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter NIN or Passport Number
                      </p>
                    )}
                    <input
                      type="hidden"
                      name="group"
                      {...register("group", {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="form-group col-md-4 input-container">
                    <label htmlFor="marital" className="form-label">
                      Marital Status
                    </label>
                    <select
                      name="marital"
                      placeholder="Date of Registration *"
                      className="form-control border-0 shadow form-control-lg input-text"
                      defaultValue={clientData?.martial_status}
                      {...register("marital", {
                        required: true,
                      })}
                    >
                      <option value="" disabled={true}>
                        Select your Marital Status
                      </option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorced">Divorced</option>
                      <option value="widowed">Widowed</option>
                    </select>
                    {errors?.marital?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please select marital status
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <textarea
                      type="text"
                      name="address"
                      placeholder="Address *"
                      defaultValue={clientData?.address}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("address", {
                        required: true,
                      })}
                    ></textarea>
                    {errors?.address?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter Client Address
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="notes" className="form-label">
                      Extra Notes
                    </label>
                    <textarea
                      type="text"
                      name="notes"
                      placeholder="Enter Extra Notes *"
                      defaultValue={clientData?.notes}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("notes", {
                        required: false,
                      })}
                    ></textarea>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={disabled}
                  >
                    {!disabled && "Update"} {disabled && "Please Wait ....."}
                  </Button>
                </Modal.Footer>
              </>
            )}
          </form>
        </>
      </Modal>
    </>
  );
};

export default EditClient;
