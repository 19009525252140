import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Select from "react-select";

const SmsSelectedModal = ({ getSentSms }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  // submit accounts data to API
  const sendMessage = async (data) => {
    // console.log(data)
    const newData = {
      message: data.message,
      clients: data.clients.map((client) => client.value),
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "communication/selected/sms",
        newData,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getSentSms();
    } catch (error) {
      if (!error?.response) {
        console.log(error);
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [clients, setClients] = useState([]);
  const getClients = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get("/clients/general", {
        signal: controller.signal,
      });
      setClients(response?.data?.data?.clients);
      // console.log(response?.data?.data?.clients);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = clients?.map((client) => ({
    value: `${client.id}`,
    label: `${client.name || "No Name"} - ${client.account || "No Account"} - ${
      client.contact || "No Contact"
    }`,
  }));
  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "auto", // adjust the height as needed
    }),
  };
  return (
    <>
      <Toaster />
      <button onClick={handleShow} className="btn btn-outline-info rounded-0">
        Send Selected Client SMS
      </button>{" "}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Send Selected Client SMS </Modal.Title>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(sendMessage)}
          className="needs-validation"
          noValidate
        >
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="clients" className="form-label">
                  Select Clients *
                </label>

                <Controller
                  control={control}
                  name="clients"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("clients", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Account To Attach * "
                      styles={customStyles}
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti
                      ref={ref}
                    />
                  )}
                />

                {errors?.clients?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    Please Select Clients
                  </p>
                )}
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="message" className="text-primary form-label">
                  Enter Your Message
                </label>
                <textarea
                  rows={5}
                  {...register("message", { required: true })}
                  type="text"
                  placeholder="Your message here in 160 characters per sms"
                  className="form-control form-control-md input-text"
                ></textarea>
                {errors?.message?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary rounded-0"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary rounded-0 "
            >
              {!disabled && "Send Message"}
              {disabled && "Sending Please Wait......"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SmsSelectedModal;
