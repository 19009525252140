
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";
import Pagination from "pages/components/pagination";

const LoanPortfolio = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);

  const controller = new AbortController();

  const [loans, setLoans] = useState([]);
  
  const getLoans = async () => {
    progress.start();
    try {
      const response = await axiosPrivate.get(
        "reports/new/loans/loans-portfolio",
        { signal: controller.signal }
      );
      setLoans(response.data.data);
      progress.finish();
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getLoans();
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loans?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(loans?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [totals, setTotals] = useState({
    principal: 0,
    principal_disbursed: 0,
    interest: 0,
    penalties: 0,
    totalArrears: 0,
    portfolioAtRisk: 0,
  });

  useEffect(() => {
    if (currentItems) {
      let principalTotal = 0;
      let principalDisbursedTotal = 0;
      let interestTotal = 0;
      let penaltiesTotal = 0;
      let totalArrearsTotal = 0;

      currentItems.forEach((data) => {
        principalTotal += data.amount;
        principalDisbursedTotal += data.amount_disbursed;
        interestTotal += data.interest;
        penaltiesTotal += data.penalties;
        totalArrearsTotal += data.amount + data.interest + data.penalties;
      });

      const portfolioAtRisk = (principalTotal / principalDisbursedTotal) * 100;

      setTotals({
        principal: principalTotal,
        principal_disbursed: principalDisbursedTotal,
        interest: interestTotal,
        penalties: penaltiesTotal,
        totalArrears: totalArrearsTotal,
        portfolioAtRisk: portfolioAtRisk,
      });
    }
  }, [currentItems]);

  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) progress.start();

      const response = await axiosPrivate.get(
        `/export/reports/loan_portfolio/pdf`,
        { responseType: "blob" }
      );
      const filename = "Loan Portfolio.pdf";
      fileDownload(response.data, filename);
      if (progress) progress.finish();
    } catch (error) {
      console.error("Error downloading loan portfolio:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) progress.finish();
    }
  };

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) progress.start();

      const response = await axiosPrivate.get(
        `/export/reports/loan_portfolio/csv`,
        { responseType: "blob" }
      );

      const filename = `Loan_Portfolio_Report.csv`;
      fileDownload(response.data, filename);
      if (progress) progress.finish();
    } catch (error) {
      console.error("Error downloading aging loans report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) progress.finish();
    }
  };

  return (
    <>
      <section className="mt-2">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Loan Portfolio Report{" "}
                </h2>
              </div>
              <div className="card-body mt-1 p-1">
                <div className="mt-3">
                  <table className="table table-striped table-bordered b-0 mt-4 text-medium-small">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>Member's Name</th>
                        <th>Account No.</th>
                        <th>Loan Product</th>
                        <th>Principal Disbursed</th>
                        <th>Principal Overdue</th>
                        <th>Interest Due</th>
                        <th>Total Due</th>
                        <th>Portfolio at risk</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((data) => (
                        <tr key={data?.id}>
                          <td>{data?.client}</td>
                          <td>{data?.account}</td>
                          <td>{data?.product}</td>
                          <td style={{ textAlign: "right" }}>{data?.amount_disbursed?.toLocaleString()}</td>
                          <td style={{ textAlign: "right" }}>{data?.amount?.toLocaleString()}</td>
                          <td style={{ textAlign: "right" }}>{data?.interest?.toLocaleString()}</td>
                          <td style={{ textAlign: "right" }}>
                            {(data?.interest + data?.amount)?.toLocaleString()}
                          </td>
                          <td>{data?.portfolio_at_risk.toLocaleString()}%</td>
                        </tr>
                      ))}
                      <tr className="bg-dark text-white">
                        <td className="font-weight-bold text-white" colSpan={3}>
                          Totals
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.principal_disbursed.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.principal.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.interest.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.totalArrears.toLocaleString()}
                        </td>
                        <td className="text-white">
                          {(totals.principal / totals.principal_disbursed * 100).toFixed(2)}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
                <div className="d-flex align-items-center mt-0">
                  <div className="ml-auto mb-0 mt-0">
                    <button
                      className="btn btn-primary text-right"
                      onClick={downloadUssdReportAsPDF}
                    >
                      {isPDFDownloading
                        ? "Downloading please wait..."
                        : "Download PDF"}
                    </button>
                    <button
                      className="btn btn-secondary text-right"
                      onClick={downloadUssdReportAsCSV}
                    >
                      {isCSVDownloading
                        ? "Downloading please wait..."
                        : "Download CSV"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoanPortfolio;