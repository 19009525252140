import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const SmsModal = ({getSentSms}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // submit accounts data to API
  const sendMessage = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "communication/general/sms",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getSentSms();
    } catch (error) {
      if (!error?.response) {
        console.log(error)
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Toaster />
      <button onClick={handleShow} className="btn btn-outline-danger rounded-0">
        Send SMS
      </button>{" "}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Send SMS (To All Clients)</Modal.Title>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(sendMessage)}
          className="needs-validation"
          noValidate
        >
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="message" className="text-primary form-label">
                  Enter Your Message
                </label>
                <textarea
                  rows={10}
                  {...register("message", {required: true })}
                  type="text"
                  placeholder="Your message here in 160 characters per sms"
                  className="form-control form-control-md input-text"
                ></textarea>
                {errors?.message?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary rounded-0" onClick={handleClose}>
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary rounded-0"
            >
              {!disabled && "Send Message"}
              {disabled && "Sending Please Wait......"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SmsModal;
