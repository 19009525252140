import { Modal, Button } from "react-bootstrap";

const ConfirmModal = ({ message, onConfirm, onCancel, show, setShow }) => {

  const handleClose = () => {
    setShow(false);
    onCancel();
  };

  const handleConfirm = () => {
    setShow(false);
    onConfirm();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="sm">
      <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>
        <button onClick={handleClose} className="btn-danger">
          <i className="fa fa-close"></i>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="container">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
