import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const EditGroup = ({ showModal, handleCloseModal, clientData, getClient }) => {
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: clientData });

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/clients/groups/${params.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getClient();
      handleCloseModal();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <>
                <Modal.Header>
                  <Modal.Title className="text-center">
                    {" "}
                    Edit Group{" "}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="row col-md-12 mx-auto">
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="title" className="form-label">
                      Group Name
                    </label>
                    <input
                      type="text"
                      name="title"
                      placeholder="Enter Group Name *"
                      className="form-control border-0 shadow form-control-md input-text"
                      defaultValue={clientData.title}
                      {...register("title", { required: true })}
                    />
                    {errors?.firstname?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter group title
                      </p>
                    )}
                  </div>

                  {/* Date of joining */}
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="dateOfJoin" className="form-label">
                      Select Date of Joining
                    </label>
                    <input
                      type="date"
                      name="dateOfJoin"
                      placeholder="Date of birth *"
                      className="form-control border-0 shadow form-control-md input-text"
                      defaultValue={clientData.date_of_joining}
                      {...register("dateOfJoin", { required: true })}
                    />
                    {errors?.dateOfJoin?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter Date Of Joining
                      </p>
                    )}
                  </div>

                  {/* Phone Number */}
                  <div className="form-group col-md-6  input-container">
                    <label htmlFor="phonenumber" className="form-label">
                      Group Contact
                    </label>
                    <input
                      type="text"
                      name="contact"
                      placeholder="Contact phone *"
                      maxLength="10"
                      defaultValue={clientData.contact}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("contact", {
                        required: true,
                        maxLength: 10,
                        minLength: 10,
                      })}
                    />
                    {errors?.contact?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter group phone number
                      </p>
                    )}
                    {(errors?.contact?.type === "maxLength" ||
                      errors?.contact?.type === "minLength") && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Invalid phone number, please cross check
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter Email Address "
                      defaultValue={clientData.email}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("email", {
                        pattern: { value: /^\S+@\S+$/i },
                      })}
                    />
                    {errors?.email?.type === "pattern" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter a valid email address
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <textarea
                      type="text"
                      name="address"
                      placeholder="Address *"
                      defaultValue={clientData.address}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("address", {
                        required: true,
                      })}
                    ></textarea>
                    {errors?.address?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please enter Client Address
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 input-container">
                    <label htmlFor="notes" className="form-label">
                      Extra Notes
                    </label>
                    <textarea
                      type="text"
                      name="notes"
                      placeholder="Enter Extra Notes *"
                      defaultValue={clientData.notes}
                      className="form-control border-0 shadow form-control-md input-text"
                      {...register("notes", {
                        required: false,
                      })}
                    ></textarea>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="Button"
                    className="btn btn-secondary"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={disabled}
                  >
                    {!disabled && "Update"} {disabled && "Please Wait ....."}
                  </Button>
                </Modal.Footer>
              </>
          
          </form>
        </>
      </Modal>
    </>
  );
};

export default EditGroup;
