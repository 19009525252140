import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const EditSystemUsers = ({
  getSystemUsers,
  users,
  data,
  handleClose,
  show,
}) => {
  // console.log(data)
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [roles, setRoles] = useState([]);

  const controller = new AbortController();
  const getRoles = async () => {
    try {
      const response = await axiosPrivate.get("settings/rights/roles", {
        signal: controller.signal,
      });
      setRoles(response.data.data.role);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur", defaultValues: data });

  const onSave = async (update_data) => {
    // console.log(update_data);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `sacco/users/${data?.id}`,
        update_data,
        {
          signal: controller.signal,
        }
      );
      console.log(response);
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getSystemUsers();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-4">
                {errors?.firstname?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  First Name{" "}
                </label>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control border-0 shadow form-control-md input-text"
                    defaultValue={data?.firstname}
                    {...register("firstname", { required: true })}
                    placeholder="Enter firstname"
                  />
                </div>
              </div>

              <div className="col-4">
                {errors?.lastname?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Last Name{" "}
                </label>
                <div className=" form-group">
                  <input
                    type="text"
                    className="form-control border-0 shadow form-control-md input-text"
                    defaultValue={data?.lastname}
                    {...register("lastname", { required: true })}
                    placeholder="Enter lastname"
                  />
                </div>
              </div>

              <div className="col-4">
                {errors?.gender?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Gender{" "}
                </label>
                <div className=" form-group">
                  <select
                    type="text"
                    className="form-control border-0 shadow form-control-lg input-text"
                    {...register("gender", { required: true })}
                    defaultValue={data?.gender}
                  >
                    <option value="" disabled>
                      Select gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-4">
                {errors?.status?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Status{" "}
                </label>
                <div className=" form-group">
                  <select
                    type="text"
                    className="form-control border-0 shadow form-control-lg input-text"
                    {...register("status", { required: true })}
                    defaultValue={data?.status}
                  >
                    <option value="" disabled>
                      Select Status
                    </option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>

              <div className="col-4">
                <label className="text-start text-primary help-block">
                  Contact{" "}
                </label>
                <div className=" form-group">
                  <input
                    type="number"
                    className=" form-control border-0 shadow form-control-md input-text"
                    // {...register("contact", { required: true })}
                    placeholder="Enter Contact"
                    defaultValue={data?.contact}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-4">
                <label className="text-start text-primary help-block">
                  Email{" "}
                </label>
                <div className=" form-group">
                  <input
                    type="email"
                    className="form-control border-0 shadow form-control-md input-text"
                    // {...register("email", { required: true })}
                    placeholder="Enter email"
                    defaultValue={data?.email}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-4">
                {errors?.role?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Role{" "}
                </label>
                <div className=" form-group">
                  <select
                    className="form-control border-1 shadow-0 form-control-lg input-text"
                    {...register("role", { required: true })}
                  >
                    {roles?.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <label className="text-start text-primary help-block">
                  Manager{" "}
                </label>
                <div className=" form-group">
                  <select
                    className="form-control border-1 shadow-0 form-control-lg input-text"
                    {...register("manager", { required: false })}
                  >
                    {users?.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.firstname} {user.lastname}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-warning"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditSystemUsers;
