import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";

const LoanAutoCharge = ({ showModal, handleCloseModal, getAutoCharges }) => {
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const params = useParams();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState([]);
  const controller = new AbortController();
  const getLoanProductAutoCharges = async () => {
    try {
      const response = await axiosPrivate.get(`/settings/accounts/account`, {
        signal: controller.signal,
      });
      setData(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoanProductAutoCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
   const options = data?.map((data) => ({
     value: `${data.id}`,
     label: `${data.title}`,
   }));

   const customStyles = {
     control: (provided) => ({
       ...provided,
       height: "45px", // adjust the height as needed
     }),
   };
  const onSave = async (data) => {
    try {
        let account = data.account.value;
        delete data.account;
        data.account = account;
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/settings/loans/autocharges",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response.data.messages);
      getAutoCharges();
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center">
              {" "}
              Loan Product Auto Charge{" "}
            </Modal.Title>
            <button onClick={handleCloseModal} className="btn-close btn-danger">
              <i className="fa fa-close"></i>
            </button>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-6 input-container">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                name="title"
                placeholder="Title *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("title", {
                  required: true,
                })}
              />
              <input
                type="hidden"
                value={params?.id}
                {...register("product", {
                  required: true,
                })}
              />
              {errors?.date?.title === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter the Title
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="value" className="form-label">
                Value
              </label>
              <input
                type="number"
                name="value"
                placeholder="Value  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("value", {
                  required: true,
                })}
              />
              {errors?.amount?.value === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter value
                </p>
              )}
            </div>

            <div className="form-group col-md-6 input-container">
              <label htmlFor="type" className="form-label">
                Type
              </label>
              <select
                name="type"
                className="form-control border-0 shadow form-control-lg input-text"
                {...register("type", {
                  required: true,
                })}
                defaultValue=""
              >
                <option defaultValue="" disabled>
                  Please Select type
                </option>
                <option value="value">Flat Amount</option>
                <option value="percentage">Percentage</option>
              </select>
              {errors?.period?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please select the type
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="nature" className="form-label">
                Nature
              </label>
              <select
                name="nature"
                className="form-control border-0 shadow form-control-lg input-text"
                {...register("nature", {
                  required: true,
                })}
                defaultValue=""
              >
                <option defaultValue="" disabled>
                  Please Select nature
                </option>
                <option value="special">Others</option>
                <option value="saving">Savings</option>
              </select>
              {errors?.period?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please select the nature
                </p>
              )}
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="account" className="form-label">
                Select Account To Attach *
              </label>
              <Controller
                control={control}
                name="account"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    {...register("account", { required: true })}
                    className="border-1 form-control-md"
                    placeholder="Select  Acocunt To Attach  * "
                    styles={customStyles}
                    options={options}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    isMulti={false}
                    ref={ref}
                  />
                )}
              />
              {errors?.account?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Account
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Save"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default LoanAutoCharge;
