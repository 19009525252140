import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const BusinessProfile = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [business_profiles, setBusiness_profiles] = useState([]);
  const [photo, setPhoto] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const logoUpdate = async (data) => {
    const formData = new FormData();
    formData.append("file", data.file[0]);
    // console.log(formData);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `business/photo`,
        formData,
        {
          headers: {
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data;`,
          },
        },
        {
          signal: controller.signal,
        }
      );

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset1();
      getBusinessPhoto();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const infoSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch("business/profile", data, {
        signal: controller.signal,
      });

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);

        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const controller = new AbortController();
  const getBusinessProfile = async () => {
    try {
      const response = await axiosPrivate.get("business/profile", {
        signal: controller.signal,
      });
      setBusiness_profiles(response?.data?.data?.business_profile);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  const getBusinessPhoto = async () => {
    try {
      const response = await axiosPrivate.get("business/photo", {
        signal: controller.signal,
      });
      setPhoto(response?.data?.data?.file);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getBusinessProfile();
    getBusinessPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const imageData = localStorage.getItem('imageData');

  return (
    <>
      <Toaster />

      <section className="py-2 mt-3">
        <div className="row">
          <div className="card mb-5 mb-lg-0">
            <div className="card-header">
              <h2 className="h6 mb-0 text-uppercase text-center">
                business profile
              </h2>
            </div>
            <div className="card-body p-2 mt-3 mb-3">
              <div className="row">
                <form
                  encType="multipart/form-data"
                  onSubmit={handleSubmit1(logoUpdate)}
                  className="col-6 mt-3 px-5 needs-validation"
                >
                  <div className="row">
                    <label className="col-4 form-group btn btn-outline-dark">
                      <span className="mdi mdi-file-image"> Select Logo</span>{" "}
                      <input
                        {...register1("file", {
                          required: true,
                        })}
                        type="file"
                        id="logo-images"
                        className="form-control"
                        style={{ display: "none" }}
                      />
                    </label>
                    <div className="col-6">
                      <button type="submit" className="btn btn-primary login">
                        Upload Logo
                      </button>
                      {errors1?.file?.type === "required" && (
                        <span className="text-danger">Choose logo first</span>
                      )}
                    </div>
                    <div className="help-block">Maximum Image size 500KB</div>
                  </div>
                </form>
                <div className="col-4">
                  <img
                    className="float-end"
                    src={photo?.url}
                    alt={photo?.id}
                    width="100px"
                  />
                </div>
              </div>
              <div className="container row d-flex justify-content-center">
                <div className="col-9">
                  <form
                    onSubmit={handleSubmit(infoSave)}
                    className="row mt-5 needs-validation"
                  >
                    <div className="col-md-6 form-account-add-single-member">
                      <div className="form-group mb-4 input-container">
                        <label htmlFor="name">Business Name</label>
                        <input
                          {...register("name", {
                            required: true,
                          })}
                          type="text"
                          defaultValue={business_profiles.name}
                          className="form-control border-0 shadow form-control-md input-text"
                        />
                        {errors?.name?.type === "required" && (
                          <span className="text-danger">
                            Busines name missing
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 form-account-add-single-member">
                      <div className="form-group mb-4 input-container">
                        <label>Phone Number</label>
                        <input
                          {...register("contact", {
                            required: true,
                          })}
                          type="text"
                          id="contact"
                          defaultValue={business_profiles.contact}
                          className="form-control border-0 shadow form-control-md input-text"
                        />
                        {errors?.contact?.type === "required" && (
                          <span className="text-danger">
                            Phone number missing
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 form-account-add-single-member">
                      <div className="form-group mb-4 input-container">
                        <label>Bussiness Short Name(Acronym)</label>
                        <input
                          {...register("short_name", {
                            required: true,
                          })}
                          type="text"
                          defaultValue={business_profiles.short_name}
                          name="short_name"
                          id="short_name"
                          className="form-control border-0 shadow form-control-md input-text"
                        />
                        {errors?.short_name?.type === "required" && (
                          <span className="text-danger">
                            Sacco short name required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 form-account-add-single-member">
                      <div className="form-group mb-4 input-container">
                        <label>Address/City/District</label>
                        <input
                          {...register("location", {
                            required: true,
                          })}
                          type="text"
                          defaultValue={business_profiles.location}
                          className="form-control border-0 shadow form-control-md input-text"
                        />
                        {errors?.location?.type === "required" && (
                          <span className="text-danger">
                            Sacco location required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-12 text-right">
                      <button
                        disabled={disabled}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {!disabled && "Update"}
                        {disabled && "Please Wait"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessProfile;
