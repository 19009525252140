import { useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import NewBill from "./components/newbill";
import PayBill from "./components/paybill";
import Table from "pages/components/common_table";

const Bills = () => {
  const [showModal, setShowModal] = useState(false);
  const [showOpenModal, setShowOpenModal] = useState(false);
      const [isLoading, setIsLoading] = useState(true);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleOpenShowModal = () => setShowOpenModal(true);
  const handleOpenCloseModal = () => setShowOpenModal(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const controller = new AbortController();
  const getBills = async () => {
    try {
      const response = await axiosPrivate.get("/accounting/vendors/bills", {
        signal: controller.signal,
      });
      setData(response.data.data.transactions);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getBills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
const refetch = () => {
  setIsLoading(true);
  getBills();
}
  const columns = useMemo(
    () => [
      {
        id: "notes", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Bill Title",
        size: 50,
        accessorFn: (row) => row?.notes,
      },
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => row?.amount?.toLocaleString(),
      },
      {
        id: "date", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Date",
        accessorFn: (row) => row?.date,
        size: 50,
      },
      {
        id: "due_date", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Due Date",
        size: 50,
        accessorFn: (row) => row?.due_date,
      },
      {
        id: "reference", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Bill Reference",
        size: 50,
        accessorFn: (row) => row?.code,
      },
      {
        id: "vendor", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Vendor",
        accessorFn: (row) => row?.vendor,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Status",
        size: 50,
        accessorFn: (row) =>
          row?.status === "paid" ? (
            <div className="badge badge-success p-1 text-white">Paid</div>
          ) : row?.status === "pending" ? (
            <div className="badge badge-info p-1 text-white">Pending</div>
          ) : (
            ""
          ),
      },

      {
        id: "expense_account", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Expense Account",
        size: 50,
        accessorFn: (row) => row?.expense_account,
      },
     
      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Registered On",
        size: 50,
        accessorFn: (row) => row?.timestamp,
      },
    ],
    []
  );
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  return (
    <>
      <section className="py-3 mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <NewBill
                showModal={showModal}
                getBills={getBills}
                handleCloseModal={handleCloseModal}
              />
              <PayBill
                showModal={showOpenModal}
                getBills={getBills}
                handleCloseModal={handleOpenCloseModal}
              />
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  data={data}
                  isLoading={isLoading}
                  refetch={refetch}
                  headers={
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="h6 mb-0 text-uppercase text-center">
                          Bills and Bill Payments
                        </h2>
                      </div>
                      <div className="col-md-6 btn-group">
                        {roles?.includes(1000033) && (
                          <button
                            className="btn btn-danger  btn-sm text-small rounded-0"
                            onClick={handleOpenShowModal}
                          >
                            Pay Bill
                          </button>
                        )}
                        {roles?.includes(1000032) && (
                          <button
                            className="btn btn-outline-dark btn-sm text-small rounded-0"
                            onClick={handleShowModal}
                          >
                            New Bill
                          </button>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Bills;
