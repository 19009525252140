import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import Select from "react-select";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const UserRolesmodal = ({ getUsers }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
 
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [permissions, setPermissions] = useState([]);

  const controller = new AbortController();
  const getPermissions = async () => {
    try {
      const response = await axiosPrivate.get("settings/rights/permissions", {
        signal: controller.signal,
      });
      setPermissions(response.data.data.permissions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (data) => {
    console.log(data);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("settings/rights/roles", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getUsers();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Toaster />
      <button onClick={handleShow} className="btn btn-outline-primary">
        Add User Role
      </button>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-sample modal-dialog-sample"
      >
        <Modal.Header>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body className="modal-body-sample">
            <div className="row">
              <div className="col-12">
                {errors?.role?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field Is Required
                  </p>
                )}
                <label className="text-start text-primary help-block">
                  Role Title{" "}
                </label>
                <div className="mb-3 input-group input-group-outline">
                  <input
                    type="text"
                    className="form-control border-0 shadow form-control-md input-text"
                    id="role"
                    name="role"
                    placeholder = "Enter Role Title"
                    {...register("role", { required: true })}
                  />
                </div>
              </div>
              <div className="col-12 row px-3">
                {permissions?.map((data) => (
                  <React.Fragment key={data?.id}>
                    <div className="bg-dark col-12 p-2 m-2">
                      <h1 className="text-white">{data?.name}</h1>
                    </div>
                    {data?.permissions?.map((permission) => (
                      <div className="col-3" key={permission?.id}>
                        <input
                          type="checkbox"
                          value={permission?.id}
                          name="permissions"
                          {...register("permissions")}
                        />
                        <span className="px-1 text-dark text-sm">
                          {permission?.name}
                        </span>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-warning"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UserRolesmodal;
