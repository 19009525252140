import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const ActionModalProcess = ({
  showModal,
  handleCloseModal,
  action,
  getloan,
  loanDefaults,
}) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    console.log(action);
    data.action = action;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.put(
        `/loans/applications/${params.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getloan();
      handleCloseModal();
    } catch (error) {
      if (!error?.response) {
        console.log(error);
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size={action === "disburse" ? "md" : "sm"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <>
              <Modal.Header>
                <Modal.Title className="text-center">
                  {" "}
                  Action - {action}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="row mx-auto">
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="date" className="form-label">
                    Action Date
                  </label>
                  <input
                    type="datetime-local"
                    name="date"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("date", { required: true })}
                  />
                  {errors?.date?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please Select Date
                    </p>
                  )}
                </div>
                {action === "disburse" ? (
                  <>
                    <div className="form-group col-md-6 input-container">
                      <label htmlFor="interest" className="form-label">
                        Interest
                      </label>
                      <input
                        type="number"
                        name="interest"
                        defaultValue={loanDefaults?.loan_product_interest}
                        className="form-control border-0 shadow form-control-md input-text"
                        {...register("interest", { required: true })}
                      />
                      {errors?.date?.interest === "required" && (
                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                          This is required
                        </p>
                      )}
                    </div>
                    <div className="form-group col-md-6 input-container">
                      <label htmlFor="period" className="form-label">
                        Period
                      </label>
                      <input
                        type="number"
                        name="period"
                        defaultValue={loanDefaults?.period}
                        className="form-control border-0 shadow form-control-md input-text"
                        {...register("period", { required: true })}
                      />
                      {errors?.date?.period === "required" && (
                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                          This is required
                        </p>
                      )}
                    </div>
                    <div className="form-group col-md-12 input-container">
                      <label htmlFor="amount" className="form-label">
                        Amount
                      </label>
                      <input
                        type="number"
                        name="amount"
                        defaultValue={loanDefaults?.amount}
                        className="form-control border-0 shadow form-control-md input-text"
                        {...register("amount", { required: true })}
                      />
                      {errors?.date?.amount === "required" && (
                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                          This is required
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit(onSave)}
                  disabled={disabled}
                >
                  {!disabled && "Take Action"} {disabled && "Please Wait ....."}
                </Button>
              </Modal.Footer>
            </>
          </form>
        </>
      </Modal>
    </>
  );
};

export default ActionModalProcess;
