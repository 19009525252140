import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "../../../../config/axios";
import fileDownload from "js-file-download";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";

const Amortization = ({
  showModal,
  handleCloseModal,
  loanProductData,
  loan,
}) => {
  // console.log(loanProductData);
  const [disabled, setDisabled] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const [calcData, setCalcData] = useState([]);
  const localData = JSON.parse(localStorage?.user_data)?.sacco_email;

  const controller = new AbortController();
  const getAmortizationSchedule = async () => {
    try {
      const response = await axiosPrivate.get(
        `/loans/calculator/${params.id}`,
        {
          signal: controller.signal,
        }
      );
      setCalcData(response?.data?.data?.schedule);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAmortizationSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let interest = 0;
  let principal = 0;

  for (let i = 0; i < calcData?.length; i++) {
    interest += calcData[i].interest;
    principal += calcData[i].principal;
  }

  let interestOne = 0;
  let principalOne = 0;

  for (let i = 0; i < loan?.schedule?.length; i++) {
    interestOne += loan?.schedule?.[i].interest;
    principalOne += loan?.schedule?.[i].principal;
  }
  const onSave = async (calcData) => {
    try {
      const data = {
        data: calcData,
        saccoData: localData,
        interest: interest,
        principal: principal,
        loanData: loan,
      };
      console.log(data);
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axios.post(
        `/export/reports/loans/before/amortization`,
        { data: data },
        { responseType: "blob" },
        {
          signal: controller.signal,
        }
      );
      // console.log(response.data);
      const download_title =
        `${loan?.account_name.replace(/\s/g, "")}-loan-amortization.pdf`;
      fileDownload(response.data, download_title);
      progress.finish();
      setDisabled(false);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const onSaveOne = async () => {
    try {
      const data = {
        saccoData: localData,
        interest: interestOne,
        principal: principalOne,
        loanData: loan,
      };
      // console.log(data);
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axios.post(
        `/export/reports/loans/after/amortization`,
        { data: data },
        { responseType: "blob" },
        {
          signal: controller.signal,
        }
      );
      // console.log(response.data);
      const download_title = `${loan?.account_name.replace(
        /\s/g,
        ""
      )}-loan-amortization.pdf`;
      fileDownload(response.data, download_title);
      progress.finish();
      setDisabled(false);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      {loan?.disburse_status !== "disburse" ? (
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={handleCloseModal}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Loan Amortization Schedule{" "}
            </Modal.Title>
            <button onClick={handleCloseModal} className="btn-close btn-danger">
              <i className="material-icons opacity-10">close</i>
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="container-fliud">
              <table className="table text-sm table-bordered">
                <thead className="text-center">
                  <tr>
                    <th>Period</th>
                    <th>Due Date</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Total Payment</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {calcData?.map((data) => (
                    <tr key={data.period}>
                      <td className="text-center"> {data.period} </td>
                      <td className="text-center"> {data.date} </td>

                      <td className="text-right">
                        {" "}
                        {data.principal.toLocaleString()}{" "}
                      </td>
                      <td className="text-right">
                        {" "}
                        {data.interest.toLocaleString()}{" "}
                      </td>
                      <td className="text-right">
                        {" "}
                        {(
                          parseInt(data.interest) + parseInt(data.principal)
                        ).toLocaleString()}{" "}
                      </td>
                      <td className="text-right">
                        {" "}
                        {data.balance.toLocaleString()}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="d-flex flex-row-reverse text-dark p-0">
                <div className="py-3 px-2 text-center text-sm">
                  <div className="mb-2 text-dark fw-500">Total Amount Due</div>
                  <div className="h6 font-weight-light">
                    {(interest + principal).toLocaleString()}{" "}
                  </div>
                </div>
                <div className="py-3 px-2 text-center text-sm">
                  <div className="mb-2">Total Principal</div>
                  <div className="h6 font-weight-light">
                    {" "}
                    {principal.toLocaleString()}{" "}
                  </div>
                </div>
                <div className="py-3 px-2 text-center text-sm">
                  <div className="mb-2">Total Interest</div>
                  <div className="h6 font-weight-light">
                    {interest.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              disabled={disabled}
              onClick={() => onSave(calcData)}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Download"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={handleCloseModal}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Loan Amortization Schedule{" "}
            </Modal.Title>
            <button onClick={handleCloseModal} className="btn-close btn-danger">
              <i className="material-icons opacity-10">close</i>
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="container-fliud">
              <table className="table text-sm table-bordered">
                <thead className="text-center">
                  <tr>
                    <th>Period</th>
                    <th>Due Date</th>
                    <th>Principal</th>
                    <th>Paid Principal</th>
                    <th>Interest</th>
                    <th>Paid Interest</th>
                    <th>Penalties</th>
                    <th>Paid Penalties</th>
                    <th>Amount Paid</th>
                    <th>Total Payment</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {loan?.schedule?.map((data) => (
                    <tr key={data?.period}>
                      <td className="text-center"> {data.period} </td>
                      <td className="text-center"> {data.date} </td>
                      <td className="text-center">
                        {" "}
                        {data?.principal?.toLocaleString()}{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        {data?.principal_paid?.toLocaleString()}{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        {data?.interest?.toLocaleString()}{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        {data?.interest_paid?.toLocaleString()}{" "}
                      </td>
                      <td>{data?.penalities?.toLocaleString()} </td>
                      <td>{data?.penalities_paid?.toLocaleString()} </td>
                      <td>{data?.amount_paid?.toLocaleString()} </td>
                      <td className="text-center">
                        {" "}
                        {(
                          parseInt(data?.interest) +
                          parseInt(data?.principal) +
                          parseInt(data?.penalities)
                        ).toLocaleString()}{" "}
                      </td>
                      <td className="text-center">
                        {" "}
                        {data?.remaining_balance?.toLocaleString()}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="d-flex flex-row-reverse text-dark p-0">
                <div className="py-3 px-2 text-center text-sm">
                  <div className="mb-2 text-dark fw-500">Total Amount Due</div>
                  <div className="h6 font-weight-light">
                    {(interestOne + principalOne).toLocaleString()}{" "}
                  </div>
                </div>
                <div className="py-3 px-2 text-center text-sm">
                  <div className="mb-2">Total Principal</div>
                  <div className="h6 font-weight-light">
                    {" "}
                    {principalOne.toLocaleString()}{" "}
                  </div>
                </div>
                <div className="py-3 px-2 text-center text-sm">
                  <div className="mb-2">Total Interest</div>
                  <div className="h6 font-weight-light">
                    {interestOne.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              disabled={disabled}
              onClick={() => onSaveOne()}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Download"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Amortization;
