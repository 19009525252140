import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const LoapProductModal = ({ show, handleClose, getLoanProducts }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "settings/loans/products",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getLoanProducts();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [activeStep, setActiveStep] = useState(1);

  //Navigates to the next page
  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      // setStep(step + 1);
      setActiveStep((nextStep) => nextStep + 1);
    }
  };

  //Navigates to the Previous page
  const handleBack = () => {
    setActiveStep((previousStep) => previousStep - 1);
  };

  return (
    <>
      <Toaster />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <b>Add loan product</b>
          </Modal.Title>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            {activeStep === 1 && (
              <fieldset>
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Name of Loan Product</label>
                      <input
                        {...register("title", { required: true })}
                        type="text"
                        placeholder="Enter Name of Loan Product"
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                      {errors?.title?.type === "required" && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Loan Rate Type </label>

                      <select
                        {...register("type", { required: true })}
                        type="text"
                        className="form-control border-0 shadow form-control-lg input-text"
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Loan Rate Type
                        </option>
                        <option value="fixed">Fixed Rate</option>
                        <option value="reducing_balance">
                          Reducing Balance
                        </option>
                      </select>
                      {errors?.type?.type === "required" && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Interest Rate in %</label>
                      <input
                        {...register("interest", { required: true })}
                        type="text"
                        placeholder="Enter interest rate"
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                    </div>
                    {errors?.interest?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Interest Interval</label>
                      <select
                        {...register("interval", { required: true })}
                        type="text"
                        defaultValue=""
                        className="form-control border-0 shadow form-control-lg input-text"
                      >
                        <option value="" disabled>
                          Select Interest Interval
                        </option>
                        <option value="weekly">Weekly</option>
                        <option value="daily">Daily</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                    {errors?.interval?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Loan Processing fees</label>
                      <input
                        {...register("processing_fee", { required: true })}
                        type="number"
                        placeholder="Enter Loan processing fees for this product"
                        className="form-control border-0 shadow form-control-md input-text"
                        required
                      />
                    </div>
                    {errors?.processing_fee?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">
                        Minimum amount that can be applied for
                      </label>
                      <input
                        {...register("min_value", { required: true })}
                        type="number"
                        placeholder="Specify minimum amount client can apply for"
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                    </div>
                    {errors?.min_value?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4 input-container">
                      <label htmlFor="" className="form-label">
                        Maximum amount that can be applied for
                      </label>
                      <input
                        {...register("max_value", { required: true })}
                        type="text"
                        placeholder="Specify maximum amount client can apply for"
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                    </div>
                    {errors?.max_value?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    // type="button"
                    className="btn btn-outline-primary"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </fieldset>
            )}
            {activeStep === 2 && (
              <fieldset>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-4 input-container">
                      <label htmlFor="" className="form-label">
                        Number of guarantors allowed
                      </label>
                      <input
                        {...register("guarantors", { required: true })}
                        type="text"
                        placeholder="Specify number of guarantors allowed"
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                    </div>
                    {errors?.guarantors?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Penalty Interval</label>
                      <select
                        {...register("penalty_interval", { required: true })}
                        type="text"
                        className="form-control border-0 shadow form-control-lg input-text"
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Interval
                        </option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                    {errors?.penalty_interval?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Penalty mode</label>
                      <select
                        {...register("penalty_mode", { required: true })}
                        type="text"
                        className="form-control border-0 shadow form-control-lg input-text"
                        defaultValue=""
                      >
                        <option value="" disabled hidden>
                          Select Penalty Mode
                        </option>
                        <option value="percentage">Percentage</option>
                        <option value="value">Value</option>
                      </select>
                    </div>
                    {errors?.penalty_mode?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">
                        Set Penalty (Value or Percentage)
                      </label>
                      <input
                        {...register("penalty_amount", { required: true })}
                        type="number"
                        placeholder="Set Penalty (Value or Percentage)"
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                    </div>
                    {errors?.penalty_amount?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    onClick={handleBack}
                    className="btn btn-outline-warning"
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNext}
                    className="btn btn-outline-primary"
                  >
                    Next
                  </button>
                </div>
              </fieldset>
            )}
            {activeStep === 3 && (
              <fieldset>
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label"> Roll over fees</label>
                      <input
                        {...register("roll_over_fees", { required: true })}
                        type="number"
                        className="form-control border-0 shadow form-control-md input-text"
                      />
                    </div>
                    {errors?.roll_over_fees?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Roll over mode</label>
                      <select
                        placeholder="Select"
                        {...register("roll_over_mode", { required: true })}
                        type="text"
                        className="form-control border-0 shadow form-control-lg input-text"
                      >
                        <option value="" disabled>
                          Select RollOver Mode
                        </option>
                        <option value="value">Value</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                    {errors?.roll_over_mode?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Roll over principal</label>
                      <select
                        {...register("roll_over_principal", { required: true })}
                        type="text"
                        className="form-control border-0 shadow form-control-lg input-text"
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Principal Rollover
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    {errors?.roll_over_principal?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group mb-4 input-container">
                      <label className="form-label">Roll over interest</label>
                      <select
                        {...register("roll_over_interest", { required: true })}
                        type="text"
                        className="form-control border-0 shadow form-control-lg input-text"
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select RollOver Interest
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    {errors?.roll_over_interest?.type === "required" && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={handleBack}
                    className="btn btn-outline-warning"
                  >
                    Previous
                  </button>
                  <button
                    disabled={disabled}
                    type="submit"
                    className="btn btn-outline-primary"
                  >
                    {!disabled && "Save"}
                    {disabled && "Please Wait"}
                  </button>
                </div>
              </fieldset>
            )}
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};

export default LoapProductModal;
