import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { Button } from "react-bootstrap";
import LoanDocuments from "./components/loan-documents";
import LoanColletral from "./components/loan-colletral";
import LoanGuarantor from "./components/loan-guarantor";
import LoanEdit from "./components/loan-edit";
import Amortization from "./components/amortization";
import LoanSchedule from "./components/loan-schedule";
import RepaymentDetails from "./components/repayment-details";
import PenaltyBreakDowns from "./components/penaltybreakdown";
import ExtraCharges from "./components/extra-charges";
import ActionModalProcess from "./components/actionModalProcess";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import RollOverModal from "./components/rollover";
import RescheduleModal from "./components/reschedule";
import LoanRepaymentGeneralModal from "./components/loan-repayment-general-modal";

const LoanApplicationSingle = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [roles, setRoles] = useState([]);
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  const [loan, setLoan] = useState([]);
  const [colletral, setColletral] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [guarantors, setGuarantors] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const controller = new AbortController();
  const getloan = async () => {
    try {
      const response = await axiosPrivate.get(
        `/loans/applications/${params.id}`,
        {
          signal: controller.signal,
        }
      );

      setLoan(response?.data?.data?.loan);
      console.log(response?.data?.data?.loan);
      setColletral(response?.data?.data?.loan?.colletral);
      setDocuments(response?.data?.data?.loan?.documents);
      setGuarantors(response?.data?.data?.loan?.guarantors);
      setSchedule(response?.data?.data?.loan?.schedule);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getloan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState([]);
  const [loanDocumentModal, setLoanDocumentModal] = useState(false);
  const [loanColletralModal, setLoanColletralModal] = useState(false);
  const [loanGuarantorModal, setLoanGuarantorModal] = useState(false);
  const [loanUpdateModal, setLoanUpdateModal] = useState(false);
  const [loanAmortizationModal, setLoanAmortizationModal] = useState(false);
  const handleOpenColletralModal = () => setLoanColletralModal(true);
  const handleCloseColletralModal = () => setLoanColletralModal(false);
  const handleOpenDocuments = () => setLoanDocumentModal(true);
  const handleCloseDocuments = () => setLoanDocumentModal(false);
  const handleOpenGuarantorModal = () => setLoanGuarantorModal(true);
  const handleCloseGuarantorModal = () => setLoanGuarantorModal(false);
  const handleOpenUpdateModal = () => setLoanUpdateModal(true);
  const handleCloseUpdateModal = () => setLoanUpdateModal(false);
  const handleOpenAmortizationModal = () => setLoanAmortizationModal(true);
  const handleCloseAmortizationModal = () => setLoanAmortizationModal(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const waiverOffLoan = async () => {
    const data = {
      waiver: "yes",
    };
    if (window.confirm("Are you sure, you want to Waiver Off this Loan")) {
      try {
        progress.start();
        setDisabled(true);
        const controller = new AbortController();
        const response = await axiosPrivate.patch(
          `/loans/applications/${params.id}`,
          data,
          {
            signal: controller.signal,
          }
        );

        toast.success(response?.data?.messages);
        progress.finish();
        setDisabled(false);
      } catch (error) {
        if (!error?.response) {
          toast.error("No server response");
          progress.finish();
          setDisabled(false);
        } else {
          console.log(error?.response);
          progress.finish();
          setDisabled(false);
          if (error?.response?.status === 401) {
            navigate("/", { state: { from: location }, replace: true });
          }
          toast.error(error?.response?.data?.messages, {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
      }
    }
  };
  const writeOffLoan = async () => {
    const data = {
      writeoff: "yes",
    };
    if (window.confirm("Are you sure, you want to Write Off this Loan")) {
      try {
        progress.start();
        setDisabled(true);
        const controller = new AbortController();
        const response = await axiosPrivate.patch(
          `/loans/applications/${params.id}`,
          data,
          {
            signal: controller.signal,
          }
        );

        toast.success(response?.data?.messages);
        progress.finish();
        setDisabled(false);
      } catch (error) {
        if (!error?.response) {
          toast.error("No server response");
          progress.finish();
          setDisabled(false);
        } else {
          console.log(error?.response);
          progress.finish();
          setDisabled(false);
          if (error?.response?.status === 401) {
            navigate("/", { state: { from: location }, replace: true });
          }
          toast.error(error?.response?.data?.messages, {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
      }
    }
  };
  const [rolloverModal, setRolloverModal] = useState(false);
  const handleOpenRolloverModal = () => setRolloverModal(true);
  const handleCloseRolloverModal = () => setRolloverModal(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const handleRescheduleOpenModal = () => setRescheduleModal(true);
  const handleRescheduleCloseModal = () => setRescheduleModal(false);
  const [generalRepayment, setGeneralRepayment] = useState(false);
  const handleGeneralRepaymentOpenModal = () => setGeneralRepayment(true);
  const handleGeneralRepaymentCloseModal = () => setGeneralRepayment(false);
  const [data, setData] = useState([]);
  const getloanRepayment = async () => {
    try {
      const response = await axiosPrivate.get(
        `/loans/payments/schedule/${params.id}`,
        {
          signal: controller.signal,
        }
      );

      setData(response?.data?.data?.transactions);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getloanRepayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = () => {
    setIsLoading(true);
    getloanRepayment();
    getloan();
  };
  return (
    <>
      <Toaster />
      <section className="py-1 mt-2">
        <div className="card ">
          <div className="card-header col-md-12">
            <div className="d-flex align-items-between">
              <div className="col-md-2">
                <Link to={`/clients/individual/${loan?.account_id}`}>
                  <span className="d-block font-weight-bold">
                    {loan?.account_name}
                  </span>
                  <span className="d-block text-muted">
                    {loan?.account_number}
                  </span>
                  <span className="d-block text-muted">
                    {loan?.account_title}
                  </span>
                </Link>
              </div>
              <div className="col-md-10">
                {loan?.status === "pending" && roles?.includes(1000044) ? (
                  <Button
                    onClick={() => {
                      setAction("process");
                      handleOpenModal();
                    }}
                    className="btn btn-danger rounded-0 mr-1 btn-sm"
                  >
                    Process Loan
                  </Button>
                ) : loan?.status === "process" && roles?.includes(1000045) ? (
                  <Button
                    onClick={() => {
                      setAction("approve");
                      handleOpenModal();
                    }}
                    className="btn btn-info rounded-0 mr-1 btn-sm"
                  >
                    Approve Loan
                  </Button>
                ) : loan?.status === "approved" && roles?.includes(1000046) ? (
                  <Button
                    onClick={() => {
                      setAction("disburse");
                      handleOpenModal();
                    }}
                    className="btn btn-primary rounded-0 mr-1 btn-sm"
                  >
                    Disburse Loan
                  </Button>
                ) : (
                  ""
                )}

                {(loan?.status === "pending" ||
                  loan?.status === "process" ||
                  loan?.status === "approved") &&
                roles?.includes(1000047) ? (
                  <Button
                    onClick={() => {
                      setAction("cancel");
                      handleOpenModal();
                    }}
                    className="btn btn-danger rounded-0 mr-1 btn-sm"
                  >
                    Reject Loan
                  </Button>
                ) : (
                  ""
                )}
                {loan.status === "disbursed" ? (
                  <>
                    {roles?.includes(1000049) &&
                      (loan.waiver_status === "no" ? (
                        <Button
                          onClick={waiverOffLoan}
                          className="btn btn-warning rounded-0 mr-1 btn-sm"
                          disabled={disabled}
                        >
                          {!disabled && "Waiver Loan"}
                          {disabled && "Please Wait ....."}
                        </Button>
                      ) : (
                        ""
                      ))}
                    {roles?.includes(1000048) &&
                      (loan.write_off_status === "no" ? (
                        <Button
                          onClick={writeOffLoan}
                          className="btn btn-danger rounded-0 mr-1 btn-sm"
                          disabled={disabled}
                        >
                          {!disabled && "Write Off Loan"}
                          {disabled && "Please Wait ....."}
                        </Button>
                      ) : (
                        ""
                      ))}

                    {roles?.includes(1000051) && (
                      <Button
                        onClick={handleOpenRolloverModal}
                        className="btn btn-warning rounded-0 mr-1 btn-sm"
                      >
                        RollOver Loan
                      </Button>
                    )}
                    <RollOverModal
                      showModal={rolloverModal}
                      handleCloseModal={handleCloseRolloverModal}
                      getloan={getloan}
                    />
                    {roles?.includes(1000050) && (
                      <Button
                        onClick={handleRescheduleOpenModal}
                        className="btn btn-success rounded-0 pt-1 mr-1 btn-sm"
                      >
                        Reschedule Loan
                      </Button>
                    )}
                  </>
                ) : (
                  ""
                )}
                <RescheduleModal
                  showModal={rescheduleModal}
                  handleCloseModal={handleRescheduleCloseModal}
                  getloan={getloan}
                />
                <ActionModalProcess
                  showModal={openModal}
                  handleCloseModal={handleCloseModal}
                  action={action}
                  getloan={getloan}
                  loanDefaults={loan}
                />
                <Button
                  onClick={handleGeneralRepaymentOpenModal}
                  className="btn btn-info rounded-0 pt-1 mr-1 btn-sm"
                >
                  Pay Loan
                </Button>
                <Button
                  onClick={() => alert("This Loan Cannot be settled beacuse it has pending payments or its already settled")}
                  className="btn btn-danger rounded-0 pt-1 mt-1 mr-1 btn-sm"
                >
                  Settle Loan
                </Button>
                
                <LoanRepaymentGeneralModal
                  getLoan={getloan}
                  showModal={generalRepayment}
                  handleCloseModal={handleGeneralRepaymentCloseModal}
                  getloanRepayment={getloanRepayment}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active text-dark text-sm"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Loan Summary
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="schedule-tab"
                  data-toggle="tab"
                  href="#schedule"
                  role="tab"
                  aria-controls="schedule"
                  aria-selected="false"
                >
                  Loan Schedule Details
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Repayment Details
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#contact"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Penalty Breakdowns
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="extra-tab"
                  data-toggle="tab"
                  href="#extra"
                  role="tab"
                  aria-controls="extra"
                  aria-selected="false"
                >
                  Extra Charges
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        <div className="row">
                          <div className="col-8 float-left">About Loan</div>

                          <div className="col-2 float-left">
                            {loan?.status === "pending" ||
                            loan?.status === "process" ? (
                              <button
                                className="btn btn-outline-danger btn-sm float-left"
                                type="button"
                                onClick={handleOpenUpdateModal}
                              >
                                Update
                              </button>
                            ) : (
                              ""
                            )}
                            <LoanEdit
                              showModal={loanUpdateModal}
                              handleCloseModal={handleCloseUpdateModal}
                              getloan={getloan}
                              data={loan}
                            />
                          </div>
                          <div className="col-2 float-right">
                            <button
                              className="btn btn-success btn-sm"
                              type="button"
                              onClick={handleOpenAmortizationModal}
                            >
                              Amortization
                            </button>
                            <Amortization
                              showModal={loanAmortizationModal}
                              handleCloseModal={handleCloseAmortizationModal}
                              loanProductData={{
                                product: loan?.loan_product,
                                status: loan?.status,
                              }}
                              loan={loan}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-body card-text row">
                        <div className="col-md-4">
                          <div className="h5"> Loan Details</div>
                          <div className="row-container py-2 px-0 d-flex align-items-center justify-content-between bio-info">
                            <div>Loan Product</div>
                            <div>{loan?.loan_product}</div>
                          </div>
                          <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                            <div>Application Amount</div>
                            <div>{loan?.amount?.toLocaleString()}</div>
                          </div>
                          {loan?.disburse_status === "disburse" && (
                            <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                              <div>Repayment Amount</div>
                              <div>
                                {loan?.disbursed_repayment_amount?.toLocaleString()}
                              </div>
                            </div>
                          )}
                          {loan?.disburse_status === "disburse" && (
                            <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                              <div>Paid Amount</div>
                              <div>
                                {loan?.disbursed_paid_amount?.toLocaleString()}
                              </div>
                            </div>
                          )}
                          {loan?.disburse_status === "disburse" && (
                            <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                              <div>Loan Balance</div>
                              <div>
                                {(loan?.disbursed_repayment_amount - loan?.disbursed_paid_amount)?.toLocaleString()}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          {loan?.disburse_status === "disburse" && (
                            <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                              <div>Disbursed Date</div>
                              <div>{loan?.disbursed_on?.toLocaleString()}</div>
                            </div>
                          )}
                          {loan?.disburse_status !== "disburse" && (
                            <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                              <div>Date Of Application</div>
                              <div>{loan?.date}</div>
                            </div>
                          )}
                          <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                            <div>Tenure Period</div>
                            <div>{loan?.period}</div>
                          </div>
                          {loan?.disburse_status === "disburse" && (
                            <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                              <div>Grace Period</div>
                              <div>{loan?.grace} day(s)</div>
                            </div>
                          )}
                          <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                            <div>Amortization Interval</div>
                            <div>{loan?.loan_product_interval}</div>
                          </div>
                          <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                            <div>Loan Status</div>
                            <div>
                              {loan?.status === "pending" ? (
                                <div className="badge badge-warning p-1 text-white">
                                  Pending
                                </div>
                              ) : loan?.status === "approved" ? (
                                <div className="badge badge-info p-1 text-white">
                                  Approved
                                </div>
                              ) : loan?.status === "disbursed" ? (
                                <div className="badge badge-success p-1 text-white">
                                  Disbursed
                                </div>
                              ) : loan?.status === "process" ? (
                                <div className="badge badge-secondary p-1 text-white">
                                  Processing
                                </div>
                              ) : loan?.status === "rejected" ? (
                                <div className="badge badge-danger p-1 text-white">
                                  Rejected
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {loan?.disburse_status !== "disburse" && (
                            <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                              <div>Reason For Loan Application</div>
                              <div>{loan?.notes}</div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="h5"> Loan Disbursement Details</div>
                          {loan?.disburse_status === "disburse" ? (
                            <>
                              <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                <div>Disbursement Interest</div>
                                <div>{loan?.disurbe_settings?.interest}</div>
                              </div>
                              <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                <div>Disbursement Amount</div>
                                <div className="text-bold">
                                  {" "}
                                  {loan?.disurbe_settings?.amount}
                                </div>
                              </div>
                              <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                <div>Disurbement Period</div>
                                <div> {loan?.disurbe_settings?.period}</div>
                              </div>
                              <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                <div>Disurbement Timestamp</div>
                                <div> {loan?.disurbe_settings?.timestamp}</div>
                              </div>
                            </>
                          ) : (
                            <div className="text-info">Loan Not Disurbed</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 pt-2">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Colletral
                        <button
                          onClick={handleOpenColletralModal}
                          className="btn btn-outline-secondary rounded-0 btn-sm float-right"
                        >
                          Add Colletral
                        </button>
                      </div>
                      <div className="card-body p-0">
                        <LoanColletral
                          data={colletral}
                          showModal={loanColletralModal}
                          handleClose={handleCloseColletralModal}
                          getloan={getloan}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-2">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Guarantors
                        <button
                          onClick={handleOpenGuarantorModal}
                          className="btn btn-outline-secondary rounded-0 btn-sm float-right"
                        >
                          Add Guarantor
                        </button>
                      </div>
                      <div className="card-body p-0">
                        <LoanGuarantor
                          data={guarantors}
                          showModal={loanGuarantorModal}
                          handleClose={handleCloseGuarantorModal}
                          getloan={getloan}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-2">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Documents
                        <button
                          onClick={handleOpenDocuments}
                          className="btn btn-outline-secondary rounded-0 btn-sm float-right"
                        >
                          Add Documents
                        </button>
                      </div>
                      <div className="card-body p-0">
                        <LoanDocuments
                          showModal={loanDocumentModal}
                          handleClose={handleCloseDocuments}
                          data={documents}
                          getloan={getloan}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="schedule"
                role="tabpanel"
                aria-labelledby="schedule-tab"
              >
                <LoanSchedule
                  data={schedule}
                  getLoan={getloan}
                  getloanRepayment={getloanRepayment}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <RepaymentDetails
                  data={data}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <PenaltyBreakDowns isLoading={isLoading} refetch={refetch} />
              </div>
              <div
                className="tab-pane fade"
                id="extra"
                role="tabpanel"
                aria-labelledby="extra-tab"
              >
                <ExtraCharges />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoanApplicationSingle;
