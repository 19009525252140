import {MaterialReactTable} from "material-react-table";
import { useState, useEffect, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
const NextOfKin = ({ showModal, handleClose }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const controller = new AbortController();

  const getNextOfKin = async () => {
    try {
      const response = await axiosPrivate.get(
        `/clients/individual/kin/${params.id}/client`,
        {
          signal: controller.signal,
        }
      );

      setData(response?.data?.data?.next_of_kin);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getNextOfKin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstname", //access nested data with dot notation
        header: "First Name",
        size: 50,
      },
      {
        accessorKey: "lastname",
        header: "Last Name",
        size: 50,
      },
      {
        accessorKey: "relationship", //normal accessorKey
        header: "Relationship",
        size: 50,
      },
      {
        accessorKey: "inheritance",
        header: "Inheritance",
        size: 50,
      },
      {
        accessorKey: "contact",
        header: "Contact",
        size: 50,
      },
    ],
    []
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/clients/individual/kin",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getNextOfKin();
      handleClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <>
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Add Next Of Kin
            </Modal.Title>
            <button onClick={handleClose} className="btn-close btn-danger">
              <i className="material-icons opacity-10">close</i>
            </button>
          </Modal.Header>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <Modal.Body>
              <div className="container row">
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="firstname" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    placeholder="Enter Firstname *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("firstname", { required: true })}
                  />
                  {errors?.lastname?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter firstname
                    </p>
                  )}
                </div>
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="lastname" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Enter Lastname *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("lastname", { required: true })}
                  />
                  {errors?.lastname?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter lastname
                    </p>
                  )}
                </div>
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="identification" className="form-label">
                    Identification
                  </label>
                  <input
                    type="text"
                    name="identification"
                    placeholder="Enter Identification "
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("identification", { required: false })}
                  />
                  {errors?.identification?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter Identification
                    </p>
                  )}
                </div>
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="dateOfBirth" className="form-label">
                    Date Of Birth
                  </label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    placeholder="Enter Date Of Birth *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("dateOfBirth", { required: true })}
                  />
                  {errors?.dateOfBirth?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter Date Of Birth
                    </p>
                  )}
                </div>
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="contact" className="form-label">
                    Contact
                  </label>
                  <input
                    type="text"
                    name="contact"
                    placeholder="Enter Contact *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("contact", { required: true })}
                  />
                  {errors?.contact?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter contact
                    </p>
                  )}
                </div>
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter Email "
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("email", { required: false })}
                  />
                  {errors?.email?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter Email
                    </p>
                  )}
                </div>

                <div className="form-group col-md-4 input-container">
                  <label htmlFor="relationship" className="form-label">
                    Relationship
                  </label>
                  <input
                    type="text"
                    name="relationship"
                    placeholder="Enter Relationship *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("relationship", { required: true })}
                  />
                  {errors?.relationship?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter relationship
                    </p>
                  )}
                </div>
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="gender" className="form-label">
                    Gender
                  </label>
                  <select
                    name="gender"
                    className="form-control border-0 shadow form-control-lg input-text"
                    defaultValue=""
                    {...register("gender", { required: true })}
                  >
                    <option value="" disabled={true}>
                      select gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {errors?.gender?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please select gender
                    </p>
                  )}
                </div>
                <div className="form-group col-md-4 input-container">
                  <label htmlFor="inheritance" className="form-label">
                    Inheritance
                  </label>
                  <input
                    type="number"
                    name="inheritance"
                    placeholder="Enter Inheritance *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("inheritance", { required: true })}
                  />
                  {errors?.inheritance?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter inheritance
                    </p>
                  )}
                  <input
                    type="hidden"
                    name="clientid"
                    value={params.id}
                    {...register("clientid", { required: true })}
                  />
                </div>
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <textarea
                    name="address"
                    placeholder="Enter Address *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("address", { required: true })}
                  ></textarea>
                  {errors?.address?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter address
                    </p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={disabled}
                type="submit"
                className="btn btn-primary"
              >
                {!disabled && "Save"} {disabled && "Please Wait"}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
      <MaterialReactTable
        columns={columns}
        data={data}
        className="table align-items-center p-0"
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        enableGrouping
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
        initialState={{ density: "compact" }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
      />
    </>
  );
};

export default NextOfKin;
