import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const TransferFixedDepoists = ({ showModal, handleCloseModal, getFixed, modalData }) => {
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  // console.log(modalData);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.put(
        `/accounting/fixed/deposits/${modalData.id}`,
        data,
        {
          signal: controller.signal,
        }
      );

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      getFixed();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> Make Transfer </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-auto">
            <div className="form-group input-container px-0">
              <label htmlFor="status" className="form-label">
                Select Status
              </label>
              <select
                name="status"
                style={{height: "56px"}}
                className="form-control border-0  shadow form-control-lg input-text"
                {...register("status", {
                  required: true,
                })}
              >
                <option value="completed">Completed</option>
                <option value="terminated">Terminated</option>
              </select>
              {errors?.status?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please select status
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Save"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default TransferFixedDepoists;
