import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { Button } from "react-bootstrap";
import {MaterialReactTable} from "material-react-table";
import Loader  from "services/loaders/loader";
import FloatAddNew from "./components/add_float";
import ProgressBar from "@badrap/bar-of-progress";
import toast, {Toaster} from "react-hot-toast";
const TeamSingleBalancer = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
const progress = new ProgressBar({ size: 5, color: "#e91e63" });
const [disabled, setDisabled] = useState(false);
//   const [roles, setRoles] = useState([]);
//   useEffect(() => {
//     setRoles(JSON.parse(localStorage.roles));
//   }, []);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
//  const [deposits, setDeposits] = useState([]);
  const params = useParams();
  const controller = new AbortController();
  const getTeam = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounting/manage/teams/${params.id}`,
        {
          signal: controller.signal,
        }
      );
        // console.log(response?.data?.data?.data);
      setData(response?.data?.data?.data);
    //   setDeposits(response?.data?.data?.data?.deposit_transactions);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 const refetch = async () => {
   setIsLoading(true);
   await getTeam();
 };
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
    const columns = useMemo(
      () => [
        {
          id: "code", //access nested data with dot notation
          enableClickToCopy: true,
          header: "Transaction Code",
          accessorFn: (row) => row.code,
          size: 50,
        },
        {
          id: "amount", //access nested data with dot notation
          enableClickToCopy: true,
          header: "Amount",
          accessorFn: (row) => row?.amount?.toLocaleString(),
          size: 50,
        },
        {
          id: "status", //access nested data with dot notation
          enableClickToCopy: true,
          header: "Status",
          accessorFn: (row) =>
            row.status === "topup" ? (
              <div className="badge badge-success p-1">Top Up</div>
            ) : row.status === "reset" ? (
              <div className="badge badge-danger p-1">Reset</div>
            ) : (
              ""
            ),
          size: 50,
        },
        {
          id: "user", //access nested data with dot notation
          enableClickToCopy: true,
          header: "Loaded By",
          accessorFn: (row) => row.user,
          size: 50,
        },

        {
          id: "timestamp", //access nested data with dot notation
          enableClickToCopy: true,
          header: "Timestamp",
          accessorFn: (row) => row.timestamp,
          size: 50,
        },
      ],
      []
    );
    const onReset = async () => {
        if (window.confirm("Are you sure you want to reset the balance?")) {
      try {
        progress.start();
        setDisabled(true);
        const controller = new AbortController();
        const response = await axiosPrivate.delete(
          `/accounting/manage/teams/${params.id}`,
          {
            signal: controller.signal,
          }
        );
        toast.success(response?.data?.messages);
        progress.finish();
        setDisabled(false);
        refetch();
        handleCloseModal();
      } catch (error) {
        if (!error?.response) {
          console.log(error);
          toast.error("No server response");
          progress.finish();
          setDisabled(false);
        } else {
          console.log(error?.response);
          progress.finish();
          setDisabled(false);
          if (error?.response?.status === 401) {
            navigate("/", { state: { from: location }, replace: true });
          }
          toast.error(error?.response?.data?.messages, {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
      }
    }
    };
     const today = new Date();
     const options = { day: "numeric", month: "numeric", year: "numeric" };
     const formattedDate = today.toLocaleDateString("en-US", options);
      const columns_savings = useMemo(
        () => [
          {
            id: "account_number", //access nested data with dot notation
            enableClickToCopy: true,
            header: "A/C No.",
            size: 50,
            accessorFn: (row) => row.account_number,
          },
          {
            id: "account_name", //access nested data with dot notation
            enableClickToCopy: true,
            header: "Account Name",
            size: 50,
            accessorFn: (row) => row.account_name,
          },
          {
            id: "amount", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Amount",
            accessorFn: (row) => row?.amount?.toLocaleString(),
          },
          {
            id: "method", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Method",
            accessorFn: (row) => row.method,
          },
          {
            id: "acount", //access nested data with dot notation
            enableClickToCopy: true,
            header: "Account Type",
            size: 50,
            accessorFn: (row) => row.account,
          },
          {
            id: "timestamp", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Timestamp",
            accessorFn: (row) => row.timestamp,
          },
          {
            id: "status", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Status",
            accessorFn: (row) =>
              row.status === "success" ? (
                <div className="badge badge-success p-1 text-white">
                  Success
                </div>
              ) : row.status === "pending" ? (
                <div className="badge badge-info p-1 text-white">Pending</div>
              ) : row.status === "failed" ? (
                <div className="badge badge-danger p-1 text-white">Failed</div>
              ) : (
                ""
              ),
          },
          {
            id: "reverse_status", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Reverse Status",
            accessorFn: (row) =>
              row.reverse_status === "ongoing" ? (
                <div className="badge badge-success p-1 text-white">
                  Not Reversed
                </div>
              ) : row.reverse_status === "reversed" ? (
                <div className="badge badge-warning p-1 text-white">
                  Reversed
                </div>
              ) : (
                ""
              ),
          },
          {
            id: "code", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Transaction Code",
            accessorFn: (row) => row.code,
          },
      
        ],
        []
      );
      const columns_withdraws = useMemo(
        () => [
          {
            id: "account_number", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "A/C No.",
            accessorFn: (row) => row.account_number,
          },
          {
            id: "account_name", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Account Name",
            accessorFn: (row) => row.account_name,
          },
          {
            id: "amount", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Amount",
            accessorFn: (row) => row?.amount?.toLocaleString(),
          },
          {
            id: "method", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Method",
            accessorFn: (row) => row.method,
          },
          {
            id: "charge", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Charge",
            accessorFn: (row) => row?.charge?.toLocaleString(),
          },
          {
            id: "acount", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Account Type",
            accessorFn: (row) => row.account,
          },
          {
            id: "timestamp", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Timestamp",
            accessorFn: (row) => row.timestamp,
          },
          {
            id: "status", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Status",
            accessorFn: (row) =>
              row.status === "success" ? (
                <div className="badge badge-success p-1 text-white">
                  Success
                </div>
              ) : row.status === "pending" ? (
                <div className="badge badge-info p-1 text-white">Pending</div>
              ) : row.status === "failed" ? (
                <div className="badge badge-danger p-1 text-white">Failed</div>
              ) : (
                ""
              ),
          },
          {
            id: "reverse_status", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Reverse Status",
            accessorFn: (row) =>
              row.reverse_status === "ongoing" ? (
                <div className="badge badge-success p-1 text-white">
                  Not Reversed
                </div>
              ) : row.reverse_status === "reversed" ? (
                <div className="badge badge-warning p-1 text-white">
                  Reversed
                </div>
              ) : (
                ""
              ),
          },
          {
            id: "code", //access nested data with dot notation
            enableClickToCopy: true,
            size: 50,
            header: "Transaction Code",
            accessorFn: (row) => row.code,
          },
        ],
        []
      );
  return (
    <>
      <Toaster />
     
            <section className="py-1 mt-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card ">
                    <div className="card-body col-md-12">
                      <div className="d-flex align-items-between">
                        <div className="col-md-2">
                          {isLoading ? (
                            <Loader />
                          ) : (
                            <>
                              <span className="d-block font-weight-bold">
                                {data?.fullname}
                              </span>
                              <span className="d-block font-weight-light">
                                {data?.role_title}
                              </span>{" "}
                            </>
                          )}
                        </div>
                        <div className="col-md-10">
                          <Button
                            onClick={() => {
                              handleOpenModal();
                            }}
                            className="btn btn-danger rounded-0 mr-1 btn-sm"
                          >
                            Load Float
                          </Button>

                          <Button
                            onClick={onReset}
                            className="btn btn-info rounded-0 mr-1 btn-sm"
                            disabled={disabled}
                          >
                            {!disabled && "Reset Float"}{" "}
                            {disabled && "Please Wait ....."}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <FloatAddNew
                      showModal={openModal}
                      handleCloseModal={handleCloseModal}
                      reload={getTeam}
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-4 col-md-6 mb-2 block_savings">
                          <div className="card card-new border-0 shadow">
                            <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                <div className="col mr-0">
                                  <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                    Float Balance
                                  </div>
                                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    {isLoading ? (
                                      <Loader />
                                    ) : (
                                      data?.float_balance?.toLocaleString()
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6 mb-2 block_savings">
                          <div className="card card-new border-0 shadow">
                            <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                <div className="col mr-0">
                                  <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                    Today Savings
                                  </div>
                                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    {isLoading ? (
                                      <Loader />
                                    ) : (
                                      data?.deposit_transactions
                                        ?.filter(
                                          (transaction) =>
                                            new Date(
                                              transaction?.timestamp
                                            ).toLocaleDateString(
                                              "en-US",
                                              options
                                            ) === formattedDate &&
                                            transaction.reverse_status ===
                                              "ongoing"
                                        )
                                        ?.map(
                                          (transaction) => transaction?.amount
                                        )
                                        .reduce((a, b) => a + b, 0)
                                        ?.toLocaleString()
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6 mb-2 block_savings">
                          <div className="card card-new border-0 shadow">
                            <div className="card-body p-0">
                              <div className="row no-gutters align-items-center">
                                <div className="col mr-0">
                                  <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                    Today Withdraws
                                  </div>
                                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    {isLoading ? (
                                      <Loader />
                                    ) : (
                                      data?.withdraw_transactions
                                        ?.filter(
                                          (transaction) =>
                                            new Date(
                                              transaction?.timestamp
                                            ).toLocaleDateString(
                                              "en-US",
                                              options
                                            ) === formattedDate &&
                                            transaction.reverse_status ===
                                              "ongoing"
                                        )
                                        ?.map(
                                          (transaction) => transaction?.amount
                                        )
                                        .reduce((a, b) => a + b, 0)
                                        ?.toLocaleString()
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-none">
                      <div className="card-body p-0">
                        <div className="row mt-2">
                          <div className="col">
                            <MaterialReactTable
                              columns={columns}
                              data={data?.float_transactions ?? []}
                              className="table align-items-justify table-flush p-0 m-0"
                              enableColumnFilterModes
                              enableColumnOrdering
                              enableRowSelection
                              enableGrouping
                              enableStickyHeader
                              initialState={{ density: "compact" }}
                              state={{ isLoading: isLoading }}
                              muiTableContainerProps={{
                                sx: { maxHeight: "300px" },
                              }}
                              muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiTableBodyCellProps={{
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiSearchTextFieldProps={{
                                placeholder: "Search",
                                sx: { fontFamily: "Nunito", fontSize: "12px" },
                              }}
                              renderTopToolbarCustomActions={() => (
                                <>
                                  <div className="btn-group">
                                    <button className="btn btn-sm btn-danger rounded-0">
                                      <i className="fa fa-file-pdf"></i>
                                    </button>
                                    <button className="btn btn-sm btn-success rounded-0">
                                      <i className="fa fa-file-excel"></i>
                                    </button>
                                    <button className="btn btn-sm btn-info rounded-0">
                                      <i className="fa fa-print"></i>
                                    </button>
                                    <button
                                      onClick={refetch}
                                      className="btn btn-sm btn-secondary rounded-0"
                                    >
                                      <i className="fa fa-rotate-right"></i>
                                    </button>
                                  </div>
                                  <h5 className="float-end">
                                    {" "}
                                    Float Transactions{" "}
                                  </h5>
                                </>
                              )}
                            />
                          </div>
                          <div className="col pt-4">
                            <MaterialReactTable
                              columns={columns_savings}
                              data={data?.deposit_transactions ?? []}
                              className="table align-items-justify table-flush p-0 m-0"
                              enableColumnFilterModes
                              enableColumnOrdering
                              enableRowSelection
                              enableGrouping
                              enableStickyHeader
                              initialState={{ density: "compact" }}
                              state={{ isLoading: isLoading }}
                              muiTableContainerProps={{
                                sx: { maxHeight: "300px" },
                              }}
                              muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiTableBodyCellProps={{
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiSearchTextFieldProps={{
                                placeholder: "Search",
                                sx: { fontFamily: "Nunito", fontSize: "12px" },
                              }}
                              renderTopToolbarCustomActions={() => (
                                <>
                                  <div className="btn-group">
                                    <button className="btn btn-sm btn-danger rounded-0">
                                      <i className="fa fa-file-pdf"></i>
                                    </button>
                                    <button className="btn btn-sm btn-success rounded-0">
                                      <i className="fa fa-file-excel"></i>
                                    </button>
                                    <button className="btn btn-sm btn-info rounded-0">
                                      <i className="fa fa-print"></i>
                                    </button>
                                    <button
                                      onClick={refetch}
                                      className="btn btn-sm btn-secondary rounded-0"
                                    >
                                      <i className="fa fa-rotate-right"></i>
                                    </button>
                                  </div>
                                  <h5 className="float-end">
                                    {" "}
                                    Savings Transactions{" "}
                                  </h5>
                                </>
                              )}
                            />
                          </div>
                          <div className="col pt-4">
                            <MaterialReactTable
                              columns={columns_withdraws}
                              data={data?.withdraw_transactions ?? []}
                              className="table align-items-justify table-flush p-0 m-0"
                              enableColumnFilterModes
                              enableColumnOrdering
                              enableRowSelection
                              enableGrouping
                              enableStickyHeader
                              initialState={{ density: "compact" }}
                              state={{ isLoading: isLoading }}
                              muiTableContainerProps={{
                                sx: { maxHeight: "300px" },
                              }}
                              muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiTableBodyCellProps={{
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiSearchTextFieldProps={{
                                placeholder: "Search",
                                sx: { fontFamily: "Nunito", fontSize: "12px" },
                              }}
                              renderTopToolbarCustomActions={() => (
                                <>
                                  <div className="btn-group">
                                    <button className="btn btn-sm btn-danger rounded-0">
                                      <i className="fa fa-file-pdf"></i>
                                    </button>
                                    <button className="btn btn-sm btn-success rounded-0">
                                      <i className="fa fa-file-excel"></i>
                                    </button>
                                    <button className="btn btn-sm btn-info rounded-0">
                                      <i className="fa fa-print"></i>
                                    </button>
                                    <button
                                      onClick={refetch}
                                      className="btn btn-sm btn-secondary rounded-0"
                                    >
                                      <i className="fa fa-rotate-right"></i>
                                    </button>
                                  </div>
                                  <h5 className="float-end">
                                    {" "}
                                    Withdraw Transactions{" "}
                                  </h5>
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        
    </>
  );
};

export default TeamSingleBalancer;
