import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const AddVendor = ({ showModal, handleCloseModal, getVendors }) => {
  const [disabled, setDisabled] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/accounting/vendors/account",
        data,
        {
          signal: controller.signal,
        }
      );

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getVendors();
      handleCloseModal();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> New Vendor </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-6 input-container">
              <label htmlFor="firstname" className="form-label">
                Firstname
              </label>
              <input
                type="text"
                name="firstname"
                placeholder="Firstname  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("firstname", {
                  required: true,
                })}
              />
              {errors?.firstname?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter firstname
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="lastname" className="form-label">
                Lastname
              </label>
              <input
                type="text"
                name="lastname"
                placeholder="Lastname  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("lastname", {
                  required: true,
                })}
              />
              {errors?.lastname?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter lastname
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="contact" className="form-label">
                Contact
              </label>
              <input
                type="number"
                name="contact"
                placeholder="Contact  *"
                maxLength={10}
                className="form-control border-0 shadow form-control-md input-text"
                {...register("contact", {
                  required: true,
                })}
              />
              {errors?.contact?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter contact
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                name="address"
                placeholder="Address  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("address", {
                  required: true,
                })}
              />
              {errors?.address?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter address
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="company" className="form-label">
                Company
              </label>
              <input
                type="text"
                name="company"
                placeholder="Company  "
                className="form-control border-0 shadow form-control-md input-text"
                {...register("company", {
                  required: true,
                })}
              />
              {errors?.company?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter company
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("email", {
                  pattern: { value: /^\S+@\S+$/i },
                })}
              />
              {errors?.email?.type === "pattern" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter valid email
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Save"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddVendor;
