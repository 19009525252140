import React, { useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "services/loaders/loader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

const LoansDashBoard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [loans, setLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const controller = new AbortController();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );
  const labels = ["January", "February", "March", "April", "May"];
  const data = {
    labels,
    datasets: [
      {
        label: "Disbursed Loans",
        data: loans
          ?.filter((loan) => loan.disburse_status === "disburse")
          .map((loan) => loan?.amount),
        borderColor: "rgba(24,97,40,1)",
        backgroundColor: "rgba(24,97,40,1)",
        lineTension: 0.1,
      },
      {
        label: "Pending Loans",
        data: loans
          ?.filter((loan) => loan.status === "pending")
          .map((loan) => loan?.amount),
        borderColor: "rgba(152, 27, 40, 0.9)",
        backgroundColor: "rgba(152, 27, 40, 0.9)",
        lineTension: 0.1,
      },
    ],
  };
  const data_gender = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [
          loans?.filter((loan) => loan?.account_gender === "male").length,
          loans?.filter((loan) => loan?.account_gender === "female").length,
        ],
        backgroundColor: ["rgba(152, 27, 40, 0.9)", "rgba(225, 184, 34, 1)"],
        borderColor: ["rgba(152, 27, 40, 0.9)", "rgba(225, 184, 34, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const getLoans = async () => {
    try {
      const response = await axiosPrivate.get("/loans/applications", {
        signal: controller.signal,
      });
      setLoans(response.data.data.loans);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options_two = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
        text: "",
      },
    },
  };
  const options = {};

  const labels_demo = [
    "Business Loan",
    "Emergency Loan",
    "Agriculture Loan",
    "School Fees Loan",
    "Medical Loan",
  ];

  const data_demo = {
    labels: labels_demo,
    datasets: [
      {
        label: "Loan Products",
        data: [19000, 234000, 390000, 240000, 560000],
        borderColor: "rgb(21, 87, 36)",
        backgroundColor: "rgba(21, 87, 36, 0.5)",
      },
    ],
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new shadow h-100 py-2 border-left-success border border-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                    Active loans
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {loans
                          ?.filter(
                            (transaction) =>
                              transaction.disburse_status === "disburse"
                          )
                          ?.map((transaction) => transaction?.amount)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new shadow h-100 py-2 border-left-success border border-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                    Over due loans{" "}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {loans
                          ?.filter(
                            (transaction) =>
                              transaction.disbursed_active_status === "overdue"
                          )
                          ?.map((transaction) => transaction?.amount)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-info  bg-hover-info bg-gradient-info card-new border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                    Pending Applications
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-white">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {loans
                          ?.filter(
                            (transaction) => transaction.status === "pending"
                          )
                          ?.map((transaction) => transaction?.amount)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4 block_deposits rounded-0">
          <div className="card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border border-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                    Written Off Loans
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-white">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {loans
                          ?.filter(
                            (transaction) =>
                              transaction.write_off_status === "yes"
                          )
                          ?.map((transaction) => transaction?.amount)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-0 mb-lg-0">
          <div className="card">
            <div className="card-header">
              <h2 className="h6 text-uppercase mb-0">
                DISBURSEMENT vs applications
              </h2>
            </div>
            <div className="card-body">
              {/* <p className="text-gray">
                Monthly trend between Savings and Withdraws
              </p> */}
              <div className="chart-holder">
                {isLoading ? (
                  <Loader />
                ) : (
                  <Line options={options} data={data} className="px-0" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 mb-lg-0 pl-lg-0">
          <div className="card mb-1">
            <div className="card-header">
              <h2 className="h6 text-uppercase mb-0">Borrowers by Gender</h2>
            </div>
            <div className="card-body">
              {isLoading ? (
                <Loader />
              ) : (
                <Doughnut data={data_gender} className="px-0" />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 mb-lg-0 pl-lg-0">
          <div className="card mb-3">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-2 small">
                    Waived Off Loans
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        UGX.{" "}
                        {loans
                          ?.filter(
                            (transaction) => transaction.waiver_status === "yes"
                          )
                          ?.map((transaction) => transaction?.amount)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-danger text-uppercase mb-2 small">
                    Loan Penalties
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    <span>0</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-2 small">
                    Due Loans
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <span>
                        {" "}
                        {
                          loans?.filter(
                            (transaction) => transaction.status === "overdue"
                          )?.length
                        }
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-xl-3 col-md-12">
          <div className="card border rounded-0 border-success border-3">
            <div className="card-header ">
              <div className="font-weight-bold text-success">
                Active Loans <br />
                {isLoading ? (
                  <Loader />
                ) : (
                  <span className="text-bold h1">
                    {" "}
                    {
                      loans?.filter(
                        (transaction) =>
                          transaction.disburse_status === "disburse"
                      )?.length
                    }{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-12 mb-0">
          <div className="card border border-danger rounded-0 border-3">
            <div className="card-header ">
              <div className="font-weight-bold text-danger">
                Over Due Loans <br />
                {isLoading ? (
                  <Loader />
                ) : (
                  <span className="text-bold h1">
                    {" "}
                    {
                      loans?.filter(
                        (transaction) => transaction.status === "overdue"
                      )?.length
                    }{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-12 mb-0">
          <div className="card border border-info rounded-0 border-3">
            <div className="card-header ">
              <div className="font-weight-bold text-info">
                Pending Applications <br />
                {isLoading ? (
                  <Loader />
                ) : (
                  <span className="text-bold h1">
                    {" "}
                    {
                      loans?.filter(
                        (transaction) => transaction.status === "pending"
                      )?.length
                    }{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-12 mb-0">
          <div className="card border border-warning rounded-0 border-3">
            <div className="card-header ">
              <div className="font-weight-bold text-warning">
                Waived Off Loans <br />
                {isLoading ? (
                  <Loader />
                ) : (
                  <span className="text-bold h1">
                    {" "}
                    {
                      loans?.filter(
                        (transaction) => transaction.waiver_status === "yes"
                      )?.length
                    }{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="card mb-5 mb-lg-0">
            <div className="card-header">
              <h2 className="h6 mb-0 text-uppercase">
                ACTIVE LOANS BY PRODUCT
              </h2>
            </div>
            <div className="card-body">
              {isLoading ? (
                <Loader />
              ) : (
                <Bar options={options_two} data={data_demo} />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-0">
            <div className="card-header">
              <h2 className="h6 text-uppercase">UP COMING DUE LOANS</h2>
            </div>
            <div className="card-body">
              <table className="table p-0 table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Loan Product</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td>
                        <Loader />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {loans?.slice(0, 5)?.map((transaction, index) => (
                      <tr key={index}>
                        <td>{transaction?.account_number}</td>
                        <td>{transaction?.account_name}</td>
                        <td>UGX. {transaction?.amount.toLocaleString()}</td>
                        <td>{transaction?.loan_product}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoansDashBoard;
