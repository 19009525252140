import {MaterialReactTable} from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
const AccountChargesGeneral = ({
  showAccountChargesModal,
  getAccountBalance,
  handleAccountModalClose,
}) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const controller = new AbortController();

  const getAccounts = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounts/attached/accounts/${params.id}`,
        {
          signal: controller.signal,
        }
      );
      setAccounts(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = accounts
    ?.filter((account) => account.type === "savings")
    ?.map((account) => ({
      value: `${account.id}`,
      label: `${account.title}`,
    }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    control: control2,
    formState: { errors: errors2 },
  } = useForm();

  const accountChargesColumns = useMemo(() => [
    {
      accessorKey: "account",
      size: 50, //access nested data with dot notation
      header: "Account Title",
    },
    {
      id: "code", //access nested data with dot notation
      header: "TxID",
      size: 50,
      accessorFn: (row) => row.code,
    },
    {
      id: "amount", //access nested data with dot notation
      header: "Amount",
      size: 50,
      accessorFn: (row) => row.amount.toLocaleString(),
    },
    {
      accessorKey: "notes", //access nested data with dot notation
      header: "Notes",
      size: 50,
    },
    {
      id: "status",
      size: 50, //access nested data with dot notation
      header: "Status",
      accessorFn: (row) =>
        row.status === "success" ? (
          <div className="badge badge-success p-1 text-white">Success</div>
        ) : row.status === "pending" ? (
          <div className="badge badge-info p-1 text-white">Pending</div>
        ) : row.status === "failed" ? (
          <div className="badge badge-danger p-1 text-white">Failed</div>
        ) : (
          ""
        ),
    },

    {
      accessorKey: "timestamp",
      size: 50, //access nested data with dot notation
      header: "Date",
    },
    {
      id: "id", //access nested data with dot notation
      enableClickToCopy: false,
      header: "Action",
      size: 50,
      accessorFn: (row) => (
        <div className="float-left text-left text-sm">
          <button
            className="btn btn-danger text-white rounded-0 btn-sm p-1"
            aria-expanded="false"
          >
            Reverse
          </button>
        </div>
      ),
    },
  ],[]);

  const [accountCharges, setAccountCharges] = useState([]);
  const getAccountCharges = async () => {
    try {
      const response = await axiosPrivate.get(`/account/charges/${params.id}/clients`, {
        signal: controller.signal,
      });
      setAccountCharges(response.data.data.charges);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAccountCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [account, setAccount] = useState([]);
  const getAccount = async () => {
    try {
      const response = await axiosPrivate.get(`/settings/accounts/account`, {
        signal: controller.signal,
      });
      setAccount(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options1 = account
    ?.filter((account) => account.type === "incomes")
    ?.map((account) => ({
      value: `${account.id}`,
      label: `${account.title}`,
    }));
  const onAccountCharge = async (data) => {
    var accountid = data.accountid.value;
    delete data.accountid;
    data.accountid = accountid;
    var client_account = data.client_account.value;
    delete data.client_account;
    data.client_account = client_account;
    console.log(data)
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("/account/charges", data, {
        signal: controller.signal,
      });
      await getAccountCharges();
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getAccountBalance();
      reset2();
      handleAccountModalClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  return (
    <>
      <Toaster />

          <div className="card">
            <div className="card-header">Account Charges</div>
            <div className="card-body p-0">
              <MaterialReactTable
                columns={accountChargesColumns}
                data={accountCharges}
                className="table align-items-center p-0"
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                enableGrouping
                enableStickyHeader
                initialState={{ density: "compact" }}
                muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
              />
            </div>
          </div>

      <Modal
        show={showAccountChargesModal}
        backdrop="static"
        keyboard={false}
        onHide={handleAccountModalClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            A New Charge
          </Modal.Title>
          <button
            onClick={handleAccountModalClose}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form
          noValidate
          onSubmit={handleSubmit2(onAccountCharge)}
          autoComplete="off"
        >
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-6 input-container">
                <label htmlFor="client_account" className="form-label">
                  Select Account To Charge
                </label>
                <Controller
                  control={control2}
                  name="client_account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register2("client_account", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Accounts To Charge * "
                      styles={customStyles}
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={false}
                      isClearable={true}
                      ref={ref}
                    />
                  )}
                />
                {errors2?.account?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please select Account
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="accountid" className="form-label">
                  Select The Income Account
                </label>
                <Controller
                  control={control2}
                  name="accountid"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register2("accountid", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Income Account * "
                      styles={customStyles}
                      options={options1}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={false}
                      isClearable={true}
                      ref={ref}
                    />
                  )}
                />
                {errors2?.account?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please select Account
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="amount" className="form-label">
                  Enter Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  placeholder="Enter Amount *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  {...register2("amount", { required: true })}
                />

                {errors2?.amount?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Amount
                  </p>
                )}
              </div>

              <div className="form-group col-md-6 input-container">
                <label htmlFor="pincode" className="form-label">
                  Enter Pincode
                </label>
                <input
                  type="password"
                  name="pincode"
                  placeholder="Enter Pincode *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  autoComplete="new-password"
                  {...register2("pincode", { required: true })}
                />

                {errors2?.pincode?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Pincode
                  </p>
                )}
              </div>
              <div className="form-group col-md-12 input-container">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  name="description"
                  placeholder="Enter Description"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={3}
                  {...register2("description", { required: true })}
                ></textarea>
                {errors2?.description?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter description
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleAccountModalClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait ... "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AccountChargesGeneral;
