import {MaterialReactTable} from "material-react-table";
import { useState, useEffect, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
const GroupAttachee = ({ showModal, handleClose }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const controller = new AbortController();
  const getAttachees = async () => {
    try {
      const response = await axiosPrivate.get(
        `/clients/groups/${params.id}/attaches`,
        {
          signal: controller.signal,
        }
      );
      setData(response.data.data.attaches);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getAttachees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstname", //access nested data with dot notation
        header: "First Name",
      },
      {
        accessorKey: "lastname",
        header: "Last Name",
      },
      {
        accessorKey: "title", //normal accessorKey
        header: "Position",
      },
      {
        accessorKey: "identification",
        header: "Identification",
      },
      {
        accessorKey: "contact",
        header: "Contact",
      },
    ],
    []
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/clients/groups/attaches",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getAttachees();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <>
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Add Group Attachee
            </Modal.Title>
            <button onClick={handleClose} className="btn-close btn-danger">
              <i className="material-icons opacity-10">close</i>
            </button>
          </Modal.Header>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <Modal.Body>
              <div className="container row">
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter Title *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("title", { required: true })}
                  />
                  {errors?.title?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter title
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="firstname" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    placeholder="Enter Firstname *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("firstname", { required: true })}
                  />
                  {errors?.firstname?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter firstname
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="lastname" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Enter Lastname *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("lastname", { required: true })}
                  />
                  {errors?.lastname?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter lastname
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="identification" className="form-label">
                    Identification
                  </label>
                  <input
                    type="text"
                    name="identification"
                    placeholder="Enter Identification "
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("identification", { required: false })}
                  />
                  {errors?.identification?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter Identification
                    </p>
                  )}
                </div>

                <div className="form-group col-md-6 input-container">
                  <label htmlFor="contact" className="form-label">
                    Contact
                  </label>
                  <input
                    type="text"
                    name="contact"
                    placeholder="Enter Contact *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("contact", { required: true })}
                  />
                  {errors?.contact?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter contact
                    </p>
                  )}
                </div>
                <input
                  type="hidden"
                  {...register("clientid", { required: true })}
                  value={params.id}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={disabled}
                type="submit"
                className="btn btn-primary"
              >
                {!disabled && "Save"} {disabled && "Please Wait"}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
      <MaterialReactTable
        columns={columns}
        data={data}
        className="table align-items-center p-0"
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        enableGrouping
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
      />
    </>
  );
};

export default GroupAttachee;
