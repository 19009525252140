import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Select from "react-select";
const WithdrawSettingsModal = ({ show, handleClose, getSettings }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const controller = new AbortController();
  const getAccounts = async () => {
    try {
      const response = await axiosPrivate.get("settings/accounts/account", {
        signal: controller.signal,
      });
      setAccounts(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = accounts?.map((account) => ({
    value: account.id,
    label: account.title,
  }));
  const onSave = async (data) => {
    const newData = {
      amount: data.amount,
      max_value: data.max_value,
      min_value: data.min_value,
      type: data.type,
      accountid: data.accountid.value,
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "settings/withdraws/teller",
        newData,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getSettings();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "45px", // adjust the height as needed
  }),
};
  return (
    <>
      <Toaster />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Add withdraw settings</Modal.Title>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="mb-2 col-md-12">
                <label className="text-primary" htmlFor="">
                  Select account
                </label>
                <Controller
                  control={control}
                  name="accountid"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("accountid", { required: true })}
                      options={options}
                      onChange={onChange}
                      isMulti={false}
                      placeholder ="Select Account *"
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      className="border-0 shadow"
                      styles={customStyles}
                      ref={ref}
                    />
                  )}
                />
                {errors?.accountid?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    Please select preferd penalty account
                  </p>
                )}
              </div>

              <div className="mb-2 col-md-6">
                <label className="text-primary" htmlFor="minvalue">
                  From(amount)
                </label>
                <input
                  {...register("min_value", { required: true })}
                  className="form-control border-0 shadow form-control-md input-text"
                  type="number"
                  placeholder ="Enter minimum amount"
                />
                {errors?.min_value?.type === "required" && (
                  <span className="text-danger">
                    This field is required please!
                  </span>
                )}
              </div>
              <div className="mb-2 col-md-6">
                <label className="text-primary" htmlFor="maxvalue">
                  To(amount)
                </label>
                <input
                  {...register("max_value", { required: true })}
                  className="form-control border-0 shadow form-control-md input-text"
                  type="number"
                  placeholder ="Enter maximum amount"
                />
                {errors?.max_value?.type === "required" && (
                  <span className="text-danger">
                    This field is required please!
                  </span>
                )}
              </div>
              <div className="mb-2 col-md-6">
                <label className="text-primary" htmlFor="charges">
                  Charges
                </label>
                <input
                  {...register("amount", { required: true })}
                  className="form-control border-0 shadow form-control-md input-text"
                  type="number"
                  placeholder ="Enter charges"
                />
                {errors?.amount?.type === "required" && (
                  <span className="text-danger">Please enter charges!</span>
                )}
              </div>

              <div className="mb-2 col-md-6">
                <label className="text-primary" htmlFor="type">
                  Mode of Deduction
                </label>
                <select
                  {...register("type", { required: true })}
                  className="form-control border-0 shadow form-control-lg input-text"
                  defaultValue=""
                >
                  <option value="" disabled>Select Mode of Deduction </option>
                  <option value="value">Value</option>
                  <option value="percentage">Percentage</option>
                </select>
                {errors?.type?.type === "required" && (
                  <span className="text-danger">Select Mode Of Deduction!</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-warning" onClick={handleClose}>
              Cancel
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-outline-primary"
            >
              {!disabled && "Save Settings"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default WithdrawSettingsModal;
