import { Button } from "react-bootstrap";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import EditClient from "./components/edit-group";
import ClientDocuments from "./components/client-documents";
import AddAccounts from "../add-account";
import Communication from "../communication";
import Transactions from "../transactions";
import LoansSummaries from "../loan-summaries";
import ReportsModals from "../reports-accounts";
import GroupAttachee from "./components/groupattach";
import GeneralReportsModals from "../general-reports-accounts";
import AccountChargesGeneral from "../account-charges";
import AccountSharesGeneral from "../account-shares";
import CompusloryTransfers from "../complusory-transfer";

const IndividualGroupSingle = () => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [client, setClient] = useState([]);
  const [clientdocs, setClientDocs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [status, setStatus] = useState(true);
  const handleChangeStatus = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.status === "active"
        ? JSON.stringify({ status: "inactive" })
        : JSON.stringify({ status: "active" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/individual/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("client status settings updated");
      setStatus(
        response?.data?.data?.client?.status === "active" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [blocked, setBlocked] = useState(true);
  const handleChangeBlocked = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.status === "overdue"
        ? JSON.stringify({ status: "active" })
        : JSON.stringify({ status: "overdue" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/individual/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("client blocked status updated");
      setStatus(
        response?.data?.data?.client?.status === "overdue" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [email, setEmail] = useState(true);
  const handleChangeEmail = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.email_setting === "yes"
        ? JSON.stringify({ emails: "no" })
        : JSON.stringify({ emails: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/individual/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("email settings changed successfully");
      setEmail(
        response?.data?.data?.client?.email_setting === "yes" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [sms, setSms] = useState(true);
  const handleChangeSms = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.sms_setting === "yes"
        ? JSON.stringify({ sms: "no" })
        : JSON.stringify({ sms: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/individual/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("sms settings changed successfully");
      setSms(
        response?.data?.data?.client?.sms_setting === "yes" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const controller = new AbortController();
  const getClient = async () => {
    try {
      const response = await axiosPrivate.get(`/clients/groups/${params.id}`, {
        signal: controller.signal,
      });

      setClient(response?.data?.data?.client);
      setClientDocs(response?.data?.data?.client.documents);

      setEmail(
        response?.data?.data?.client?.email_setting === "yes" ? true : false
      );

      setSms(
        response?.data?.data?.client?.sms_setting === "yes" ? true : false
      );

      setStatus(
        response?.data?.data?.client?.status === "active" ? true : false
      );

      setBlocked(
        response?.data?.data?.client?.status === "overdue" ? true : false
      );
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getAccounts = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounts/attached/app/accounts/${params.id}`,
        {
          signal: controller.signal,
        }
      );

      setAccounts(response?.data?.data?.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openNextModal, setOpenNextModal] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openSMSModal, setOpenSMSModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openSavingModal, setOpenSavingModal] = useState(false);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openAccountReportModal, setOpenAccountReportModal] = useState(false);
  const [openGeneralAccountReportModal, setOpenGeneralAccountReportModal] =
    useState(false);
  const [openAccountTransferModal, setOpenAccountTransferModal] =
    useState(false);

  const handleOpenNextModal = () => setOpenNextModal(true);
  const handleCloseNextModal = () => setOpenNextModal(false);
  const handleOpenDocumentModal = () => setOpenDocumentModal(true);
  const handleCloseDocumentModal = () => setOpenDocumentModal(false);
  const handleOpenAccountModal = () => setOpenAccountModal(true);
  const handleCloseAccountModal = () => setOpenAccountModal(false);
  const handleOpenSMSModal = () => setOpenSMSModal(true);
  const handleCloseSMSModal = () => setOpenSMSModal(false);
  const handleOpenEmailModal = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(false);
  const handleOpenSavingModal = () => setOpenSavingModal(true);
  const handleCloseSavingModal = () => setOpenSavingModal(false);
  const handleOpenWithdrawModal = () => setOpenWithdrawModal(true);
  const handleCloseWithdrawModal = () => setOpenWithdrawModal(false);
  const handleOpenAccountReportModal = () => setOpenAccountReportModal(true);
  const handleCloseAccountReportModal = () => setOpenAccountReportModal(false);
  // const handleOpenGeneralAccountReportModal = () =>
  //   setOpenGeneralAccountReportModal(true);
  const handleCloseGeneralAccountReportModal = () =>
    setOpenGeneralAccountReportModal(false);
  const [showAccountChargesModal, setShowAccountChargesModal] = useState(false);
  const handleAccountModalOpen = () => setShowAccountChargesModal(true);
  const handleAccountModalClose = () => setShowAccountChargesModal(false);
  const handleOpenAccountTransferModal = () =>
    setOpenAccountTransferModal(true);
  const handleCloseAccountTransferModal = () =>
    setOpenAccountTransferModal(false);
  return (
    <>
      <Toaster />

      <section className="py-1 mt-2">
        <div className="card">
          <div className="card-header  d-flex align-items-between">
            <div className="col-md-2">
              <span className="d-block font-weight-bold">{client?.title}</span>
              <span className="d-block text-muted">{client?.account}</span>
            </div>
            <div className="col-md-10 btn-group">
              {roles?.includes(1000014) && (
                <Button
                  onClick={handleOpenAccountReportModal}
                  className="btn btn-sm btn-outline-info rounded-0 text-small"
                >
                  Statement
                </Button>
              )}
              {roles?.includes(1000008) && (
                <Button
                  onClick={handleOpenEmailModal}
                  className="btn btn-sm btn-outline-primary rounded-0 text-small"
                >
                  Send Email
                </Button>
              )}
              {roles?.includes(1000009) && (
                <Button
                  onClick={handleOpenSMSModal}
                  className="btn btn-sm btn-outline-secondary rounded-0 text-small"
                >
                  Send SMS
                </Button>
              )}
              {roles?.includes(1000010) && (
                <Button
                  onClick={handleOpenSavingModal}
                  className="btn btn-sm btn-outline-success rounded-0 text-small"
                >
                  Saving
                </Button>
              )}
              {roles?.includes(1000011) && (
                <Button
                  onClick={handleOpenWithdrawModal}
                  className="btn btn-sm btn-outline-warning rounded-0 text-small"
                >
                  Withdraw
                </Button>
              )}
              {roles?.includes(1000014) && (
                <Button
                  onClick={handleAccountModalOpen}
                  className="btn btn-sm btn-outline-danger rounded-0 text-small"
                >
                  Account Charges
                </Button>
              )}
            </div>
          </div>
          <div className="card-body float-right">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active text-dark text-sm"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Summary
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Communication
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#contact"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Transactions
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="loans-tab"
                  data-toggle="tab"
                  href="#loan"
                  role="tab"
                  aria-controls="loan"
                  aria-selected="false"
                >
                  Loans
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="charges-tab"
                  data-toggle="tab"
                  href="#charges"
                  role="tab"
                  aria-controls="charges"
                  aria-selected="false"
                >
                  Account Charges
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark text-sm"
                  id="shares-tab"
                  data-toggle="tab"
                  href="#shares"
                  role="tab"
                  aria-controls="shares"
                  aria-selected="false"
                >
                  Shares
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row mt-2">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Group Data
                      </div>
                      <div className="card-body card-text">
                        <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                          <div>Phone Number</div>
                          <div>{client?.contact}</div>
                        </div>
                        <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                          <div>Date Of Registration</div>
                          <div>{client?.date_of_joining}</div>
                        </div>

                        <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                          <div>Address</div>
                          <div>{client?.address}</div>
                        </div>
                        <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                          <div>Email</div>
                          <div>{client?.email}</div>
                        </div>
                        <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                          <div>Notes</div>
                          <div>{client?.notes}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Accounts
                        {roles?.includes(1000005) && (
                          <div className="button-group">
                            <button
                              onClick={handleOpenAccountTransferModal}
                              className="btn btn-outline-danger rounded-0 btn-sm float-right"
                            >
                              Compulsory Transfers
                            </button>
                            <CompusloryTransfers
                              getAccounts={getAccounts}
                              showModal={openAccountTransferModal}
                              handleCloseModal={handleCloseAccountTransferModal}
                              accounts={accounts}
                            />

                            {/* <button className="btn btn-outline-info rounded-0 btn-sm float-right">
                                          Add Shares
                                        </button> */}
                            <button
                              onClick={handleOpenAccountModal}
                              className="btn btn-outline-secondary rounded-0 btn-sm float-right"
                            >
                              Add Account
                            </button>
                          </div>
                        )}
                        <AddAccounts
                          showModal={openAccountModal}
                          handleClose={handleCloseAccountModal}
                        />
                      </div>
                      <div className="card-body p-1">
                        <table
                          className="table table-bordered text-small"
                          // style={{ maxHeight: "100px" }}
                        >
                          <thead className="text-bold bg-dark text-white">
                            <tr>
                              <th>Account Name</th>
                              <th>Actual Balance</th>
                              <th>Available Balance</th>
                              <th>Compuslory Balance</th>
                              <th>Fixed Balance</th>
                              <th>Date Of Opening</th>
                            </tr>
                          </thead>
                          <tbody>
                            {accounts?.map((account) => (
                              <tr key={account?.id}>
                                <td>{account?.title}</td>
                                <td>
                                  {parseInt(account?.balance)?.toLocaleString()}
                                </td>
                                <td>
                                  {parseInt(
                                    account?.actual_balance
                                  )?.toLocaleString()}
                                </td>
                                <td>
                                  {parseInt(
                                    account?.frozen_balance
                                  )?.toLocaleString()}
                                </td>
                                <td>
                                  {parseInt(
                                    account?.fixed_amount_balance
                                  )?.toLocaleString()}
                                </td>
                                <td>{account?.created}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pt-2">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Settings
                      </div>
                      <div className="card-body card-text">
                        <h6 className="text-uppercase text-body text-xs font-weight-bolder">
                          Communication
                        </h6>
                        <ul className="list-group">
                          <li className="list-group-item border-0 px-0">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input ms-auto"
                                type="checkbox"
                                name="email"
                                onChange={handleChangeEmail}
                                checked={email}
                              />
                              <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">
                                Send Emails
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item border-0 px-0">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input ms-auto"
                                type="checkbox"
                                name="sms"
                                onChange={handleChangeSms}
                                checked={sms}
                              />
                              <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">
                                Send SMS
                              </label>
                            </div>
                          </li>
                        </ul>
                        <h6 className="text-uppercase text-body text-xs font-weight-bolder mt-4">
                          Status
                        </h6>
                        <ul className="list-group">
                          <li className="list-group-item border-0 px-0">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input ms-auto"
                                type="checkbox"
                                name="status"
                                onChange={handleChangeStatus}
                                checked={status}
                              />
                              <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">
                                InActive / Active
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item border-0 px-0">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input ms-auto"
                                type="checkbox"
                                name="blocked"
                                onChange={handleChangeBlocked}
                                checked={blocked}
                              />
                              <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">
                                Unblock / Block
                              </label>
                            </div>
                          </li>
                        </ul>
                        <ul className="list-group">
                          <li className="list-group-item border-0 px-0">
                            <div className="form-check form-switch ps-0">
                              {roles?.includes(1000004) && (
                                <button
                                  className="btn btn-outline-danger"
                                  type="button"
                                  onClick={handleOpen}
                                >
                                  Update Group
                                </button>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 pt-2">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Documents
                        {roles?.includes(1000007) && (
                          <button
                            onClick={handleOpenDocumentModal}
                            className="btn btn-outline-secondary rounded-0 btn-sm float-right"
                          >
                            Add Documents
                          </button>
                        )}
                      </div>
                      <div className="card-body p-0">
                        <ClientDocuments
                          className="p-0"
                          clientData={clientdocs}
                          showModal={openDocumentModal}
                          handleClose={handleCloseDocumentModal}
                          getClient={getClient}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-2">
                    <div className="card">
                      <div className="card-header text-dark text-bold">
                        Group Attachee
                        {roles?.includes(1000015) && (
                          <button
                            onClick={handleOpenNextModal}
                            className="btn btn-outline-secondary rounded-0 btn-sm float-right"
                          >
                            Add Attachee
                          </button>
                        )}
                      </div>
                      <div className="card-body p-0">
                        <GroupAttachee
                          className="p-0"
                          showModal={openNextModal}
                          handleClose={handleCloseNextModal}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <Communication
                  showSMSModal={openSMSModal}
                  handleSMSClose={handleCloseSMSModal}
                  showEmailModal={openEmailModal}
                  handleEmailClose={handleCloseEmailModal}
                />
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <Transactions
                  showSavingModal={openSavingModal}
                  handleSavingClose={handleCloseSavingModal}
                  showWithdrawModal={openWithdrawModal}
                  handleWithdrawClose={handleCloseWithdrawModal}
                  getAccountBalance={getAccounts}
                />
              </div>
              <div
                className="tab-pane fade"
                id="loan"
                role="tabpanel"
                aria-labelledby="loan-tab"
              >
                <LoansSummaries />
              </div>
              <div
                className="tab-pane fade"
                id="charges"
                role="tabpanel"
                aria-labelledby="charges-tab"
              >
                <AccountChargesGeneral
                  showAccountChargesModal={showAccountChargesModal}
                  handleAccountModalClose={handleAccountModalClose}
                  getAccountBalance={getAccounts}
                />
              </div>
              <div
                className="tab-pane fade"
                id="shares"
                role="tabpanel"
                aria-labelledby="shares-tab"
              >
                <AccountSharesGeneral
                  shares={client?.shares}
                  share_balance={client?.share_balance}
                  getData={getClient}
                  accounts={accounts}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {client && (
        <EditClient
          showModal={showModal}
          handleCloseModal={handleClose}
          clientData={client}
          getClient={getClient}
        />
      )}
      <ReportsModals
        handleAccountReport={handleCloseAccountReportModal}
        openAccountReport={openAccountReportModal}
      />
      <GeneralReportsModals
        handleAccountReport={handleCloseGeneralAccountReportModal}
        openAccountReport={openGeneralAccountReportModal}
      />
    </>
  );
};

export default IndividualGroupSingle;
