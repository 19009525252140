
import { Link } from "react-router-dom";

const SavingsReportGeneral = () => {

  return (
    <>
      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Savings Reports{" "}
                </h2>
              </div>
              <div className="card-body row ">
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">General Savings</div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/savings/general-savings"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">General Withdraws</div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/savings/general-withdraws"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">USSD Savings</div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/savings/savings-by-ussd"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      USSD Withdraws 
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/savings/withdraws-by-ussd"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SavingsReportGeneral;
