import {MaterialReactTable} from "material-react-table";
import BranchesModal from "pages/settings/modals/branches";
import { useMemo, useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const BranchManager = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [branches, setBranches] = useState([]);
  //fetch loan branches data
  const controller = new AbortController();
  const getBranches = async () => {
    try {
      const response = await axiosPrivate.get("settings/branches", {
        signal: controller.signal,
      });
      setBranches(response.data.data.branches);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const branches_ = branches?.map((branch) => ({
    name: branch.name,
    code: branch.code,
    address: branch.address,
    description: branch.description,
    action: <i className="fa fa-eye text-info"></i>,
  }));

  const branches_body = useMemo(
    () => [
      { header: "Branch", accessorKey: "name" },
      { header: "Code", accessorKey: "code" },
      { header: "Adress", accessorKey: "address" },
      { header: "Description", accessorKey: "description" },
      { header: "Action", accessorKey: "action" },
    ],
    []
  );

  return (
    <>
     
            <section className="py-2 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        branch management
                      </h2>
                      <BranchesModal getBranches={getBranches} />
                    </div>
                    <div className="card-body mt-1 mb-1 p-0">
                      <div className="col-md-12">
                        <p>Current Branches</p>
                        <MaterialReactTable
                          columns={branches_body}
                          data={branches_}
                          className="table align-items-center mb-0"
                          enableColumnFilterModes
                          enableColumnOrdering
                          enableRowSelection
                          enableGrouping
                          enableStickyHeader
                          muiTableContainerProps={{
                            sx: { maxHeight: "300px" },
                          }}
                          muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiSearchTextFieldProps={{
                            placeholder: "Search",
                            sx: { fontFamily: "Nunito", fontSize: "12px" },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
         
    </>
  );
};

export default BranchManager;
