import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="pagination">
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="btn btn-sm btn-link text-dark rounded-0">
        {currentPage}
      </span>
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};
const ShareBalancesReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [data, setData] = useState([]);
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);

  const onGetShares = async () => {
    try {
      progress.start();
      const controller = new AbortController();
      const response = await axiosPrivate.get(`/clients/general`, {
        signal: controller.signal,
      });
      setData(response.data.data.clients.filter(client=>client.shares!== 0));
      // console.log(response.data.data.clients);
      progress.finish();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        console.log(error?.response);
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  useEffect(() => {
    onGetShares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const total_balance = data
    ?.map((item) => item?.share_amount || 0)
    .reduce((a, b) => a + b, 0);
  const total_count = data
    ?.map((item) => item?.count || 0)
    .reduce((a, b) => a + b, 0);
  // console.log(total_balance);
  // const avaliable_balance = data?.account_balances
  //   ?.map((data) => data?.avaliable_balance)
  //   .reduce((a, b) => a + b, 0);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // function to export data as pdf
  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/share_balance/pdf`,

        { responseType: "blob" }
      );
      const filename = "share_balances_report.pdf";
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading share balances report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/share_balance/csv`,

        { responseType: "blob" }
      );

      const filename = `share balances_report.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading share balances report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };
  return (
    <>
      <Toaster />

      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header bg-dark text-white">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Share Balances Report{" "}
                </h2>
              </div>
              <div className="card-body mt-3 mb-3">
                <div className="mt-3">
                  <table className="table table-hover table-bordered text-medium-small">
                    <thead>
                      <tr className="bg-dark text-white text-medium-small">
                        <th>Client Name</th>
                        <th>Account Number</th>
                        <th>No. of Shares</th>
                        <th className="text-right">Share Balance</th>
                      </tr>
                    </thead>

                    <tbody className="text-medium-small">
                      {currentItems?.map((data) => (
                        <tr key={data?.id}>
                          <td>{data?.name}</td>
                          <td>{data?.account}</td>{" "}
                          <td>{data?.shares}</td>{" "}
                          <td className="text-right">{data?.share_balance}</td>
                        </tr>
                      ))}
                      <tr className="bg-dark text-white">
                        <th colSpan="2">Total</th>
                        <th className="text-left">
                          {total_count.toLocaleString()}
                        </th>
                        <th className="text-right">
                          {total_balance.toLocaleString()}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />

                  <div className="d-flex align-items-center mt-0">
                    <div className="ml-auto mb-0 mt-0">
                      <button
                        className="btn btn-primary text-right"
                        onClick={downloadUssdReportAsPDF}

                        // disabled={isGenerating || isPDFDownloading}
                      >
                        {isPDFDownloading
                          ? "Downloading please wait..."
                          : "Download PDF"}
                      </button>
                      <button
                        className="btn btn-secondary text-right"
                        onClick={downloadUssdReportAsCSV}
                        // disabled={isGenerating || isCSVDownloading}
                      >
                        {isCSVDownloading
                          ? "Downloading please wait..."
                          : "Download CSV"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShareBalancesReport;
