import { useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast, {Toaster} from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
const ChangeUserPassword = () => {
      const axiosPrivate = useAxiosPrivate();
      const navigate = useNavigate();
      const location = useLocation();
      const progress = new ProgressBar({ size: 5, color: "#e91e63" });
   const [disabled, setDisabled] = useState(false);

      const [data, setData] = useState([]);
        
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

   const {
     register: register1,
     handleSubmit: handleSubmit1,
     reset: reset1,
     formState: { errors1 },
   } = useForm();



        const controller = new AbortController();
        const getProfile = async () => {
          try {
            const response = await axiosPrivate.get("/accounts/profile", {
              signal: controller.signal,
            });
            setData(response.data.data.user);
          } catch (error) {
            // console.log(error)
            if (error?.response?.status === 401) {
              navigate("/", {
                state: { from: location },
                replace: true,
              });
            }
          }
        };
        useEffect(() => {
          getProfile();
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        const onSave = async(data) => {
          try {
            progress.start();
            setDisabled(true);
            const controller = new AbortController();
            const response = await axiosPrivate.post(
              "/accounts/password/update",
              data,
              {
                signal: controller.signal,
              }
            );
            toast.success(response?.data?.messages);
            progress.finish();
            setDisabled(false);
            reset();
          } catch (error) {
            if (!error?.response) {
              toast.error("No server response");
              progress.finish();
              setDisabled(false);
            } else {
              console.log(error?.response);
              progress.finish();
              setDisabled(false);
              if (error?.response?.status === 401) {
                navigate("/", { state: { from: location }, replace: true });
              }
              toast.error(error?.response?.data?.messages, {
                style: {
                  background: "#e91e63",
                  fontSize: "13px",
                  color: "#fff",
                },
              });
            }
          }
        }
      const onUpdatePin = async(data) => {
        try {
          progress.start();
          setDisabled(true);
          const controller = new AbortController();
          const response = await axiosPrivate.post(
            "/accounts/pincode/update",
            data,
            {
              signal: controller.signal,
            }
          );
          toast.success(response?.data?.messages);
          progress.finish();
          setDisabled(false);
          reset1();
        } catch (error) {
          if (!error?.response) {
            toast.error("No server response");
            progress.finish();
            setDisabled(false);
          } else {
            console.log(error?.response);
            progress.finish();
            setDisabled(false);
            if (error?.response?.status === 401) {
              navigate("/", { state: { from: location }, replace: true });
            }
            toast.error(error?.response?.data?.messages, {
              style: {
                background: "#e91e63",
                fontSize: "13px",
                color: "#fff",
              },
            });
          }
        }
        }
  return (
    <>
      <Toaster />
    
            <section className="mt-1">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Profile & Credentials{" "}
                      </h2>
                    </div>

                    <div className="card-body p-2">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active text-dark text-sm"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            Update Password
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-dark text-sm"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            Update Pin
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="row container pt-3">
                            <div className="col-6">
                              <div className="card">
                                <div className="card-header text-dark text-bold">
                                  Profile
                                </div>
                                <div className="card-body card-text">
                                  <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                    <div>Name</div>
                                    <div>
                                      {data?.firstname} {data?.lastname}
                                    </div>
                                  </div>
                                  <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                    <div>Contact</div>
                                    <div>{data?.contact}</div>
                                  </div>
                                  <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                    <div>Date Of Reg</div>
                                    <div>{data?.timestamp}</div>
                                  </div>

                                  <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                    <div>Status</div>
                                    <div>
                                      {data?.status === "active" ? (
                                        <div className="badge badge-success">
                                          Active
                                        </div>
                                      ) : (
                                        <div className="badge badge-danger">
                                          Inactive
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                    <div>Gender</div>
                                    <div>
                                      {data?.gender === "male"
                                        ? "Male"
                                        : "Female"}
                                    </div>
                                  </div>
                                  <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                                    <div>Email</div>
                                    <div>{data?.email}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="card">
                                <div className="card-header text-dark text-bold">
                                  Update Password
                                </div>
                                <div className="card-body card-text">
                                  <form
                                    className="justify-content-end"
                                    noValidate
                                    onSubmit={handleSubmit(onSave)}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="password"
                                        className="form-control border-0 shadow form-control-md"
                                        name="old_password"
                                        placeholder="Enter old password"
                                        autoComplete="off"
                                        {...register("old_password", {
                                          required: true,
                                        })}
                                      />
                                      {errors?.old_password?.type ===
                                        "required" && (
                                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                                          Please enter your old password
                                        </p>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="password"
                                        className="form-control border-0 shadow form-control-md"
                                        name="new_password"
                                        placeholder="Enter new password"
                                        autoComplete="off"
                                        {...register("new_password", {
                                          required: true,
                                        })}
                                      />
                                      {errors?.new_password?.type ===
                                        "required" && (
                                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                                          Please enter your new password
                                        </p>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="password"
                                        className="form-control border-0 shadow form-control-md"
                                        name="confirm_password"
                                        placeholder="Confirm password"
                                        autoComplete="off"
                                        {...register("confirm_password", {
                                          required: true,
                                        })}
                                      />
                                      {errors?.confirm_password?.type ===
                                        "required" && (
                                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                                          Please confirm password
                                        </p>
                                      )}
                                      {watch("confirm_password") !==
                                        watch("new_password") &&
                                      getValues("confirm_password") ? (
                                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                                          Password does not match
                                        </p>
                                      ) : null}
                                    </div>

                                    <button
                                      type="submit"
                                      className="btn btn-outline-danger rounded-0 float-right"
                                      disabled={disabled}
                                    >
                                      {!disabled && "Change Password"}{" "}
                                      {disabled && "Please Wait ....."}
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div className="row container pt-3">
                            <div className="col-6">
                              <div className="card">
                                <div className="card-header text-dark text-bold">
                                  Update Pin
                                </div>
                                <div className="card-body card-text">
                                  <form
                                    className="justify-content-start"
                                    noValidate
                                    onSubmit={handleSubmit1(onUpdatePin)}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="password"
                                        className="form-control border-0 shadow form-control-md"
                                        name="pincode"
                                        placeholder="Enter old pin"
                                        autoComplete="off"
                                        {...register1("pincode", {
                                          required: true,
                                        })}
                                      />{" "}
                                      {errors1?.pincode?.type ===
                                        "required" && (
                                        <p className="text-small m-0 px-1 text-start text-danger help-block">
                                          Please enter old pincode
                                        </p>
                                      )}
                                    </div>

                                    <button
                                      type="submit"
                                      className="btn btn-outline-danger rounded-0 float-right"
                                      disabled={disabled}
                                    >
                                      {!disabled && "Update Pin"}{" "}
                                      {disabled && "Please Wait ....."}
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        
    </>
  );
};

export default ChangeUserPassword;
