import { useState, useMemo } from "react";
import LoanRepaymentModal from "./loan-repayment-modal";
import Table from "pages/components/common_table";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const LoanSchedule = ({
  data,
  getLoan,
  getloanRepayment,
  isLoading,
  refetch,
}) => {
  // toast("hello")
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = (id) => {
    setShowModal(true);
    setId(id);
  };
  const params = useParams();

  const handleCloseModal = () => {
    setShowModal(false);
    setId("");
  };
  // console.log(id);

  const onRemovePenalty = async (scheduleid) => {
    const data = {
      loanid: params.id,
      scheduleid: scheduleid,
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/schedule/loans/penalty",
        data,
        {
          signal: controller.signal,
        }
      );

      progress.finish();
      setDisabled(false);

      console.log(response?.data);
      getLoan();
      toast.success(response?.data?.messages);
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        toast.error("This loan has no penalties");
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const onRemoveInterest = async (scheduleid) => {
    const data = {
      loanid: params.id,
      scheduleid: scheduleid,
    };
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/schedule/loans/interest",
        data,
        {
          signal: controller.signal,
        }
      );

      progress.finish();
      setDisabled(false);

      console.log(response?.data);
      getLoan();
      toast.success(response?.data?.messages);
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        toast.error("This loan has no penalties");
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const loansColumns = useMemo(
    () => [
      {
        accessorKey: "period",
        header: "Period",
        size: 50,
      },
      {
        accessorKey: "date",
        size: 50,
        header: "Date",
      },
      {
        id: "principal",
        header: "Principal",
        size: 50,
        accessorFn: (row) => row.principal.toLocaleString(),
      },
      {
        id: "principal_paid",
        header: "Principal Paid",
        size: 50,
        accessorFn: (row) => row.principal_paid.toLocaleString(),
      },
      {
        id: "interest",
        header: "Interest",
        size: 50,
        accessorFn: (row) => row.interest.toLocaleString(),
      },
      {
        id: "interest_paid",
        header: "Interest Paid",
        size: 50,
        accessorFn: (row) => row.interest_paid.toLocaleString(),
      },

      {
        id: "penalities",
        header: "Penalities",
        size: 50,
        accessorFn: (row) => row.penalities.toLocaleString(),
      },
      {
        id: "penalities_paid",
        header: "Penalities Paid",
        size: 50,
        accessorFn: (row) => row.penalities_paid.toLocaleString(),
      },
      {
        id: "amount_paid",
        header: "Amount Paid",
        size: 50,
        accessorFn: (row) => row.amount_paid.toLocaleString(),
      },
      {
        id: "remaining_balance",
        header: "Balance",
        size: 50,
        accessorFn: (row) =>
          (
            row.interest +
            row.principal +
            row.penalities -
            row.amount_paid
          ).toLocaleString(),
      },
      {
        id: "payment_status",
        size: 50,
        header: "Payment Status",
        accessorFn: (row) =>
          row.payment_status === "paid" ? (
            <div className="badge badge-success p-2">Paid</div>
          ) : row.payment_status === "partial" ? (
            <div className="badge badge-info p-2">Parital</div>
          ) : (
            <div className="badge badge-danger p-2">Not Paid</div>
          ),
      },
      {
        id: "activity_status",
        size: 50,
        header: "Activity Status",
        accessorFn: (row) =>
          row.activity_status === "due" ? (
            <div className="badge badge-info p-2">Due</div>
          ) : (
            <div className="badge badge-danger p-2">OverDue</div>
          ),
      },

      {
        id: "id",
        size: 50, //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        accessorFn: (row) => (
          <div className="float-left text-left text-sm">
            <button
              onClick={() => handleOpenModal(row.id)}
              className="btn btn-success text-white rounded-0 btn-sm p-1 mr-2"
            >
              Pay
            </button>
            <button
              onClick={() => onRemovePenalty(row.id)}
              disabled={disabled}
              className="btn btn-dark text-white rounded-0 btn-sm p-1"
            >
              <i className="fa fa-trash"></i> Penalty
            </button>
            <button 
              onClick={() => onRemoveInterest(row.id)}  
              disabled={disabled}
            className="btn btn-info text-white rounded-0 btn-sm p-1">
              <i className="fa fa-trash"></i> Interest
            </button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Toaster />
      <div className="row">
        <div className="col-12 p-2">
          <div className="card">
            <div className="card-header">Loan Schedule Details</div>
            <div className="card-body p-0">

              <Table
                columns={loansColumns}
                data={data}
                isLoading={isLoading}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      </div>
      <LoanRepaymentModal
        getLoan={getLoan}
        id={id}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getloanRepayment={getloanRepayment}
      />
    </>
  );
};

export default LoanSchedule;
