import TellerDashBoard from "./components/teller-dashboard";
import LoansDashBoard from "./components/loans-dashboard";
import ManagerDashboard from "./components/manager-dashboard";
import GreetingComponent from "./components/greeting";
import { useState, useEffect } from "react";
const Dashboard = () => {
  const user_info = JSON.parse(localStorage["user_data"]);
     const [roles, setRoles] = useState([]);
     useEffect(() => {
       setRoles(JSON.parse(localStorage.roles));
     }, []);
  return (
    <>
      <section className="row">
        <div className="col-xl-12 mb-2 mt-2">
          <div className="card bg-hover-gradient-success bg-hover-danger bg-gradient-info p-3">
            <div className="row">
              <p className="h5 col-xl-6 text-start">
                {" "}
                <GreetingComponent />,{" "}
                {`${user_info?.firstname} 
                ${user_info?.lastname}`}
              </p>
              <p className="h6 col-xl-6 text-end">
                You are signed as, {user_info?.role}
              </p>
            </div>
          </div>
        </div>
      </section>
      {roles?.includes(1000069) && (
        <section className="py-2">
          <TellerDashBoard />
        </section>
      )}
      {roles?.includes(1000070) && (
      <section className="py-2">
        <LoansDashBoard />
      </section>)}
      {roles?.includes(1000071) && (
      <section className="py-2">
        <ManagerDashboard />
      </section>
      )}
    </>
  );
};

export default Dashboard;
