import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="pagination">
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="btn btn-sm btn-link text-dark rounded-0">
        {currentPage}
      </span>
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

const CashBookReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [dates, setDates] = useState([]);
  // const [incomes, setIncomes] = useState([]);
  // const [deposits, setDeposits] = useState([]);
  // const [withdraws, setWithdraws] = useState([]);
  // const [expenses, setExpenses] = useState([]);
  const [transactions, setTransactions] = useState([]);
  // const [applicationAmount, setApplicationAmount] = useState([]);
  // const [shares, setShares] = useState([]);
  // const [accountCharges, setAccountCharges] = useState([]);
  // const [loanCharges, setLoanCharges] = useState([]);
  // const [accountTopUp, setAccountTopUp] = useState([]);
  // const [loanRepayment, setLoanRepayment] = useState([]);
  // const [withdrawCharges, setWithdrawCharges] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(`/reports/new/cashbook`, data, {
        signal: controller.signal,
      });
      setDates(response?.data?.data.dates);
      setTransactions(response?.data?.data?.transactions);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  // const datacredit = [...incomes, ...deposits];
  // const datadebit = [...expenses, ...withdraws];
  // const sumcredit = datacredit
  //   .filter((data) => data.type === "credit")
  //   .map((data) => data.amount)
  //   .reduce((a, b) => a + b, 0);
  // const sumdebit = datadebit
  //   .filter((data) => data.type === "debit")
  //   .map((data) => data.amount)
  //   .reduce((a, b) => a + b, 0);
  // //   console.log(sumcredit)
  // const sortedCreditData = datacredit.sort(
  //   (a, b) => new Date(a.date) - new Date(b.date)
  // );
  // const sortedDebitData = datadebit.sort(
  //   (a, b) => new Date(a.date) - new Date(b.date)
  // );
  //   console.log(data)

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(transactions.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // function to export data as pdf
  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.post(
        `/export/reports/cash_book/pdf`,
        {
          start_date: dates.start_date,
          end_date: dates.end_date,
        },

        { responseType: "blob" }
      );
      const filename = "Cash Book.pdf";
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading cash book report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.post(
        `/export/reports/cash_book/csv`,
        {
          start_date: dates.start_date,
          end_date: dates.end_date,
        },

        { responseType: "blob" }
      );

      const filename = `Cash Book.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading cash book report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };
  return (
    <>
      <Toaster />

      <div className="card mt-2">
        <div className="card-header bg-dark">
          <p className="h5 text-bold text-uppercase text-center text-white">
            Cash Book{" "}
          </p>
        </div>
        <div className="card-body mt-3 mb-3 text-medium-small">
          <div className="row">
            <div className="col-md-12 mb-3">
              <form
                className="row needs-validation"
                id="generalLegerForm"
                onSubmit={handleSubmit(onQuery)}
              >
                <div className="form-group col-md-3">
                  <label className="form-label">Select Start Date</label>
                  <input
                    type="date"
                    placeholder="Select Start Date"
                    className="form-control shadow border-1"
                    {...register("start_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.start_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label">Select End Date</label>
                  <input
                    type="date"
                    placeholder="Select End Date"
                    className="form-control shadow border-1"
                    {...register("end_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.end_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>

                <div className="form-group col-md-3 pt-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-center rounded-0"
                    disabled={disabled}
                  >
                    {!disabled && "Generate"} {disabled && "Please Wait ....."}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-3 text-medium-small">
            <table className="table table-hover table-bordered">
              <thead className="text-medium-small">
                <tr>
                  <th colSpan="9">
                    <b className="text-right">
                      From: {dates?.start_date} To: {dates?.end_date}
                    </b>
                  </th>
                </tr>
                <tr>
                  <th>Date</th>
                  <th>Account No.</th>
                  <th>Particulars</th>
                  <th>Reference No.</th>
                  <th>Cash Debit</th>
                  <th>Cash Credit</th>
                  <th>Cash Balance</th>
                  <th>Bank Debit</th>
                  <th>Bank Credit</th>
                  <th>Bank Balance</th>
                </tr>
              </thead>
              <tbody className="text-medium-small">
                {currentItems.map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      {new Date(transaction.date).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    <td>{transaction.account_no}</td>
                    <td>{transaction.particulars}</td>
                    <td>{transaction.reference_no}</td>
                    <td>{transaction.cash_debit.toLocaleString()}</td>
                    <td>{transaction.cash_credit.toLocaleString()}</td>
                    <td>{transaction.cash_balance.toLocaleString()}</td>
                    <td>{transaction.bank_debit.toLocaleString()}</td>
                    <td>{transaction.bank_credit.toLocaleString()}</td>
                    <td>{transaction.bank_balance.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
            <div className="d-flex align-items-center mt-0">
              <div className="ml-auto mb-0 mt-0">
                <button
                  className="btn btn-primary text-right"
                  onClick={downloadUssdReportAsPDF}
                  sumcredit
                  // disabled={isGenerating || isPDFDownloading}
                >
                  {isPDFDownloading
                    ? "Downloading please wait..."
                    : "Download PDF"}
                </button>
                <button
                  className="btn btn-secondary text-right"
                  onClick={downloadUssdReportAsCSV}
                  // disabled={isGenerating || isCSVDownloading}
                >
                  {isCSVDownloading
                    ? "Downloading please wait..."
                    : "Download CSV"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashBookReport;
