import { useState, useEffect } from "react";
import FixedModal from "pages/settings/modals/fixed-deposit";
import {MaterialReactTable} from "material-react-table";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const FixedDeposit = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [fixeddeposits, setFixeddeposits] = useState([]);
  const controller = new AbortController();
  const getFixedSettings = async () => {
    try {
      const response = await axiosPrivate.get("settings/fixed/settings", {
        signal: controller.signal,
      });

      setFixeddeposits(response.data.data.fixed_deposit_settings);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getFixedSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fixedSettings = fixeddeposits?.map((fixeddeposit) => ({
    title: fixeddeposit.title,
    deposit_account: fixeddeposit.deposit_account,
    interest: fixeddeposit.interest,
    cal_mode: fixeddeposit.calculation_mode,
    int_exp_account: fixeddeposit.interest_expense_account,
    account_payable_account: fixeddeposit.account_payable_account,
    interval: fixeddeposit.interval,
  }));

  const account_body = useMemo(
    () => [
      { header: "Title", accessorKey: "title" },
      { header: "Interest Rate", accessorKey: "interest" },
      { header: "Calculation Mode", accessorKey: "cal_mode" },
      { header: "Deposit Account", accessorKey: "deposit_account" },
      { header: "Interest Expense Account", accessorKey: "int_exp_account" },
      {
        header: "Interest Payable Account",
        accessorKey: "account_payable_account",
      },
      { header: "Earning Interval", accessorKey: "interval" },
    ],
    []
  );
  return (
    <>
    
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Fixed Deposit settings{" "}
                      </h2>
                    </div>
                    <div className="card-body p-2">
                      <div className="mb-4 col d-flex justify-content-end">
                        <FixedModal getFixedSettings={getFixedSettings} />
                      </div>

                      <MaterialReactTable
                        columns={account_body}
                        data={fixedSettings}
                        className="table align-items-center mb-0"
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableRowSelection
                        enableGrouping
                        enableStickyHeader
                        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiSearchTextFieldProps={{
                          placeholder: "Search",
                          sx: { fontFamily: "Nunito", fontSize: "12px" },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
        
    </>
  );
};

export default FixedDeposit;
