import {MaterialReactTable} from "material-react-table";
import { useState, useEffect, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
const SharesGeneral = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const controller = new AbortController();

  const getShares = async () => {
    try {
      const response = await axiosPrivate.get("/clients/general", {
        signal: controller.signal,
      });
      setTransactions(response.data.data.clients);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getShares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "account_number", //access nested data with dot notation
        enableClickToCopy: true,
        header: "A/C No.",
        size: 50,
        accessorFn: (row) => row?.account,
      },
      {
        id: "account_name", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Name",
        size: 50,
        accessorFn: (row) => row?.name,
      },
      {
        id: "shares", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Shares",
        accessorFn: (row) => row?.shares,
      },
      {
        id: "share_balance", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Share Balance",
        accessorFn: (row) => row?.share_balance?.toLocaleString(),
      },

      // {
      //   id: "id", //access nested data with dot notation
      //   enableClickToCopy: false,
      //   size: 50,
      //   header: "Action",
      //   accessorFn: (row) => (
      //     <div className="align-middle text-center text-sm">
      //       <button className="text-secondary rounded-0 border-0 font-weight-bold text-xs mr-1">
      //         Certificate
      //       </button>
      //     </div>
      //   ),
      // },
    ],
    []
  );

  const refetch = async () => {
    setIsLoading(true);
    await getShares();
  };
  return (
    <>
      <section className="py-3 mt-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body p-0">
                <MaterialReactTable
                  columns={columns}
                  data={transactions?.filter(
                    (transaction) => transaction.shares !== 0
                  )}
                  className="table align-items-center mb-0"
                  enableColumnFilterModes
                  enableColumnOrdering
                  enableRowSelection
                  enableGrouping
                  enableStickyHeader
                  initialState={{ density: "compact" }}
                  state={{ isLoading: isLoading }}
                  muiTableContainerProps={{
                    sx: { maxHeight: "600px" },
                  }}
                  muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      fontWeight: "normal",
                      fontSize: "13px",
                      fontFamily: "Nunito",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontWeight: "normal",
                      fontSize: "13px",
                      fontFamily: "Nunito",
                    },
                  }}
                  muiSearchTextFieldProps={{
                    placeholder: "Search",
                    sx: { fontFamily: "Nunito", fontSize: "12px" },
                  }}
                  renderTopToolbarCustomActions={() => (
                    <>
                      <div className="btn-group">
                        <button
                          onClick={refetch}
                          className="btn btn-sm btn-secondary rounded-0"
                        >
                          <i className="fa fa-rotate-right"></i>
                        </button>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SharesGeneral;
