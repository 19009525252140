import {MaterialReactTable} from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
const LoanGuarantor = ({ showModal, handleClose, data, getloan }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);

 const columns = useMemo(
   () => [
     {
       id: "name", //access nested data with dot notation
       enableClickToCopy: true,
       header: "Name",
       accessorFn: (row) => row.name,
       size: 10,
     },
     {
       id: "amount", //access nested data with dot notation
       enableClickToCopy: true,
       header: "Amount Guarantored",
       accessorFn: (row) => row?.amount?.toLocaleString(),
     },
     {
       id: "mode", //access nested data with dot notation
       enableClickToCopy: true,
       header: "Mode",
       accessorFn: (row) => row.mode,
     },
     {
       id: "notes", //access nested data with dot notation
       enableClickToCopy: true,
       header: "Description",
       accessorFn: (row) => row.notes,
     },
   ],
   []
 );
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    let client = data.client.value;
    delete data.client;
    data.client = client;
    console.log(data)
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("/loans/gurantor", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getloan();
      handleClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  const [clients, setClients] = useState([]);
  const controller = new AbortController();
  const getClients = async () => {
    try {
      const response = await axiosPrivate.get("/clients/general", {
        signal: controller.signal,
      });
      setClients(response?.data?.data?.clients);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = clients?.map((client) => ({
    value: `${client.id}`,
    label: `${client.name} - ${client.account} - ${client.contact}`,
  }));
    const customStyles = {
      control: (provided) => ({
        ...provided,
        height: "45px", // adjust the height as needed
      }),
    };
  return (
    <>
      <Toaster />
      <>
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Loan Guarantors
            </Modal.Title>
            <button onClick={handleClose} className="btn-close btn-danger">
              <i className="material-icons opacity-10">close</i>
            </button>
          </Modal.Header>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <Modal.Body>
              <div className="container-fluid row">
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="client" className="form-label">
                    Select Member
                  </label>
                  <Controller
                    control={control}
                    name="client"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("client", { required: true })}
                        className="border-1 form-control-md"
                        placeholder="Select  Member * "
                        styles={customStyles}
                        options={options}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        isMulti={false}
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.client?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please Select Member
                    </p>
                  )}
                </div>
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="value" className="form-label">
                    Amount to guarantee
                  </label>
                  <input
                    type="number"
                    name="value"
                    placeholder="Enter Value *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("value", { required: true })}
                  />
                  <input
                    type="hidden"
                    value={params.id}
                    {...register("loanid", { required: true })}
                  />
                  {errors?.value?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please Enter Value
                    </p>
                  )}
                </div>
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="mode" className="form-label">
                    Mode
                  </label>
                  <select
                    name="mode"
                    placeholder="Enter Mode *"
                    className="form-control border-0 shadow form-control-lg input-text"
                    {...register("mode", { required: true })}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select Mode
                    </option>
                    <option value="saving">Savings</option>
                    <option value="colletral">Colletral</option>
                  </select>
                  {errors?.mode?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please Enter Mode
                    </p>
                  )}
                </div>
                <div className="form-group col-md-12 input-container">
                  <label htmlFor="address" className="form-label">
                    Notes
                  </label>
                  <textarea
                    name="notes"
                    placeholder="Enter Notes *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("notes", { required: true })}
                  ></textarea>
                  {errors?.notes?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter notes
                    </p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={disabled}
                type="submit"
                className="btn btn-primary"
              >
                {!disabled && "Save"} {disabled && "Please Wait"}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
      <MaterialReactTable
        columns={columns}
        data={data}
        className="table align-items-center p-0"
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        enableGrouping
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
        initialState={{ density: "compact" }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
      />
    </>
  );
};

export default LoanGuarantor;
