import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const ExtraChargesModal = ({ showModal, handleCloseModal, getCharges }) => {
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    data.loanid = params.id;

    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("/loans/charges", data, {
        signal: controller.signal,
      });

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      getCharges();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> New Loan Charge </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-12 input-container">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                name="title"
                placeholder="Enter Charge Title*"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("title", {
                  required: true,
                })}
              />
              {errors?.title?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter charge title
                </p>
              )}
            </div>
            <div className="form-group col-md-12 input-container">
              <label htmlFor="amount" className="form-label">
                Value
              </label>
              <input
                type="number"
                name="amount"
                placeholder="Enter Charge Amount *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("amount", {
                  required: true,
                })}
              />
              {errors?.amount?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter value to deduct, it can be amount or percentage
                </p>
              )}
            </div>
            {/* <div className="form-group col-md-12 input-container">
              <label htmlFor="amount" className="form-label">
                Deduction Mode
              </label>
              <select
                name="rate_type"
                className="form-control border-0 shadow form-control-lg input-text"
                {...register("rate_type", {
                  required: true,
                })}
                defaultValue={""}
              >
                <option value="">Select Deduction Mode</option>
                <option value="value">Value</option>
                <option value="percentage">Percentage</option>
              </select>
              {errors?.rate_type?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please select deduction mode
                </p>
              )}
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-danger"
              disabled={disabled}
            >
              {!disabled && "Charge"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ExtraChargesModal;
