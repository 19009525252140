import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import axios from "../../config/axios";
import fileDownload from "js-file-download";

const ReportsModals = ({ handleAccountReport, openAccountReport }) => {
  const [disabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const controller = new AbortController();
  const getAttachedAccounts = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounts/attached/accounts/${params.id}`,
        {
          signal: controller.signal,
        }
      );
      setAccounts(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAttachedAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = accounts?.map((account) => ({
    value: `${account.id}`,
    label: `${account.title}`,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const [information, setInformation] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [loanCharges, setLoanCharges] = useState([]);
  const [accountCharges, setAccountCharges] = useState([]);
  const [loanDisurbse, setLoanDisurbse] = useState([]);
  const [loanRepayment, setLoanRepayment] = useState([]);
  const [shareCharge, setshareCharge] = useState([]);
  const [datas, setDatas] = useState([]);
  const onSave = async (data) => {
    let account = data.account.value;
    delete data.account;
    data.account = account;
    // console.log(data);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `/reports/single/account`,
        data,
        {
          signal: controller.signal,
        }
      );
      setDatas(response?.data?.data);
      setInformation(response?.data?.data?.account);
      setDeposits(response?.data?.data?.data?.deposits);
      setWithdraws(response?.data?.data?.data?.withdraw);
      setLoanCharges(response?.data?.data?.data?.loan_charges);
      setAccountCharges(response?.data?.data?.data?.account_charges);
      setLoanDisurbse(response?.data?.data?.data?.loan_disurbse);
      setLoanRepayment(response?.data?.data?.data?.loan_repayment);
      setshareCharge(response?.data?.data?.data?.share_charges);
      progress.finish();
      setDisabled(false);
      handleAccountReport();
      setOpenModal(true);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const date_options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const data = [
    ...deposits,
    ...withdraws,
    ...loanCharges,
    ...accountCharges,
    ...loanDisurbse,
    ...loanRepayment,
    ...shareCharge,
  ];
  // console.log(data);
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

  const download = async (data) => {
    console.log(data)
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axios.post(
        `/export/reports/account/statement`,
        { data: data },
        { responseType: "blob" },
        {
          signal: controller.signal,
        }
      );
      // console.log(response.data);
      const download_title =
        (information?.account_name).replace(/\s/g, "") +
        "-account-statement-" +
        information?.account_number +
        ".pdf";
         fileDownload(response.data, download_title);
      progress.finish();
      setDisabled(false);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={openAccountReport}
        backdrop="static"
        keyboard={false}
        onHide={handleAccountReport}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Create Statement
          </Modal.Title>
          <button
            onClick={handleAccountReport}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="container row">
              <div className="form-group col-md-12">
                <label htmlFor="account" className="form-label">
                  Select Account To Query *
                </label>
                <Controller
                  control={control}
                  name="account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("account", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Accounts To Query * "
                      styles={customStyles}
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={false}
                      ref={ref}
                    />
                  )}
                />
                {errors?.account?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    Please Select Account
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="start_date" className="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  name="start_date"
                  placeholder="Start Date *"
                  className="form-control border-0 shadow form-control-md input-text"
                  {...register("start_date", { required: true })}
                />
                {errors?.start_date?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Start Date
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="end_date" className="form-label">
                  End Date
                </label>
                <input
                  type="date"
                  name="end_date"
                  placeholder="End Date *"
                  className="form-control border-0 shadow form-control-md input-text"
                  {...register("end_date", { required: true })}
                />
                {errors?.end_date?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter End Date
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleAccountReport}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Generate"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={openModal}
        backdrop="static"
        keyboard={false}
        onHide={() => setOpenModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Account Statement ({information?.account})
          </Modal.Title>
          <button
            onClick={() => setOpenModal(false)}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ height: "500px", overflowY: "auto" }}>
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th className="text-center" colSpan="7">
                  <h6 style={{ textAlign: "right" }}>
                    <b>From: </b>
                    {new Date(information?.start_date).toLocaleDateString(
                      "es-CL",
                      date_options
                    )}{" "}
                    <br /> <b>To: </b>
                    {new Date(information?.end_date).toLocaleDateString(
                      "es-CL",
                      date_options
                    )}
                  </h6>
                </th>
              </tr>
              <tr>
                <th colSpan={8}>
                  <span className="float-left">
                    Account Name: {information?.account_name} <br />
                    Account Number: {information?.account_number}
                  </span>
                  <span className="float-right">
                    Account Balance:{" "}
                    {information?.account_balance?.toLocaleString()}
                  </span>
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Comment</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>

            <tbody>
              {sortedData?.map((row, index) => {
                const baseKey = `${row?.id}-${row.type}-${index}`;
                return row.type === "credit" ? (
                  <tr key={baseKey}>
                    <td>{row?.date}</td>
                    <td>{row?.reference}</td>
                    <td>{row?.comment}</td>
                    <td></td>
                    <td>{row?.amount?.toLocaleString()}</td>
                    <td>{row?.balance?.toLocaleString()}</td>
                  </tr>
                ) : row.type === "debit" ? (
                  <>
                    <tr key={baseKey}>
                      <td>{row?.date}</td>
                      <td>{row?.reference}</td>
                      <td>{row?.comment}</td>
                      <td>{row?.amount?.toLocaleString()}</td>
                      <td></td>
                      <td>
                        {(row?.balance + (row?.charges ?? 0)).toLocaleString()}
                      </td>
                    </tr>
                    {row?.charges ? (
                      <tr key={`${baseKey}-charge`}>
                        <td>{row?.date}</td>
                        <td></td>
                        <td>Withdraw Charge</td>
                        <td>{row?.charges?.toLocaleString()}</td>
                        <td></td>
                        <td>{row?.balance?.toLocaleString()}</td>
                      </tr>
                    ) : null}
                  </>
                ) : null;
              })}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark rounded-0"
            onClick={() => setOpenModal(false)}
          >
            Close
          </button>
          <button
            disabled={disabled}
            type="submit"
            className="btn btn-primary rounded-0"
            onClick={() => download(datas)}
          >
            {!disabled && "Download"} {disabled && "Please Wait"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportsModals;
