import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";
import Pagination from "pages/components/pagination";

const AgingLoans = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);

  const controller = new AbortController();

  const [loans, setLoans] = useState([]);
  //fetch Accounts Accounts data
  const getLoans = async () => {
    progress.start();
    try {
      const response = await axiosPrivate.get("reports/new/loans/aging-loans", {
        signal: controller.signal,
      });

      setLoans(response.data.data);
      progress.finish();
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loans?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(loans?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Calculate totals
  const totals = loans.reduce(
    (acc, loan) => {
      acc.loanAmount += loan.amount || 0;
      acc.principal += loan.principal || 0;
      acc[loan.age_group] += loan.principal || 0;
      return acc;
    },
    { 
      loanAmount: 0,
      principal: 0,
      age_one: 0,
      age_two: 0,
      age_three: 0,
      age_four: 0,
      age_five: 0,
      age_six: 0,
      age_seven: 0,
    }
  );
// console.log(loans)
   // function to export data as pdf
   const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/ageing_loans/pdf`,

        { responseType: "blob" }
      );
      const filename = "Aging Loans_Report.pdf";
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading aging_Loans_Report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/ageing_loans/csv`,

        { responseType: "blob" }
      );

      const filename = `Aging_Loans_Report.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading aging loans report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };
  return (
    <>
      <section className="mt-1">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Aging Loans Reports
                </h2>
              </div>
              <div className="card-body p-1 ">
                <div className="mt-3">
                  <table className="table table-striped table-bordered mt-4 text-medium-small ">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>Member's Name</th>
                        <th>Account No.</th>
                        <th>Loan Product</th>
                        <th>Loan Number</th>
                        <th>Date of Last Pay</th>
                        <th>Loan Amount</th>
                        <th>Principal in arrears</th>
                        <th>1 - 30 Days</th>
                        <th>31 - 60 Days</th>
                        <th>61 - 90 Days</th>
                        <th>91 - 120 Days</th>
                        <th>121 - 150 Days</th>
                        <th>151 - 180 Days</th>
                        <th>More than 180 Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((data) => (
                        <tr key={data?.id}>
                          <td>{data?.client}</td>
                          <td>{data?.account}</td>
                          <td>{data?.product}</td>
                          <td>{data?.code}</td>
                          <td>
                            {" "}
                            {new Date(data?.date).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>{data?.amount}</td>
                          <td style={{ textAlign: "right" }}>{data?.principal.toLocaleString()}</td>
                          {data?.age_group === "age_one" ? (
                            <td style={{ textAlign: "right" }}>{(data?.principal).toLocaleString()}</td>
                          ) : (
                            <td></td>
                          )}
                          {data?.age_group === "age_two" ? (
                            <td style={{ textAlign: "right" }}>{(data?.principal).toLocaleString()}</td>
                          ) : (
                            <td></td>
                          )}
                          {data?.age_group === "age_three" ? (
                            <td style={{ textAlign: "right" }}>{(data?.principal).toLocaleString()}</td>
                          ) : (
                            <td></td>
                          )}
                          {data?.age_group === "age_four" ? (
                            <td style={{ textAlign: "right" }}>{(data?.principal).toLocaleString()}</td>
                          ) : (
                            <td></td>
                          )}
                          {data?.age_group === "age_five" ? (
                            <td style={{ textAlign: "right" }}>{(data?.principal).toLocaleString()}</td>
                          ) : (
                            <td></td>
                          )}
                          {data?.age_group === "age_six" ? (
                            <td style={{ textAlign: "right" }}>{(data?.principal).toLocaleString()}</td>
                          ) : (
                            <td></td>
                          )}
                          {data?.age_group === "age_seven" ? (
                            <td style={{ textAlign: "right" }}>{(data?.principal).toLocaleString()}</td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                        
                      ))}
                      <tr className="bg-dark text-white">
                        <td className="text-white" colSpan="5" style={{ textAlign: "left" }}>
                          Totals
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.loanAmount.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.principal.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.age_one.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.age_two.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.age_three.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.age_four.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.age_five.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.age_six.toLocaleString()}
                        </td>
                        <td className="text-white" style={{ textAlign: "right" }}>
                          {totals.age_seven.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                  <div className="d-flex align-items-center mt-0">
                    <div className="ml-auto mb-0 mt-0">
                      <button
                        className="btn btn-primary text-right"
                        onClick={downloadUssdReportAsPDF}

                        // disabled={isGenerating || isPDFDownloading}
                      >
                        {isPDFDownloading
                          ? "Downloading please wait..."
                          : "Download PDF"}
                      </button>
                      <button
                        className="btn btn-secondary text-right"
                        onClick={downloadUssdReportAsCSV}
                        // disabled={isGenerating || isCSVDownloading}
                      >
                        {isCSVDownloading
                          ? "Downloading please wait..."
                          : "Download CSV"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgingLoans;
