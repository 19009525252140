import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const LoanEdit = ({ showModal, handleCloseModal, data, getloan }) => {

  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: data });

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/loans/applications/${params.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getloan();
      handleCloseModal();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <form noValidate onSubmit={handleSubmit(onSave)}>
            <>
              <Modal.Header>
                <Modal.Title className="text-center"> Edit Loan </Modal.Title>
              </Modal.Header>
              <Modal.Body className="row col-md-12 mx-auto">
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="amount" className="form-label">
                    Amount
                  </label>
                  <input
                    type="text"
                    name="amount"
                    placeholder="Enter Amount *"
                    defaultValue={data?.amount}
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("amount", { required: true })}
                  />
                  {errors?.amount?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter amount
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="period" className="form-label">
                    Period
                  </label>
                  <input
                    type="text"
                    name="period"
                    defaultValue={data?.period}
                    placeholder="Enter Period *"
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("period", { required: true })}
                  />
                  {errors?.period?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter period
                    </p>
                  )}
                </div>
                {/* Last Name */}
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="grace_period" className="form-label">
                    Grace Period
                  </label>
                  <input
                    type="text"
                    name="grace_period"
                    placeholder="Enter Grace Period *"
                    defaultValue={data?.grace}
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("grace_period", { required: true })}
                  />
                  {errors?.grace_period?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter grace period
                    </p>
                  )}
                </div>
                {/* Date of birth */}
                <div className="form-group col-md-6 input-container">
                  <label htmlFor="notes" className="form-label">
                    Notes
                  </label>
                  <textarea
                    type="textdate"
                    name="notes"
                    placeholder="Enter notes "
                    defaultValue={data?.notes}
                    className="form-control border-0 shadow form-control-md input-text"
                    {...register("notes", { required: true })}
                  ></textarea>
                  {errors?.notes?.type === "required" && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Please enter notes
                    </p>
                  )}
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="Button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit(onSave)}
                  disabled={disabled}
                >
                  {!disabled && "Update"} {disabled && "Please Wait ....."}
                </Button>
              </Modal.Footer>
            </>
          </form>
        </>
      </Modal>
    </>
  );
};

export default LoanEdit;
