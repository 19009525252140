import Logo from "assets/images/img/logo.png";
import Favicon from "assets/images/img/192x192.png";
import { Link } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import useLogout from "middlewares/hooks/useLogout";
const Header = memo(() => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState([]);
  const controller = new AbortController();
  const getUser = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco/default/header`, {
        signal: controller.signal,
      });

      setUser(response?.data?.data?.data);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const logout = useLogout();
  const signOut = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      await logout();
      await localStorage.removeItem("verify");
      navigate("/");
    }
  };
  return (
    <>
      <div className="header sticky-top">
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="text-sm">
            <div className="btn-group">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-outline-danger rounded-0 btn-sm"
              >
                <i className="fa fa-arrow-circle-left"></i>{" "}
              </button>
              <button
                onClick={() => navigate(1)}
                className="btn btn-outline-success rounded-0 btn-sm"
              >
                <i className="fa fa-arrow-circle-right"></i>{" "}
              </button>
            </div>
            <b> Sacco Name: </b> {user.sacco_name}{" "}
          </div>
          <Link
            to="/dashboard"
            className="navbar-brand font-weight-bold text-uppercase text-base"
          >
            <img className="" height="40px" src={Logo} alt="Irembo Finance" />
          </Link>

          <ul className="ml-auto d-flex align-items-center list-unstyled mb-0">
            <li className="nav-item">
              <div className="d-none d-lg-block">
                <p className="text-sm">
                  <b>SACCO code:</b> {user.ussd} <b className="ml-3">Branch:</b>{" "}
                  {user.branch_name}
                </p>
              </div>
            </li>
            <li className="nav-item dropdown ml-auto">
              <Link
                id="userInfo"
                to="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="nav-link dropdown-toggle"
              >
                <img
                  src={Favicon}
                  alt="Innocent Bigega"
                  style={{ maxHeight: "2.0rem" }}
                  className="rounded-circle "
                />
              </Link>
              <div aria-labelledby="userInfo" className="dropdown-menu">
                <Link to="#" className="dropdown-item">
                  <strong className="d-block text-uppercase headings-font-family">
                    {user?.fullname}
                  </strong>
                  <small>{user?.email}</small> <br />
                  <small>{user?.contact}</small>
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="/profile/user" className="dropdown-item">
                  Settings
                </Link>
                <Link to="/activity" className="dropdown-item">
                  Activity log
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="#" onClick={signOut} className="dropdown-item">
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
});

export default Header;
