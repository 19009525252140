import { useEffect, useState } from "react";

const GreetingComponent = () => {
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const getCurrentTime = () => {
      const currentHour = new Date().getHours();
      setGreeting(
        currentHour >= 5 && currentHour < 12
          ? "Good morning"
          : currentHour >= 12 && currentHour < 17
          ? "Good afternoon"
          : "Good evening"
      );
    };

    getCurrentTime(); // Set the initial greeting

  }, []);

  return greeting;
};

export default GreetingComponent;
