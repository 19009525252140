import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import Select from "react-select";
import { exportToJsonExcel, exportToJsonPdf } from '../exports/exportFunctions'; 
import fileDownload from "js-file-download";


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="pagination">
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="btn btn-sm btn-link text-dark rounded-0">
        {currentPage}
      </span>
      <button
        className="btn btn-dark btn-sm rounded-0"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};


const AccountBalancesReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState([]);
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const selectedFields = [
    "name",
    "account_no",
    "actual_balance",
    "avaliable_balance",
    "timestamp",
  ]; 

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const controller = new AbortController(); 

  const [accounts, setAccounts] = useState([]);
  //fetch Accounts Accounts data
  const getAccounts = async () => {
    try {
      const response = await axiosPrivate.get("settings/accounts/account", {
        signal: controller.signal,
      });

      setAccounts(
        response.data.data.accounts.filter(
          (account) => account.type === "savings" || account.type === "fixed"
        )
      );
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const accountsData = accounts?.map((account) => ({
    value: account.id,
    label: account.title + " | " + account.code,
  }));

  const onQuery = async (data) => {
    let account = data.account.value;
    delete data.account;
    data.account = account;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `/reports/general/accounts`,
        data,
        {
          signal: controller.signal,
        }
      );
      setData(response.data.data);
      console.log(response.data.data);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const actual_balance = data?.account_balances
    ?.map((data) => data?.actual_balance)
    .reduce((a, b) => a + b, 0);
  const avaliable_balance = data?.account_balances
    ?.map((data) => data?.avaliable_balance)
    .reduce((a, b) => a + b, 0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.account_balances?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(data?.account_balances?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const downloadAccountBalancesReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }
  
      const response = await axiosPrivate.post(
        `/export/reports/account_balances/pdf`,
        {
          data
        },
        { responseType: "blob" }
      );
      const filename = `Account_Balances.pdf`;
      fileDownload(response.data, filename);
  
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading account balances:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log("Error response data:", error.response.data);
        console.log("Error response status:", error.response.status);
        console.log("Error response headers:", error.response.headers);
  
        if (error.response.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };
  
  
  return (
    <>
      <Toaster />

      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header bg-dark text-white">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Account Balances Report{" "}
                </h2>
              </div>
              <div className="card-body mt-3 mb-3">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <form
                      className="row needs-validation text-medium-small"
                      id="generalLegerForm"
                      onSubmit={handleSubmit(onQuery)}
                    >
                      <div className="form-group col-md-3">
                        <label className="form-label">Select Account</label>
                        <Controller
                          control={control}
                          name="account"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <Select
                              {...register("account", {
                                required: true,
                              })}
                              options={accountsData}
                              onChange={onChange}
                              isMulti={false}
                              onBlur={onBlur}
                              name={name}
                              className="shadow border-0"
                              value={value}
                              styles={customStyles}
                              ref={ref}
                            />
                          )}
                        />
                        {errors?.account?.type === "required" && (
                          <p className="text-small m-0 text-start text-danger help-block">
                            Please select account
                          </p>
                        )}
                      </div>
                      <div className="form-group col-md-3 pt-3 mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary text-center rounded-0"
                          disabled={disabled}
                        >
                          {!disabled && "Generate"}{" "}
                          {disabled && "Please Wait ....."}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {data?.account_balances &&
                  data?.account_balances?.length > 0 && (
                    <div className="btn-group">
                      <button
                        onClick={() => exportToJsonExcel(data?.account_balances, selectedFields)}
                        className="btn btn-info rounded-0"
                      >
                        <i className="fa fa-file-excel"></i>
                      </button>
                      <button
                        onClick={() => exportToJsonPdf(data?.account_balances, selectedFields)}
                        className="btn btn-danger rounded-0"
                      >
                        <i className="fa fa-file-pdf"></i>
                      </button>
                    </div>
                  )}
                <div className="mt-3">
                  <table className="table table-hover table-bordered text-medium-small">
                    <thead>
                      <tr>
                        <th colSpan="7">
                          <b className="text-right">
                            <p className="text-bold p-0">
                              Account Title : {data?.account_details?.title}
                            </p>
                            <p className="text-bold p-0">
                              Account Code : {data?.account_details?.code}
                            </p>
                            <p className="text-bold p-0">
                              Account Minimal Balance:{" "}
                              {data?.account_details?.minimal_balance.toLocaleString()}
                            </p>
                            <p className="text-bold p-0">
                              Account Balance:{" "}
                              {data?.account_details?.account_balance.toLocaleString()}
                            </p>
                          </b>
                        </th>
                      </tr>
                      <tr className="bg-dark text-white text-medium-small">
                        <th>Client Name</th>
                        <th>Account Number</th>
                        <th>Actual Balance</th>
                        <th>Avaliable Balance</th>
                      </tr>
                    </thead>

                    <tbody className="text-medium-small">
                      {currentItems?.map((data) => (
                        <tr key={data?.id}>
                          <td>{data?.name}</td>
                          <td>{data?.account_no}</td>
                          <td>{data?.actual_balance.toLocaleString()}</td>
                          <td>{data?.avaliable_balance.toLocaleString()}</td>
                        </tr>
                      ))}
                      <tr className="bg-dark text-white">
                        {/* <th></th> */}
                        <th colSpan="2">Total Balance</th>
                        <th>{actual_balance?.toLocaleString()}</th>
                        <th>{avaliable_balance?.toLocaleString()}</th>
                      </tr>
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      <button
                        className="btn btn-primary text-right"
                        onClick={downloadAccountBalancesReportAsPDF}

                        // disabled={isGenerating || isPDFDownloading}
                      >
                        {isPDFDownloading
                          ? "Downloading please wait..."
                          : "Download PDF"}
                      </button>
                      
                    </div>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountBalancesReport;
