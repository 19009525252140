import { useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import AddIncome from "./components/addIncome";
import Table from "pages/components/common_table";

const Incomes = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
      const [isLoading, setIsLoading] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
    const controller = new AbortController();
    const getIncome = async () => {
      try {
        const response = await axiosPrivate.get("/accounting/incomes/account", {
          signal: controller.signal,
        });
        setData(response.data.data.transactions);
        setIsLoading(false);
      } catch (error) {
        // console.log(error)
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
      }
    };
  useEffect(() => {
    getIncome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = () => {
    setIsLoading(true);
    getIncome();
  }
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  const columns = useMemo(
    () => [
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Amount",
        size: 50,
        accessorFn: (row) => row?.amount?.toLocaleString(),
      },
      {
        id: "account", //access nested data with dot notation
        enableClickToCopy: true,
        header: " A/C Details",
        size: 50,
        accessorFn: (row) => row?.account,
      },
      {
        id: "reference", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Transaction Reference",
        size: 50,
        accessorFn: (row) => row?.reference,
      },
      {
        id: "receivedfrom", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Received From",
        size: 50,
        accessorFn: (row) => row?.receivedfrom,
      },
      {
        id: "date", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Date",
        accessorFn: (row) => row?.date,
      },
      {
        id: "notes", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Notes",
        accessorFn: (row) => row?.notes,
        size: 50,
      },
      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Date",
        accessorFn: (row) => row?.timestamp,
      },
    ],
    []
  );
  return (
    <>
      <section className="py-2 mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <AddIncome
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                getIncome={getIncome}
              />
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  data={data}
                  refetch={refetch}
                  isLoading={isLoading}
                  headers={
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="h6 mb-0 text-uppercase  text-center">
                          Income Transactions
                        </h2>
                      </div>
                      <div className="col-md-6">
                        {roles?.includes(1000028) && (
                          <button
                            className="btn btn-outline-dark text-small float-right"
                            onClick={handleShowModal}
                          >
                            New Income Transaction
                          </button>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Incomes;
