import axios from "axios";

// const BASE_URL = "http://localhost/ahuriire/irembo/";
// const BASE_URL = "http://192.168.0.102/ahuriire/irembo/";
const BASE_URL = 'https://api.irembofinance.com/v1/'
// const BASE_URL = 'https://dev.irembofinance.com/apis/'

export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPrivate = axios.create({
        baseURL : BASE_URL,
        headers: {'Content-Type': 'application/json'},
        withCredentials: false
        
})
