import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";
import Pagination from "pages/components/pagination";

const LoanBalances = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);

  const controller = new AbortController();

  const [loans, setLoans] = useState([]);
  const [totals, setTotals] = useState({
    amountApplied: 0,
    amountDisbursed: 0,
    principalPaid: 0,
    principalBalance: 0,
    interestCharged: 0,
    interestPaid: 0,
    interestBalance: 0,
    penaltyCharged: 0,
    penaltyBalance: 0,
    totalLoanAmount: 0,
    totalLoanBalance: 0,
  });

  const getLoans = async () => {
    progress.start();
    try {
      const response = await axiosPrivate.get(
        "reports/new/loans/loans-balances",
        {
          signal: controller.signal,
        }
      );
      setLoans(response.data.data);
      progress.finish();
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loans?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(loans?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const calculateTotals = () => {
    const newTotals = loans.reduce((acc, data) => {
      const amountDisbursed = Number(data?.amount_disbursed) || 0;
      const amountPaid = Number(data?.amount_paid) || 0;
      const interest = Number(data?.interest) || 0;
      const interestPaid = Number(data?.interest_paid) || 0;
      const penalties = Number(data?.penalties) || 0;
      const penaltiesPaid = Number(data?.penalties_paid) || 0;

      return {
        
        amountDisbursed: acc.amountDisbursed + amountDisbursed,
        principalPaid: acc.principalPaid + amountPaid,
        principalBalance: acc.principalBalance + (amountDisbursed - amountPaid),
        interestCharged: acc.interestCharged + interest,
        interestPaid: acc.interestPaid + interestPaid,
        interestBalance: acc.interestBalance + (interest - interestPaid),
        penaltyCharged: acc.penaltyCharged + penalties,
        penaltyPaid: acc.penaltyPaid + penaltiesPaid,
        penaltyBalance: acc.penaltyBalance + (penalties - penaltiesPaid),
        totalLoanAmount: acc.totalLoanAmount + (amountDisbursed + interest + penalties),
        totalLoanBalance: acc.totalLoanBalance + ((amountDisbursed + interest + penalties) - (amountPaid + penaltiesPaid + interestPaid)),
      };
    }, {
      amountDisbursed: 0,
      principalPaid: 0,
      principalBalance: 0,
      interestCharged: 0,
      interestPaid: 0,
      interestBalance: 0,
      penaltyCharged: 0,
      penaltyPaid: 0,
      penaltyBalance: 0,
      totalLoanAmount: 0,
      totalLoanBalance: 0,
    });

    setTotals(newTotals);
  };

  useEffect(() => {
    calculateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loans]);

  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(`/export/reports/loan_balance/pdf`, { responseType: "blob" });
      const filename = "Loan_Balances_Report.pdf";
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading loan portfolio:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(`/export/reports/loan_balance/csv`, { responseType: "blob" });
      const filename = `Loan_Balances_Report.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading waived loans report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  return (
    <>
      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">Loan Balances</h2>
              </div>
              <div className="card-body p-1">
                <div className="mt-3">
                  <table className="table table-bordered table-stripped text-medium-small">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>Members’ Name</th>
                        <th>Acc No.</th>
                        <th>Loan Product</th>
                        <th>Date Disbursed</th>
                        <th>Amount Disbursed</th>
                        <th>Principal Paid</th>
                        <th>Principal Balance</th>
                        <th>Interest Charged</th>
                        <th>Interest Paid</th>
                        <th>Interest Balance</th>
                        <th>Penalty Charged</th>
                        <th>Penalty Paid</th>
                        <th>Penalty Balance</th>
                        <th>Total Loan Amount</th>
                        <th>Total Loan Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((data) => (
                        <tr key={data?.id}>
                          <td>{data?.client}</td>
                          <td>{data?.account}</td>
                          <td>{data?.product}</td>
                          <td>
                            {" "}
                            {new Date(data?.date).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(Number(data?.amount_disbursed) || 0).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(Number(data?.amount_paid) || 0).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(
                              (Number(data?.amount_disbursed) || 0) -
                              (Number(data?.amount_paid) || 0)
                            ).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(Number(data?.interest) || 0).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(Number(data?.interest_paid) || 0).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(
                              (Number(data?.interest) || 0) -
                              (Number(data?.interest_paid) || 0)
                            ).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(Number(data?.penalties) || 0).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(Number(data?.penalties_paid) || 0).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(
                              (Number(data?.penalties) || 0) -
                              (Number(data?.penalties_paid) || 0)
                            ).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(
                              (Number(data?.amount_disbursed) || 0) +
                              (Number(data?.interest) || 0) +
                              (Number(data?.penalties) || 0)
                            ).toLocaleString()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {(
                              (Number(data?.amount_disbursed) || 0) +
                              (Number(data?.interest) || 0) +
                              (Number(data?.penalties) || 0) -
                              ((Number(data?.amount_paid) || 0) +
                                (Number(data?.penalties_paid) || 0) +
                                (Number(data?.interest_paid) || 0))
                            ).toLocaleString()}
                          </td>
                        </tr>
                      ))}

                      <tr className="bg-dark text-white">
                        <td className="font-weight-bold" colSpan={4}>
                          Totals
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.amountDisbursed || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.principalPaid || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.principalBalance || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.interestCharged || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.interestPaid || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.interestBalance || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.penaltyCharged || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.penaltyPaid || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.penaltyBalance || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.totalLoanAmount || 0).toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(totals.totalLoanBalance || 0).toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
                <div className="d-flex align-items-center mt-0">
                  <div className="ml-auto mb-0 mt-0">
                    <button
                      className="btn btn-primary text-right"
                      onClick={downloadUssdReportAsPDF}
                      disabled={isPDFDownloading}
                    >
                      {isPDFDownloading
                        ? "Downloading please wait..."
                        : "Download PDF"}
                    </button>
                    <button
                      className="btn btn-secondary text-right"
                      onClick={downloadUssdReportAsCSV}
                      disabled={isCSVDownloading}
                    >
                      {isCSVDownloading
                        ? "Downloading please wait..."
                        : "Download CSV"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoanBalances;
