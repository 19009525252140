import { MaterialReactTable } from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import DepositReceipt from "./components/receipt_deposit";
import ConfirmModal from "pages/components/confirmModal";
const SavingGeneralSacco = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState([]);
  const handleOpenModal = (data) => {
    setShowModal(true);
    setModalData(data);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalData([]);
  };

  const handleCancel = async () => {
    setId([]);
  };

  const handleDelete = async () => {
    try {
      const controller = new AbortController();
      const response = await axiosPrivate.delete(
        "accounting/savings/deposits/" + id,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      await refetch();
    } catch (error) {
      if (!error?.response) {
        toast("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  const columns = useMemo(
    () => [
      {
        id: "client_account_number", //access nested data with dot notation
        enableClickToCopy: true,
        header: "A/C No.",
        size: 50,
        accessorFn: (row) => row.client_account_number,
      },
      {
        id: "account_type", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Type",
        size: 50,
        accessorFn: (row) => row.account_type,
      },
      {
        id: "account_name", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Name",
        size: 50,
        accessorFn: (row) => row?.account_name,
      },
      {
        id: "deposit_amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => row?.deposit_amount?.toLocaleString(),
      },
      {
        id: "deposit_transaction_method", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Method",
        accessorFn: (row) => row.deposit_transaction_method,
      },

      {
        id: "deposit_transaction_code", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Transaction Code",
        accessorFn: (row) => row.deposit_transaction_code,
      },
      {
        id: "deposit_transaction_timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
        accessorFn: (row) => row.deposit_transaction_timestamp,
      },
      {
        id: "user", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "System User",
        accessorFn: (row) => row.user,
      },
      {
        id: "deposit_transaction_status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) =>
          row.deposit_transaction_status === "success" ? (
            <div className="badge badge-success p-1 text-white">Success</div>
          ) : row.deposit_transaction_status === "pending" ? (
            <div className="badge badge-info p-1 text-white">Pending</div>
          ) : row.deposit_transaction_status === "failed" ? (
            <div className="badge badge-danger p-1 text-white">Failed</div>
          ) : (
            ""
          ),
      },
      {
        id: "deposit_transaction_reverse_status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Reverse Status",
        accessorFn: (row) =>
          row.deposit_transaction_reverse_status === "ongoing" ? (
            <div className="badge badge-success p-1 text-white">
              Not Reversed
            </div>
          ) : row.deposit_transaction_reverse_status === "reversed" ? (
            <div className="badge badge-warning p-1 text-white">Reversed</div>
          ) : (
            ""
          ),
      },

      {
        id: "deposit_transaction_id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <button
              onClick={() => handleOpenModal(row)}
              className="text-secondary rounded-0 border-0 font-weight-bold text-xs mr-1"
            >
              Receipt
            </button>
            {roles?.includes(1000076) && (
              <button
                className="btn btn-danger text-white rounded-0 btn-sm p-1"
                onClick={() => {
                  setId(row.deposit_transaction_id);
                  setShow(true);
                }}
                aria-expanded="false"
              >
                Reverse
              </button>
            )}
          </div>
        ),
      },
    ],
    [roles]
  );
  // eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState([]);
  // eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line
  const [sorting, setSorting] = useState([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data: tableData = [], meta } = {}, // your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      pagination.pageIndex, // refetch when pagination.pageIndex changes
      pagination.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `serverside/savings/general`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? "",
            sorting: JSON.stringify(sorting ?? []),
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });

  // eslint-disable-next-line
  const [columnFilters1, setColumnFilters1] = useState([]);
  // eslint-disable-next-line
  const [globalFilter1, setGlobalFilter1] = useState("");
  // eslint-disable-next-line
  const [sorting1, setSorting1] = useState([]);
  // eslint-disable-next-line
  const [pagination1, setPagination1] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data: otherData = [], otherMeta } = {},
    isError: otherIsError,
    isRefetching: otherIsRefetching,
    isLoading: otherIsLoading,
    refetch: otherRefetch,
  } = useQuery({
    queryKey: [
      "other-data",
      columnFilters1, // refetch when columnFilters changes
      globalFilter1, // refetch when globalFilter changes
      pagination1.pageIndex, // refetch when pagination.pageIndex changes
      pagination1.pageSize, // refetch when pagination.pageSize changes
      sorting1, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `serverside/savings/group/general`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination1.pageIndex * pagination1.pageSize,
            size: pagination1.pageSize,
            filters: JSON.stringify(columnFilters1 ?? []),
            globalFilter: globalFilter1 ?? "",
            sorting: JSON.stringify(sorting1 ?? []),
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });

  return (
    <>
      <Toaster />

      <section className="py-2 mb-4">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              {/* <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">Savings</h2>
              </div> */}
              <div className="card-body p-0">
                <ul className="nav nav-tabs bg-dark" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active text-white text-sm"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Individual Savings
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white text-sm"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Group Savings
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active "
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <MaterialReactTable
                      data={tableData}
                      columns={columns}
                      enableColumnFilterModes
                      enableColumnOrdering
                      enableRowSelection
                      manualFiltering={true}
                      manualPagination={true}
                      manualSorting={true}
                      enableGrouping
                      enableStickyHeader
                      className="table align-items-justify table-flush p-0 m-0"
                      initialState={{
                        density: "compact",
                        showColumnFilters: false,
                      }}
                      paginationDisplayMode={"pages"}
                      muiPaginationProps={{
                        shape: "rounded",
                        sx: { fontFamily: "Nunito", fontSize: "10px" },
                      }}
                      // state={{ isLoading: isLoading }}
                      muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                      muiTableHeadCellProps={{
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiCircularProgressProps={{
                        color: "secondary",
                        thickness: 5,
                        size: 25,
                      }}
                      muiSkeletonProps={{
                        animation: "pulse",
                        height: 28,
                      }}
                      muiTableBodyCellProps={{
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiSearchTextFieldProps={{
                        placeholder: "Search",
                        sx: { fontFamily: "Nunito", fontSize: "12px" },
                      }}
                      renderTopToolbarCustomActions={({ table }) => (
                        <>
                          <div className="btn-group">
                            <button
                              onClick={refetch}
                              className="btn btn-sm btn-dark rounded-0"
                            >
                              <i className="fa fa-rotate-right"></i>
                            </button>
                          </div>
                        </>
                      )}
                      rowCount={meta?.totalRowCount ?? 0}
                      state={{
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                      }}
                      onColumnFiltersChange={setColumnFilters}
                      onGlobalFilterChange={setGlobalFilter}
                      onPaginationChange={setPagination}
                      onSortingChange={setSorting}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <MaterialReactTable
                      data={otherData}
                      columns={columns}
                      enableColumnFilterModes
                      enableColumnOrdering
                      enableRowSelection
                      manualFiltering={true}
                      manualPagination={true}
                      manualSorting={true}
                      enableGrouping
                      enableStickyHeader
                      className="table align-items-justify table-flush p-0 m-0"
                      initialState={{
                        density: "compact",
                        showColumnFilters: false,
                      }}
                      paginationDisplayMode={"pages"}
                      muiPaginationProps={{
                        shape: "rounded",
                        sx: { fontFamily: "Nunito", fontSize: "10px" },
                      }}
                      // state={{ isLoading: isLoading }}
                      muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                      muiTableHeadCellProps={{
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiCircularProgressProps={{
                        color: "secondary",
                        thickness: 5,
                        size: 25,
                      }}
                      muiSkeletonProps={{
                        animation: "pulse",
                        height: 28,
                      }}
                      muiTableBodyCellProps={{
                        sx: {
                          fontWeight: "normal",
                          fontSize: "12px",
                          fontFamily: "Nunito",
                        },
                      }}
                      muiSearchTextFieldProps={{
                        placeholder: "Search",
                        sx: { fontFamily: "Nunito", fontSize: "12px" },
                      }}
                      renderTopToolbarCustomActions={({ table }) => (
                        <>
                          <div className="btn-group">
                            <button
                              onClick={otherRefetch}
                              className="btn btn-sm btn-dark rounded-0"
                            >
                              <i className="fa fa-rotate-right"></i>
                            </button>
                          </div>
                        </>
                      )}
                      rowCount={otherMeta?.totalRowCount ?? 0}
                      state={{
                        columnFilters1,
                        globalFilter1,
                        otherIsLoading,
                        pagination1,
                        showAlertBanner: otherIsError,
                        showProgressBars: otherIsRefetching,
                        sorting1,
                      }}
                      onColumnFiltersChange={setColumnFilters1}
                      onGlobalFilterChange={setGlobalFilter1}
                      onPaginationChange={setPagination1}
                      onSortingChange={setSorting1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modalData && (
        <DepositReceipt
          showModal={showModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
      )}
      <ConfirmModal
        message="Are you sure you want to reverse this transaction? "
        onConfirm={handleDelete}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default SavingGeneralSacco;
