import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import fileDownload from "js-file-download";
import Pagination from "pages/components/pagination";

const RejectedLoansReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);



  const controller = new AbortController();

  const [loans, setLoans] = useState([]);
  //fetch Accounts Accounts data
  const getLoans = async () => {
    progress.start()
    try {
      const response = await axiosPrivate.get(
        "reports/new/loans/rejected-applications",
        {
          signal: controller.signal,
        }
      );

      setLoans(response.data.data);
      progress.finish();
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const total = loans?.map((data) => data?.amount)?.reduce((a, b) => a + b, 0);

    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loans?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(loans?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

   // function to export data as pdf
   const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/rejected_loans/pdf`,

        { responseType: "blob" }
      );
      const filename = "rejected_loans_Report.pdf";
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading rejected Loans Report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }

      const response = await axiosPrivate.get(
        `/export/reports/rejected_loans/csv`,

        { responseType: "blob" }
      );

      const filename = `Rejected_Loans_Report.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading rejected loans report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };
  return (
    <>
      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header bg-dark text-white">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Rejected Loan Applications Report{" "}
                </h2>
              </div>
              <div className="card-body mt-3 mb-3">
                <div className="mt-3">
                  <table className="table table-hover table-bordered text-medium-small">
                    <thead>
                      <tr className="bg-dark text-white text-medium-small">
                        <th>Account No.</th>
                        <th>Client Name</th>
                        <th>Loan Product</th>
                        <th>Loan N0.</th>
                        <th>Date Of Application</th>
                        <th>Date Of Rejection</th>
                        <th>Amount Applied</th>
                        <th>Tenure Period</th>
                        <th>Reason</th>
                      </tr>
                    </thead>

                    <tbody className="text-medium-small">
                      {currentItems?.map((data) => (
                        <tr key={data?.id}>
                          <td>{data?.account}</td>
                          <td>{data?.client}</td>
                          <td>{data?.product}</td>
                          <td>{data?.code}</td>
                          <td>{data?.date}</td>
                          <td>{data?.reject_date}</td>
                          <td>{data?.amount?.toLocaleString()}</td>
                          <td>{data?.tenure}</td>
                          <td>{data?.reason}</td>
                        </tr>
                      ))}
                      <tr className="bg-dark text-white">
                        <th colSpan="6">Total Amount</th>
                        <th>{total?.toLocaleString()}</th>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                  <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      <button
                        className="btn btn-primary text-right"
                        onClick={downloadUssdReportAsPDF}

                        // disabled={isGenerating || isPDFDownloading}
                      >
                        {isPDFDownloading
                          ? "Downloading please wait..."
                          : "Download PDF"}
                      </button>
                      <button
                        className="btn btn-secondary text-right"
                        onClick={downloadUssdReportAsCSV}
                        // disabled={isGenerating || isCSVDownloading}
                      >
                        {isCSVDownloading
                          ? "Downloading please wait..."
                          : "Download CSV"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RejectedLoansReport;
