import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const PayBill = ({ showModal, handleCloseModal, getBills }) => {
  const [disabled, setDisabled] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    let bill = data.bill.value;
    delete data.bill;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/accounting/vendors/bills/${bill}`,
        data,
        {
          signal: controller.signal,
        }
      );
        console.log(response);
      toast.success(response?.data?.messages);
      progress.finish();
      getBills();
      setDisabled(false);
      handleCloseModal();
      reset();
    } catch (error) {
      console.log(error)
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [bills, setBills] = useState([]);
  const onChange = async (event) => {
    const id = event.value;
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(
        `/accounting/vendors/${id}/bills`,
        {
          signal: controller.signal,
        }
      );
      setBills(
        response?.data?.data?.transactions
          .map((transaction) => transaction)
          .filter((transaction) => transaction.status === "pending")
      );
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const options3 = bills?.map((bill) => ({
    value: `${bill.id}`,
    label: `${bill.code} - ${bill.notes} - ${bill.amount}`,
  }));
  // const [accounts, setAccounts] = useState([]);
  const [vendors, setVendors] = useState([]);
const controller = new AbortController();
const get = async () => {
  try {
    const response = await axiosPrivate.get("/accounting/vendors/account", {
      signal: controller.signal,
    });
     setVendors(response?.data?.data?.vendors);
  } catch (error) {
    // console.log(error)
    if (error?.response?.status === 401) {
      navigate("/", { state: { from: location }, replace: true });
    }
  }
};
  useEffect(() => {
    
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options2 = vendors?.map((vendor) => ({
    value: `${vendor.id}`,
    label: `${vendor.firstname} ${vendor.lastname}`,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> Pay Bill </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-12">
              <label htmlFor="vendor" className="form-label">
                Select Vendor *
              </label>

              <Select
                className="border-1 form-control-md"
                placeholder="Select Vendor To Pay  * "
                styles={customStyles}
                options={options2}
                onChange={onChange}
                isMulti={false}
              />

              {errors?.vendor?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Vendor
                </p>
              )}
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="bill" className="form-label">
                Select Bill *
              </label>
              <Controller
                control={control}
                name="bill"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    {...register("bill", { required: true })}
                    className="border-1 form-control-md"
                    placeholder="Select bill to pay  * "
                    styles={customStyles}
                    options={options3}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    isMulti={false}
                    ref={ref}
                  />
                )}
              />
              {errors?.account?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select atleast one bill
                </p>
              )}
              <input
                type="hidden"
                value="paid"
                {...register("status", { required: true })}
              />
            </div>
            {/* <div className="form-group col-md-12">
              <label htmlFor="bill" className="form-label">
                Expense Account
              </label>
              <input
                type="text"
                disabled
                className="form-control border-0 shadow form-control-md input-text"
              />
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Pay"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default PayBill;
